import { Table as MaterialTable } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import React, { Component } from "react";
import { MdHistory } from "react-icons/md";
// import config from "../../config";
// import errorFunctions from "../../store/errorFunctions";

import "./Table.css";
import Modal from "react-modal";
// import { MdClose } from "react-icons/md";
import "./Table.css";

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{
                lineHeight: "1rem",
                boxSizing: "border-box",
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                style={{
                  fontSize: "12px",
                  color: "#777777",
                }}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export default class Table extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: "desc",
      orderBy: "inventory_id",
      page: 0,
      rowsPerPage: 15,
      employee: null,
      showModal: false,
      showModalResetPassword: false,
      ticketID: 0,
      employeeID: 0,
      emailForReset: "",
      statusTicketType: "",
      modalStatus: false,
      showDueDateModal: false,
      dueDateReason: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({ page: 0 });
    }
  }

  handleRequestSort = (event, filter) => {
    this.props.onOrder(this.props.order === "asc" ? "desc" : "asc");
    this.props.onOrderBy(filter);
    this.props.pageNumberChange(1);
    this.props.onOrder(this.props.order === "asc" ? "desc" : "asc");
    let filterTxt = filter; //verificationStatus
    if (filter === "device_name") filterTxt = "device__name";
    else if (filter === "verificationStatus") filterTxt = "status";
    this.props.onOrderBy(filterTxt);
    this.props.pageNumberChange(1);

    const orderText = this.props.order !== "asc" ? filterTxt : "-" + filterTxt;

    this.props.retrieveInventory(null, null, null, null, orderText);
  };
  handleChangePage = (event, newPage) => {
    let apiUrl = "";
    // let page = 0;
    if (event.currentTarget.title === "Next page") {
      apiUrl = this.props.objInventory?.next;
      // page = newPage + 1;
    } else {
      apiUrl = this.props.objInventory?.previous;
      // page = newPage - 1;
    }
    this.props.pageNumberChange(newPage);
    this.props.retrieveInventory(apiUrl);
    // this.setState({
    //   page: newPage,
    // });
  };
  handleChangeRowsPerPage = (event) => {
    const rowPerPage = parseInt(event.target.value, 10);
    this.props.rowsPerPageChange(rowPerPage);
    this.props.pageNumberChange(0);
    this.props.retrieveInventory(null, rowPerPage, 1);
  };

  handleDropDown = (e, id) => {
    let ticketID = id;
    let employeeID = e.target.value;
    this.setState({ showModal: true, employeeID, ticketID });
  };

  renderTicketType = (value) => {
    return value;
  };

  handleCancel = () => {
    this.setState({
      modalStatus: false,
    });
  };

  render() {
    const rows = this.props.data;
    const headCells = this.props.head;
    Modal.setAppElement("body");

    return (
      <div>
        <TableContainer>
          <MaterialTable aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
            <EnhancedTableHead
              order={this.state.order}
              orderBy={this.state.orderBy}
              onRequestSort={this.handleRequestSort}
              headCells={this.props.head}
              selectStatus={this.props.statusSelectors}
              filterStatus={this.props.filteredStatus}
            />
            <TableBody>
              {rows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                const isStockLow = parseInt(row["quantity"]) <= 5;
                return (
                  <TableRow tabIndex={-1} key={labelId} className={isStockLow ? "out-stock" : "in-stock"} style={{ cursor: "pointer" }}>
                    {/* Loop through head to get data order */}
                    {headCells.map((item, i) => {
                      if (item.id === "material_number") {
                        return (
                          <TableCell
                            key={i}
                            style={{
                              width: "15vw",
                              wordWrap: "break-word",
                              // cursor: "pointer",
                            }}
                            onClick={() => this.props.editInventory(row)}
                          >
                            {item?.valueDisplay(row[item?.id])}
                          </TableCell>
                        );
                      } else if (item.id === "history") {
                        return (
                          <TableCell
                            key={i}
                            style={{
                              width: "10vw",
                              wordWrap: "break-word",
                              textAlign: "center",
                              paddingRight: "2vw",

                              // cursor: "pointer",
                            }}
                            onClick={() => row.part_used > 0 && this.props.onHistory(row)}
                          >
                            <div className={`btn btn-sm ${row.part_used > 0 ? "btn-primary " : "btn-secondary disabled "}`}>
                              <MdHistory />
                            </div>
                          </TableCell>
                        );
                      } else
                        return (
                          <TableCell
                            key={i}
                            style={
                              item?.width
                                ? {
                                    maxWidth: item?.width,
                                    wordWrap: "break-word",
                                  }
                                : {
                                    maxWidth: "120px",
                                    wordWrap: "break-word",
                                  }
                            }
                            onClick={() => this.props.editInventory(row)}
                          >
                            {item?.valueDisplay(row[item?.id])}
                          </TableCell>
                        );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </MaterialTable>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 25, 100]}
          component="div"
          count={this.props.objInventory.count}
          rowsPerPage={this.props.rowsPerPage}
          page={this.props.pageNumber}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />

        {/* Modal for confirm status ticket type */}
      </div>
    );
  }
}
