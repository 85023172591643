import React, { Component } from "react";
import BarChartAssignTo from "../BarChartAssignTo";
import BarChartDueDate from "../BarChartDueDate";
import BarChartStatus from "../BarChartStatus";
import PieChartAllocate from "../PieChartAllocate";
import config from "../../../config";
import errorFunctions from "../../../store/errorFunctions";
import LoadingPage from "../../../components/Loading/Loading";
import moment from "moment";
import { IoIosRefreshCircle } from "react-icons/io";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      dashboardData: undefined,
      dataChart3: undefined,
      devices: undefined,
      lastUpdated: moment(),
      employee: [],
      error: "",
    };
    // const userInfo = utilityFunctions.getCookie("userInfo");
    // this.userRole = JSON.parse(userInfdo).user.role;
    // this.user = JSON.parse(userInfo).user;
  }

  retriveDashboardData() {
    this.setState({
      loading: true,

      // deviceId: brandDevice[0]?.id,
    });
    this.props
      .protectedFetch(config.backendServer + "dashboard/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({
          loading: false,
          lastUpdated: moment(),
          dashboardData: res,

          // deviceId: brandDevice[0]?.id,
        });
      })

      .catch(() => {
        this.setState({
          loading: false,
          error: "Internal Server Error or No Internet connection",
        });
      });
  }
  retrieveEmployee = () => {
    this.props
      .protectedFetch(config.backendServer + "employee/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({ employee: res });
      })
      .catch(() => {
        console.log("fail");
      });
  };

  componentDidMount() {
    // this.retriveDashboardData();
    setInterval(() => this.retriveDashboardData(), 3600000);
    this.retriveDashboardData();
    this.retrieveEmployee();
  }

  render() {
    // if (this.state.loading) {
    //   return <LoadingPage />;
    // }
    const dashboardData = this.state.dashboardData;

    return (
      <>
        {this.state.loading && <LoadingPage />}
        {dashboardData && (
          <div>
            {this.state.error && (
              <div className="alert alert-danger" role="alert">
                {this.state.error}
              </div>
            )}

            <div className="d-flex flex-row bd-highlight card mb-1 p-2">
              <div className="m-2">{`Last update: ${this.state.lastUpdated.format("DD-MMM-YYYY HH:mm:ss ")}`}</div>
              <button data-testid="refresh-btn" title="Refresh" className="btn btn-sm " onClick={() => this.retriveDashboardData()}>
                <IoIosRefreshCircle title="Reload data" style={{ fontSize: "1.5rem", color: "#0d6efd" }} />
              </button>
            </div>

            <div className="row mb-4">
              <div className="col-md-6">
                <div className="card mb-2" style={{ textAlign: "center", height: "20vh" }}>
                  <div className="card-header">AVERAGE DAYS TO CLOSE TICKETS</div>
                  <div className="card-body">
                    <h2 className="mt-4">{dashboardData.average_day_to_close_ticket_chart || 0}</h2>
                  </div>
                </div>
                <div className="card" style={{ textAlign: "center", height: "20vh" }}>
                  <div className="card-header">NEW TICKETS</div>
                  <div className="row" style={{ height: "20vh" }}>
                    <div className="col-md-6 border-right">
                      <div className="card-body">
                        <div className="mt-4">Previous</div>
                        <div>
                          <h2>
                            <a style={{ color: "black", textDecoration: "none" }} href="/tickets?newtickets=previous">
                              {dashboardData.new_ticket_chart.previous || 0}
                            </a>
                          </h2>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 ">
                      <div className="card-body">
                        <div className="mt-4">Today</div>
                        <div>
                          <h2>
                            <a style={{ color: "black", textDecoration: "none" }} href="/tickets?newtickets=today">
                              {dashboardData.new_ticket_chart.current || 0}{" "}
                            </a>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card d-flex align-items-center justify-content-center h-100">
                  <h6 className="mt-1">Open Tickets Allocation</h6>
                  <div style={{ width: "50%" }}>
                    {/* {this.state.dataChart3 && ( */}
                    <PieChartAllocate type="pie" data={dashboardData.open_ticket_allocation_chart} lassName="mt-2" />
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-6">
                <div className="card d-flex align-items-center justify-content-center">
                  <h6 className="mt-2">Open Tickets Due Dates</h6>
                  <div style={{ width: "90%", marginBottom: "20px" }}>
                    <BarChartDueDate type="bar" data={dashboardData.open_ticket_due_dates_chart} />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card d-flex align-items-center justify-content-center">
                  <h6 className="mt-2">Allocated Open Tickets</h6>
                  <div style={{ width: "90%", marginBottom: "20px" }}>
                    <BarChartAssignTo type="bar" data={dashboardData.allocated_open_ticket_chart} employee={this.state.employee} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="card d-flex align-items-center justify-content-center mb-4">
                  <h6 className="mt-2">Ticket Status</h6>
                  <div
                    style={{
                      width: "60%",
                    }}
                  >
                    <BarChartStatus data={dashboardData.ticket_status_chart} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
