import React, { Component } from "react";
import moment from "moment";
import errorFunctions from "../../../store/errorFunctions";
import { MdClose } from "react-icons/md";
import Modal from "react-modal";
import axios from "axios";
import config from "../../../config";
import utilityFunctions from "../../../store/utilityFunctions";

export default class Notes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      warranties: [],
      notes: [],
      statusFilter: "All",
      searchFilter: "",
      showAddNote: false,
      addedNote: "",
      editedNote: "",
      editedNoteId: "",
    };
  }

  componentDidMount() {
    this.retrieveCustomerNotes(this.props.customerId);
  }

  handleInputChange = (e) => {
    const { id, value } = e.target;

    this.setState({
      [id]: value,
    });
  };
  retrieveCustomerNotes = (id) => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + `customers/${id}/note`, "GET", { id }, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({ loading: false, notes: res });
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
  };

  handleAddNote = (e) => {
    this.setState({ loading: true, showAddNote: false });
    e.preventDefault();
    // let formData = new FormData();
    // formData.append("note", e.currentTarget.addedNote.value);
    this.addNote({ note: e.currentTarget.addedNote.value }, this.state.id);

    console.log(this.state.warranties);
  };

  addNote(data) {
    const userInfo = localStorage.getItem("lgn") || utilityFunctions.getCookie("userInfo");
    const token = JSON.parse(userInfo).access_token;
    let options = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };

    if (this.state.editedNoteId) {
      const url = `${config.backendServer}customers/${this.props.customerId}/note/${this.state.editedNoteId}`;
      axios
        .put(url, data, options)
        .then(() => {
          this.retrieveCustomerNotes(this.props.customerId);
          this.setState({
            addedNote: "",
            editedNoteId: "",
            editedNote: null,
          });
        })
        .catch((error) => {
          // Handle the error or show an error message
          alert(error?.response?.data?.non_field_errors?.[0] || error?.response?.data?.serial_number?.[0] || "Something went wrong.. try later");
          this.setState({ isLoading: false });
        });
    } else {
      const url = `${config.backendServer}customers/${this.props.customerId}/note/`;
      axios
        .post(url, data, options)
        .then(() => {
          this.retrieveCustomerNotes(this.props.customerId);
          this.setState({
            addedNote: "",
            editedNoteId: "",
            editedNote: null,
          });
        })
        .catch((error) => {
          // Handle the error or show an error message
          alert(error?.response?.data?.non_field_errors?.[0] || error?.response?.data?.serial_number?.[0] || "Something went wrong.. try later");
          this.setState({ isLoading: false });
        });
    }
  }

  render() {
    const that = this;
    const userInfo = utilityFunctions.getCookie("userInfo");
    return (
      <div className={"activity-wrapper"}>
        <div className="mb-2">
          <button
            type="button"
            className="btn btn-outline-primary btn-sm"
            onClick={() => {
              this.setState({
                showAddNote: true,
                editedNoteId: "",
                editedNote: null,
              });
            }}
          >
            Add Note
          </button>
        </div>
        {/* <h4 className={"single-warranty-header"}>activities and comments</h4> */}
        <ul style={{ padding: "0px" }}>
          {this.state.notes
            .sort((a, b) => {
              return b.id - a.id;
            })
            .map(function (item, i) {
              const isMyNote = item.subject.includes(JSON.parse(userInfo).user.name);
              return (
                <div key={i} className="card" style={{ marginBottom: "10px" }}>
                  <div>
                    <div style={{ margin: "1px" }} className={"card-note-title row"}>
                      <div className="col-8">{item.subject}</div>
                      {isMyNote && (
                        <div className="col-4 text-end">
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-sm "
                            onClick={() => {
                              that.setState({
                                showAddNote: true,
                                editedNote: item.detail,
                                editedNoteId: item.id,
                              });
                            }}
                          >
                            Edit
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="card-body">
                      <p className="card-text">{item.detail}</p>
                      <p className="card-text">{moment(item.created).format("DD MMMM YYYY HH:mm")}</p>
                    </div>
                  </div>
                </div>
              );
            })}
        </ul>
        <Modal
          isOpen={this.state.showAddNote}
          //onRequestClose={()=>this.setState({showDeclineModal: false})}
          contentLabel={"Add Note"}
          className={"input-modal"}
        >
          <div className="card">
            <button onClick={() => this.setState({ showAddNote: false })} className={"confirm-modal-close"}>
              <MdClose />
            </button>
            <div className="card-header">{this.state.editedNote ? "Edit" : "Add"} Note</div>
            <form onSubmit={this.handleAddNote}>
              <div className={"card-body"}>
                <textarea
                  required={true}
                  onChange={this.handleInputChange}
                  id={"addedNote"}
                  className="form-control w-100"
                  value={this.state.addedNote || this.state.editedNote}
                />
              </div>
              <div className={"card-footer"}>
                <button type="submit" className={"btn btn-outline-primary mr-3"}>
                  Save
                </button>
                <button type={"button"} className={"btn btn-outline-secondary"} onClick={() => this.setState({ showAddNote: false, addedNote: "" })}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    );
  }
}
