import React, { Component } from "react";
import Chart from "chart.js/auto";

export default class PieChart extends Component {
  chartRef = React.createRef();

  componentDidMount() {
    const ctx = this.chartRef.current.getContext("2d");

    new Chart(ctx, {
      type: "pie",
      data: {
        labels: this.props.data?.labels,
        datasets: [
          {
            data: this.props.data?.data,
            label: "Total",
            backgroundColor: this.props.data?.backgroundColor,
            borderColor: this.props.data?.borderColor,
            fill: true,
          },
        ],
      },
      options: {
        // onClick: (e, element) => {
        //   if (element.length > 0) {
        //     var label = this.props.data.labels[element[0].index];
        //     window.location.href = `tickets?allocated=${label}`;
        //   }
        // },
        onHover: (event, chartElement) => {
          if (chartElement.length == 1) {
            event.native.target.style.cursor = "pointer";
          }
          if (chartElement.length == 0) {
            event.native.target.style.cursor = "default";
          }
        },
        plugins: {
          legend: {
            position: "bottom",
          },
        },
      },
    });
  }
  render() {
    return (
      <div>
        <canvas id="myChart" ref={this.chartRef} />
      </div>
    );
  }
}
