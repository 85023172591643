import React from "react";
import {
  IoIosBrush as CreatedIcon,
  IoIosRocket as InTransitIcon,
  IoIosThumbsDown as DeclinedIcon,
  IoIosThunderstorm as ServiceIcon,
  IoIosTimer as ReviewIcon,
  IoMdCloud as CompleteIcon,
} from "react-icons/io";

let refertenceLibrary = {
  statusSelectors: [
    {
      id: 1,
      name: "Awaiting Confirmation / Pending Approval",
      icon: <CreatedIcon className={"warranties-summary-individual-icon"} />,
      color: "#2475B0",
    },
    {
      id: 2,
      name: "Pending Return Label to be Sent",
      icon: <InTransitIcon className={"warranties-summary-individual-icon"} />,
      color: "#00695C",
    },
    {
      id: 3,
      name: "Pending Customer to Download Return Label",
      icon: <InTransitIcon className={"warranties-summary-individual-icon"} />,
      color: "#1A237E",
    },
    {
      id: 4,
      name: "Pending Receipt of Goods",
      icon: <InTransitIcon className={"warranties-summary-individual-icon"} />,
      color: "#1A237E",
    },

    {
      id: 5,
      name: "Goods received pending replacement to be sent",
      icon: <ReviewIcon className={"warranties-summary-individual-icon"} />,
      color: "#B71C1C",
    },
    {
      id: 6,
      name: "Replacement Sent",
      icon: <ServiceIcon className={"warranties-summary-individual-icon"} />,
      color: "#E65100",
    },
    {
      id: 7,
      name: "Not Approved",
      icon: <CompleteIcon className={"warranties-summary-individual-icon"} />,
      color: "#212121",
    },
    {
      id: 8,
      name: "Awaiting Parts",
      icon: <CompleteIcon className={"warranties-summary-individual-icon"} />,
      color: "#212121",
    },

    {
      id: 9,
      name: "Service Completed",
      icon: <CompleteIcon className={"warranties-summary-individual-icon"} />,
      color: "#212121",
    },
    {
      id: 10,
      name: "Goods Assessed and require additional payment (if applicable)",
      icon: <CompleteIcon className={"warranties-summary-individual-icon"} />,
      color: "#212121",
    },
    {
      id: 11,
      name: "Goods received and under repair",
      icon: <CompleteIcon className={"warranties-summary-individual-icon"} />,
      color: "#212121",
    },
    {
      id: 12,
      name: "Declined",
      icon: <DeclinedIcon className={"warranties-summary-individual-icon"} />,
      color: "#D50000",
    },
    {
      id: 13,
      name: "Repair complete, goods sent out",
      icon: <InTransitIcon className={"warranties-summary-individual-icon"} />,
      color: "#1A237E",
    },
    {
      id: 14,
      name: "Approved Pending payment of service charge",
      icon: <ReviewIcon className={"warranties-summary-individual-icon"} />,
      color: "#212121",
    },
    {
      id: 15,
      name: "Approved - Pending acceptance of Assessment Fee",
      icon: <ReviewIcon className={"warranties-summary-individual-icon"} />,
      color: "#212121",
    },
    {
      id: 16,
      name: "Refund Processed (Completed)",
      icon: <CompleteIcon className={"warranties-summary-individual-icon"} />,
      color: "#212121",
    },
    {
      id: 17,
      name: "Goods Received pending Refund",
      icon: <InTransitIcon className={"warranties-summary-individual-icon"} />,
      color: "#1A237E",
    },
    {
      id: 18,
      name: "Did Not Proceed",
      icon: <DeclinedIcon className={"warranties-summary-individual-icon"} />,
      color: "#212121",
    },
    {
      id: 19,
      name: "Completed",
      icon: <DeclinedIcon className={"warranties-summary-individual-icon"} />,
      color: "#212121",
    },
  ],
  statusClosed: [
    "Not Approved",
    "Service Completed",
    "Completed",
    "Did not Proceed",
    // "Refund Processed",
    // "Replacement Sent",
    // "Repair complete, goods sent out",
  ],
  statusArchived: ["Not Approved", "Service Completed", "Did not Proceed", "Completed"],
};

export default refertenceLibrary;
