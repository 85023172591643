import axios from "axios";
import config from "../../../config";
import utilityFunctions from "../../../store/utilityFunctions";

export async function postNewStaffFunction(payload) {
  const userInfo = localStorage?.getItem("userInfo") || utilityFunctions?.getCookie("userInfo");
  const token = (userInfo && JSON?.parse(userInfo)?.access_token) || "";
  let options = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  return new Promise((resolve) => {
    axios
      .post(`${config.backendServer}employee/`, payload, options)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        resolve(err);
      });
  });
}
