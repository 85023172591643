import React, { Component } from "react";
import config from "../../../../../config";
import errorFunctions from "../../../../../store/errorFunctions";
import Select from "react-select";

export default class DiagnosticRepair extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      actionIndex: 0,
      summary_of_issue: this.props.reportContent?.summary_of_issue || "",
      received_items: this.props.reportContent?.received_items || "",
      technician: this.props.reportContent?.technician || 0,
      checkedList: this.props.reportContent?.accessories?.map((x) => {
        return { accessory: x.accessory, quantity: x.quantity } || [];
      }),
      checkedDevice: false,
    };
  }
  handleSubmit = (e) => {
    this.setState({ loading: true });
    e.preventDefault();
    console.log(this.state.addComment);

    const payload = {
      // ticketId: this.props.ticketId,
      summary_of_issue: this.state.summary_of_issue,
      received_items: this.state.received_items || "-",
      technician: this.state.technician,
      accessories: this.state.checkedList,
    };

    const url =
      this.props.reportMode === "new"
        ? `${config.backendServer}ticket/${this.props.ticketId}/diagnostic-reports/new/`
        : `${config.backendServer}ticket/diagnostic-reports/${this.props.reportContent.id}/`;

    this.props
      .protectedFetch(url, this.props.reportMode === "new" ? "POST" : "PUT", payload, errorFunctions.checkResponseSent)
      .then(() => {
        this.setState({
          summary_of_issue: "",
          received_items: "",
          technician: 0,
          loading: false,
        });

        this.props.retrieveWarranty();
        this.props.setDefaultTab();
        // this.retrieveWarranty(dataToSend.id);
        // this.props.closeModal();
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
        this.props.closeModal();
      });
  };
  handleInputChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });
  };
  handleDropDown = (e) => {
    // let technician = e.target.value;
    this.setState({ technician: e.value });
  };

  handleChangeCheckList = (e, data) => {
    let tempCheckList = [...this.state.checkedList];
    if (e.target.checked) {
      if (this.state.checkedList.length > 0 && this.checkedList?.include(data.id)) {
        tempCheckList = tempCheckList.filter((item) => item.accessory !== data.id);
      } else {
        tempCheckList.push({ accessory: data.id, quantity: data.quantity });
      }
    } else tempCheckList = tempCheckList.filter((item) => item.accessory !== data.id);

    this.setState({ checkedList: tempCheckList });
  };

  handleAllCheckList = (e) => {
    let tempCheckList = [];
    if (e.target.checked) {
      tempCheckList = this.props.device?.accessories?.map((data) => {
        return { accessory: data.id, quantity: data.quantity };
      });
    }
    this.setState({ checkedList: tempCheckList });
  };

  render() {
    const technicianOption = this.props.employeeList?.map((val) => {
      return { value: val.id, label: val.name };
    });

    const selectedTechnician = technicianOption?.find((x) => x.value === this.state.technician);
    return (
      <div className="card">
        <form onSubmit={this.handleSubmit}>
          <div className="card-header">
            <h5>Diagnostic Report</h5>
          </div>
          <div className="card-body" style={{ width: "800px", height: "60vh", overflow: "auto" }}>
            <div className="mb-3 border rounded p-3">
              <div>
                <b>Issue</b>
              </div>
              <div>Summary Issue</div>
              <div>
                <textarea
                  id="summary_of_issue"
                  disabled={this.props.reportMode === "view"}
                  className="form-control"
                  rows={4}
                  style={{ width: "100%" }}
                  onChange={this.handleInputChange}
                  value={this.state.summary_of_issue}
                />
              </div>
              <div className="my-3 border rounded p-3">
                <b>Part Received : {this.props.device.name}</b>
                <div className="w-75">
                  <b>
                    <div className="row  border border-light bg-gray-200">
                      <div className="form-check col-7 mx-1">
                        <input
                          className={"form-check-input"}
                          disabled={this.props.reportMode === "view"}
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                          checked={this.state.checkedList?.length === this.props.device.accessories?.length}
                          onChange={(e) => this.handleAllCheckList(e)}
                        />
                        <div className="col text-left">Item Name</div>
                      </div>

                      <div className="col-2  text-left ">Qty</div>
                      <div className="col text-right">Qty Received</div>
                    </div>
                  </b>
                </div>
                {this.props.device.accessories?.map((dev, i) => {
                  const checkedDevice = this.state.checkedList?.find((x) => x.accessory === dev.id);
                  return (
                    <div className={"w-75"} key={i}>
                      <div className="row border border-light border-bottom-1">
                        <div className="form-check col-7 mx-1">
                          <input
                            className={"form-check-input col-1"}
                            type="checkbox"
                            disabled={this.props.reportMode === "view"}
                            // value={checkedDevice}
                            id="flexCheckChecked"
                            checked={checkedDevice}
                            onChange={(e) => this.handleChangeCheckList(e, dev)}
                          />
                          <label className="form-check-label col" htmlFor="flexCheckChecked">
                            {dev.name}
                          </label>
                        </div>
                        {/* <div className="col-7">{g.name}</div> */}
                        <div className="col-2 mr-1">{dev.quantity}</div>
                        <input
                          type="number"
                          min="1"
                          className="col  border border-light"
                          disabled={this.props.reportMode === "view"}
                          onChange={(e) => this.quantityChange(e, dev)}
                          onBlur={(e) => {
                            if (this.state.checkedList.find((x) => x.accessory == dev.id) && (!e.target.value || e.target.value === "0"))
                              alert(`${dev.name} quantity can not be null`);
                          }}
                          defaultValue={this.state.checkedList?.find((x) => x.accessory == dev.id)?.quantity || dev.quantity}
                          value={this.state.checkedList?.find((x) => x.accessory == dev.id)?.quantity}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* <div>
                <textarea
                  id="received_items"
                  className="form-control"
                  disabled={this.props.reportMode === "view"}
                  value={this.state.received_items}
                  rows={4}
                  style={{ width: "100%" }}
                  onChange={this.reportMode !== "view" && this.handleInputChange}
                />
              </div> */}
            </div>

            <div className="mb-3 border rounded p-3">
              <b>Worked Performed</b>
              <div className={"single-warranty-details-item mb-3"}>
                <span>Technician:</span>
                <Select
                  styles={{ zIndex: 999 }}
                  data-testid="assign-to-select"
                  value={selectedTechnician}
                  isSearchable={true}
                  options={technicianOption}
                  onChange={(obj) => {
                    this.handleDropDown(obj);
                  }}
                  isDisabled={this.props.reportMode === "view"}
                />
              </div>
            </div>
          </div>
          <div className="card-footer">
            <button
              type="submit"
              disabled={this.props.reportMode === "view"}
              // onClick={() => this.props.closeModal()}
              className="btn btn-outline-primary m-2"
            >
              Save
            </button>

            <button onClick={() => this.props.closeModal()} className="btn btn-outline-secondary m-2">
              Close
            </button>
          </div>
        </form>
      </div>
    );
  }
}
