import React, { Component } from "react";
import Modal from "react-modal";
import "../../common.css";
import { MdClose } from "react-icons/md";
import config from "../../../config";
import { icons } from "../../../img/icons";
// import errorFunctions from "../../../store/errorFunctions";
// import { postNewStaffFunction } from "./api";

Modal.setAppElement("body");

export default class DeviceAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      deviceName: this.props.deviceData?.name || "",
      width: this.props.deviceData?.width || "",
      length: this.props.deviceData?.length || "",
      height: this.props.deviceData?.height || "",
      weight: this.props.deviceData?.weight || "",
      description: this.props.deviceData?.description || "",
      shippingVendor: this.props.deviceData?.shipping_vendor || "auspost",
      image: this.props.deviceData?.image || "",

      errorNotification: "",
    };
  }
  handleFileUpload = (e) => {
    // TODO - verify file upload to make sure it's good
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
    this.setState({ image: file.name });
    if (!allowedTypes.includes(file.type)) {
      alert("Only JPG, JPEG, PNG, and PDF files are allowed.");
      this.setState({ allowUpload: false });
    } else {
      this.setState({ allowUpload: true });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.state.deviceName) {
      this.setState({ errorNotification: "Device name can not be blank" });
      return;
    }

    const mediaFile = document.getElementById("uploadFile")?.files[0] || null;
    let formData = new FormData();
    formData.append("name", this.state.deviceName);
    formData.append("width", this.state.width);
    formData.append("length", this.state.length);
    formData.append("height", this.state.height);
    formData.append("weight", this.state.weight);
    formData.append("description", this.state.description);
    formData.append("brand", this.props.selectedBrandId);
    formData.append("shipping_vendor", this.state.shippingVendor);
    if (mediaFile) formData.append("image", mediaFile);

    const url = this.props.isEdit ? config.backendServer + `device/` + this.props.deviceData?.id + "/" : config.backendServer + `device/`;

    this.props
      .protectedFetch(url, this.props.isEdit ? "PUT" : "POST", formData, (res) => res.ok, true)
      .then(() => {
        //mini reload

        // this.props.reloadDevice();
        this.props.handleCloseModal();
        alert("Device saved");
      })
      .catch((err) => {
        console.log(err);
        this.setState({ errorNotification: err });
      });
  };
  handleRole = (e) => {
    this.setState({ currentRole: e.target.value });
  };
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  render() {
    const imgArray = this.state.image.split("/");
    const imgName = imgArray.length > 0 ? imgArray[imgArray.length - 1] : imgArray;
    return (
      <div className="card">
        <button onClick={() => this.props.handleCloseModal()} className={"confirm-modal-close"}>
          <MdClose />
        </button>
        <h4 className={"card-header"}>{`${this.props.isEdit ? "Edit" : "Add"} Device`}</h4>
        <form onSubmit={this.handleSubmit}>
          <div className="card-body">
            <div className="file-upload-container mb-3">
              <label htmlFor="uploadFile" className="custom-file-upload ">
                {"Upload Image "} <img src={icons.Upload} alt="" />
              </label>
              <span>{`  ${this.state.image ? imgName : "No file choosen"}`}</span>
              <input
                accept="image/jpeg, image/png, application/pdf"
                type={"file"}
                id={"uploadFile"}
                // className="custom-file-upload"
                // required={true}
                onChange={this.handleFileUpload}
              />
            </div>

            <div className={"mb-3"} style={{ display: "none" }}>
              <label className={"single-warranty-details-label"} htmlFor="deviceId">
                Device ID
              </label>
              <input type="text" id="deviceId" className="form-control" value={this.props.deviceData?.id} disabled />
            </div>

            <div className={"mb-3"}>
              <label className={"single-warranty-details-label "} htmlFor="deviceName">
                Device Name*
              </label>

              <input
                type="text"
                id="deviceName"
                //  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                required={true}
                className="form-control"
                onChange={this.handleChange}
                value={this.state.deviceName}
              />
              <div style={{ color: "red" }}>{this.state.errorNotification}</div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <label className={"single-warranty-details-label "} htmlFor="width">
                  Width*
                </label>
                <div className="input-group">
                  <input type="number" id="width" required={true} className="form-control" onChange={this.handleChange} value={this.state.width} />
                  <div className="input-group-append">
                    <span className="input-group-text">cm</span>
                  </div>
                </div>

                <div style={{ color: "red" }}>{this.state.errorNotification}</div>
              </div>

              <div className="col">
                <label className={"single-warranty-details-label "} htmlFor="length">
                  Length*
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    id="length"
                    //  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    required={true}
                    className="form-control"
                    onChange={this.handleChange}
                    value={this.state.length}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">cm</span>
                  </div>
                </div>

                <div style={{ color: "red" }}>{this.state.errorNotification}</div>
              </div>
            </div>
            <div className="row mb-3">
              <div className={"col"}>
                <label className={"single-warranty-details-label"} htmlFor="height">
                  Height*
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    id="height"
                    //  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    required={true}
                    className="form-control"
                    onChange={this.handleChange}
                    value={this.state.height}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">cm</span>
                  </div>
                </div>

                <div style={{ color: "red" }}>{this.state.errorNotification}</div>
              </div>
              <div className={"col"}>
                <label className={"single-warranty-details-label"} htmlFor="weight">
                  Weight*
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    id="weight"
                    //  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    required={true}
                    className="form-control"
                    onChange={this.handleChange}
                    value={this.state.weight}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">kg</span>
                  </div>
                </div>

                <div style={{ color: "red" }}>{this.state.errorNotification}</div>
              </div>
            </div>

            <div className={"mb-3"}>
              <label className={"single-warranty-details-label"} htmlFor="description">
                Descriptions
              </label>
              <input
                type="text"
                id="description"
                //  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                // required={true}
                className="form-control"
                onChange={this.handleChange}
                value={this.state.description}
              />
              <div style={{ color: "red" }}>{this.state.errorNotification}</div>
            </div>
            <div className={"mb-3"}>
              <label className={"single-warranty-details-label"} htmlFor="shippingVendor">
                Shipping vendor
              </label>
              <select id="shippingVendor" onChange={this.handleChange} value={this.state.shippingVendor} className="form-select" required>
                <option value="auspost">auspost</option>
                <option value="transdirect">transdirect</option>
              </select>

              <div style={{ color: "red" }}>{this.state.errorNotification}</div>
            </div>
          </div>
          <div className="card-footer">
            <div className="row">
              <div className="col-2">
                <button className="btn btn-outline-primary ">Submit</button>
              </div>
              <div className="col-2">
                <button className="btn btn-outline-secondary " onClick={() => this.props.handleCloseModal()}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
