import React, { Component } from "react";
import "./SingleUser.css";
import "../../common.css";
import config from "../../../config";
import LoadingPage from "../../../components/Loading/Loading";
import errorFunctions from "../../../store/errorFunctions";
import { Link, Redirect } from "react-router-dom";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
Modal.setAppElement("body");

export default class SingleUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      details: null,
      id: null,
      showDeleteModal: false,
      showForceVerifyModal: false,
      redirect: false,
    };
  }

  componentDidMount() {
    const id = this.props.computedMatch.params.id;
    this.setState({ id });
    this.retrieveData(id);
  }
  retrieveData = (id) => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + "getsingleuser", "POST", { id }, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({ loading: false, details: res });
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
  };
  handleDelete = () => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + "deleteuser", "POST", { id: this.state.id }, errorFunctions.checkResponseSent)
      .then(() => {
        this.setState({ loading: false, redirect: true });
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
  };
  handleForceVerify = () => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + "forceverify", "POST", { id: this.state.id }, errorFunctions.checkResponseSent)
      .then(() => {
        this.setState({ showForceVerifyModal: false });
        this.retrieveData(this.state.id);
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/users"} />;
    } else if (this.state.loading) {
      return <LoadingPage />;
    } else if (!this.state.details) {
      return (
        <div className={"page"}>
          <h2 className={"pageHeader"}>Error - page not found</h2>
        </div>
      );
    }

    const details = this.state.details || {};
    const devices = details.devices;
    const warranties = details.warranties;

    return (
      <div className={"page"}>
        <h2 className={"pageHeader"}>User {this.state.id}</h2>
        <div className={"pageContent clearfix"}>
          <div className={"single-user-details"}>
            <h4 className={"single-user-header"}>User Details</h4>
            <div className={"single-user-details-item"}>
              <span className={"single-user-details-label"}>Name: </span>
              <span>{details.name}</span>
            </div>
            <div className={"single-user-details-item"}>
              <span className={"single-user-details-label"}>Company: </span>
              <span>{details.company}</span>
            </div>
            <div className={"single-user-details-item"}>
              <span className={"single-user-details-label"}>Address: </span>
              <span>{details.address}</span>
            </div>
            <div className={"single-user-details-item"}>
              <span className={"single-user-details-label"}>Mobile: </span>
              <span>{details.mobile}</span>
            </div>
            <div className={"single-user-details-item"}>
              <span className={"single-user-details-label"}>Email: </span>
              <span>{details.email}</span>
            </div>
            <div className={"single-user-details-item"}>
              <span className={"single-user-details-label"}>Is Verified: </span>
              <span>{details.is_verified ? "Yes" : "No"}</span>
            </div>
            {devices && (
              <div className={"single-user-details-item"}>
                <div className={"single-user-details-label"}>Devices: </div>
                <ul>
                  {devices.map(function (item, i) {
                    return (
                      <li key={i}>
                        {item.serialNumber} - {item.deviceName}. Purchase Date: {item.dateOfPurchase}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {!devices && <div className={"single-user-details-item"}>No devices</div>}
            {warranties ? (
              <div className={"single-user-details-item"}>
                <div className={"single-user-details-label"}>Warranties: </div>
                <ul>
                  {warranties.map(function (item, i) {
                    return (
                      <li key={i}>
                        <Link to={"/warranty/" + item.warrantyId}>
                          {item.warrantyId} - {item.serialNumber}
                        </Link>
                        . Created: {item.dateCreated}
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              <div className={"single-user-details-item"}>No warranties</div>
            )}

            <div className={"single-user-details-item"}>
              <span className={"single-user-details-label"}>Account created: </span>
              <span>{details.date_created}</span>
            </div>
            <div className={"single-user-details-item"}>
              <span className={"single-user-details-label"}>Account verified: </span>
              <span>{details.date_verified}</span>
            </div>
            <div className={"single-user-details-item"}>
              <span className={"single-user-details-label"}>Profile last updated: </span>
              <span>{details.profile_updated}</span>
            </div>
            <div className={"single-user-details-item"}>
              <span className={"single-user-details-label"}>Last Login: </span>
              <span>{details.last_login}</span>
            </div>
          </div>
          <button
            className={"button deleteButton"}
            onClick={() => {
              this.setState({ showDeleteModal: true });
            }}
          >
            Delete User
          </button>
          {!details.is_verified && (
            <button
              className={"button deleteButton"}
              style={{ marginLeft: "10px" }}
              onClick={() => {
                this.setState({ showForceVerifyModal: true });
              }}
            >
              Force Verify User
            </button>
          )}
        </div>

        {/* Delete modal */}
        <Modal
          isOpen={this.state.showDeleteModal}
          onRequestClose={() => this.setState({ showDeleteModal: false })}
          contentLabel={"Delete modal"}
          className={"delete-modal"}
        >
          <button onClick={() => this.setState({ showDeleteModal: false })} className={"modal-close"}>
            <MdClose />
          </button>
          <div className={"delete-main-text"}>Are you sure you wish to delete this user?</div>
          <div className={"delete-sub-text"}>All user information and warranties associated with user will be permanently deleted.</div>
          <div className={"approveDeclineSet confirmApproveDeclineSet"}>
            <button onClick={this.handleDelete} className={"decline confirmDecline"}>
              Delete
            </button>
            <button className={"neutral confirmNeutral"} onClick={() => this.setState({ showDeleteModal: false })}>
              Cancel
            </button>
          </div>
        </Modal>

        {/* Force verify modal */}
        <Modal
          isOpen={this.state.showForceVerifyModal}
          onRequestClose={() => this.setState({ showForceVerifyModal: false })}
          contentLabel={"Force verify modal"}
          className={"delete-modal"}
        >
          <button onClick={() => this.setState({ showForceVerifyModal: false })} className={"modal-close"}>
            <MdClose />
          </button>
          <div className={"delete-main-text"}>Are you sure you wish to verify this user?</div>
          <div className={"delete-sub-text"}>This user will now be able to warranty requests in the system.</div>
          <div className={"approveDeclineSet confirmApproveDeclineSet"}>
            <button onClick={this.handleForceVerify} className={"decline confirmDecline"}>
              Verify User
            </button>
            <button className={"neutral confirmNeutral"} onClick={() => this.setState({ showForceVerifyModal: false })}>
              Cancel
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}
