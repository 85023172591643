import { Table as MaterialTable } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import React, { Component } from "react";
import config from "../../config";
import errorFunctions from "../../store/errorFunctions";
import "./Table.css";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import { Link } from "react-router-dom";
import { createBrowserHistory } from "history";

import "./Table.css";

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{
                lineHeight: "1rem",
                boxSizing: "border-box",
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                style={{
                  fontSize: "12px",
                  color: "#777777",
                }}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export default class Table extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: "desc",
      orderBy: "id",
      page: 0,
      rowsPerPage: 100,

      showModal: false,
      showModalResetPassword: false,
      showModalVerify: false,
      ticketID: 0,

      emailForReset: "",
      statusTicketType: "",
      modalStatus: false,
      modalCaption: "",
      selectedCustomer: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({ page: 0 });
    }
  }

  handleRequestSort = (event, filter) => {
    this.props.onOrder(this.props.order === "asc" ? "desc" : "asc");
    let filterTxt = filter; //verificationStatus
    if (filter === "email") filterTxt = "user__email";
    else if (filter === "verificationStatus") filterTxt = "status";
    this.props.onOrderBy(filterTxt);
    this.props.pageNumberChg(1);

    const orderText = this.props.order !== "asc" ? filterTxt : "-" + filterTxt;

    this.props.retriveCustomer(null, null, null, null, orderText);
  };

  handleChangePage = (event, newPage) => {
    let apiUrl = "";
    if (event.currentTarget.title === "Next page") apiUrl = this.props.objCustomer?.next;
    else apiUrl = this.props.objCustomer?.previous;
    this.props.pageNumberChg(newPage + 1);
    this.props.retriveCustomer(apiUrl);
  };

  handleChangeRowsPerPage = (event) => {
    const rowPerPage = parseInt(event.target.value, 10);

    this.props.rowsPerPageChg(rowPerPage);
    this.props.pageNumberChg(1);
    this.props.retriveCustomer("", rowPerPage, 1);
  };

  handleDropDown = (e, id) => {
    let ticketID = id;

    this.setState({ showModal: true, ticketID });
  };

  handleOpenModalSendPassword = (email, modalCaption) => {
    this.setState({
      showModalResetPassword: true,
      emailForReset: email,
      modalCaption,
    });
  };

  handleSendVerification = (event) => {
    event.preventDefault();
    let payload = {
      email: this.state.emailForReset,
    };
    this.props
      .protectedFetch(`${config.backendServer}registration/resend-email/`, "POST", payload, errorFunctions.checkResponseThenJson)
      .then((response) => {
        console.log(response.status);
        if (response.detail === "ok") {
          this.setState({
            showModalVerificationSuccess: true,
            showModalResetPassword: false,
          });
        } else if (response.status === 409) {
          alert(`Email does not exist.`);
        } else {
          alert("Problem with system. Please refresh and try again later.");
        }
      })
      .catch(() => {
        console.log("fail");
        this.setState({ showModal: false });
      });
  };
  handleVerify = () => {
    let payload = {
      email: this.state.selectedCustomer.email,

      customer_id: this.state.selectedCustomer.id,
    };
    this.props
      .protectedFetch(`${config.backendServer}customer/verify/`, "POST", payload, errorFunctions.checkResponseThenJson)
      .then((response) => {
        if (response.message) {
          alert(response.message);

          this.setState({
            showModalVerify: false,
          });
          this.props.retriveCustomer(this.props.selectedbrandID);
        } else if (response.status === 409) {
          alert(`Email does not exist.`);
        } else {
          alert("Problem with system. Please refresh and try again later.");
        }
      })
      .catch(() => {
        console.log("fail");
        this.setState({ showModalVerify: false });
      });
  };

  handleResetPassword = () => {
    let url = `${config.backendServer}password/reset/`;

    if (this.props.selectedbrandID !== undefined) {
      url += `?brandId=${this.props.selectedbrandID}`;
    }

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: this.state.emailForReset,
      }),
    }).then((response) => {
      console.log(response.status);
      if (response.status === 200) {
        this.setState({ showModalResetPasswordSuccess: true, showModalResetPassword: false });
      } else if (response.status === 409) {
        alert(`Email does not exist.`);
      } else {
        alert("Problem with system. Please refresh and try again later.");
      }
    });
  };
  handleStatusTicketTypeModal = (e, id) => {
    e.preventDefault();
    this.setState({
      modalStatus: true,
      statusTicketType: e.target.value,
      ticketID: id,
    });
  };

  handleChangeStatusTicketType = (e) => {
    e.preventDefault();

    let payload = {
      ticket_type: this.state.statusTicketType,
    };
    this.props
      .protectedFetch(config.backendServer + `ticket/${this.state.ticketID}/change-type/`, "PATCH", payload, errorFunctions.checkResponseThenJson)
      .then(() => {
        this.props.retriveCustomer(this.props.selectedbrandID);
        this.setState({ modalStatus: false });
      })
      .catch(() => {
        console.log("fail");
        this.setState({ modalStatus: false });
      });
  };

  renderTicketType = (status, value) => {
    return value;
  };

  handleCancel = () => {
    this.props.retriveCustomer(this.props.selectedbrandID);
    this.setState({
      modalStatus: false,
    });
  };

  handleRowClick = (id) => {
    const history = createBrowserHistory({ forceRefresh: true });
    history.push(`/customer-detail/${id}`);
    // alert("row");
  };
  render() {
    const rows = this.props.data;
    const headCells = this.props.head;
    Modal.setAppElement("body");

    return (
      <div>
        <TableContainer>
          <MaterialTable aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
            <EnhancedTableHead
              order={this.state.order}
              orderBy={this.state.orderBy}
              onRequestSort={this.handleRequestSort}
              headCells={this.props.head}
              selectStatus={this.props.statusSelectors}
              filterStatus={this.props.filteredStatus}
            />
            <TableBody>
              {rows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow tabIndex={-1} key={labelId} className={"customer"}>
                    {/* Loop through head to get data order */}
                    {headCells.map((item, i) => {
                      if (item.id === "first_name") {
                        return (
                          <TableCell
                            key={i}
                            style={{
                              maxWidth: "200px",
                              wordWrap: "break-word",
                              cursor: "pointer",
                            }}
                          >
                            <Link
                              style={{
                                textDecoration: "none",
                                color: "black",
                              }}
                              to={"/customer-detail/" + row.id}
                            >
                              {item?.valueDisplay(row[item?.id])}
                            </Link>
                          </TableCell>
                        );
                      } else if (item.id === "last_name") {
                        return (
                          <TableCell
                            key={i}
                            style={{
                              maxWidth: "200px",
                              wordWrap: "break-word",
                              cursor: "pointer",
                            }}
                          >
                            <Link
                              style={{
                                textDecoration: "none",
                                color: "black",
                              }}
                              to={"/customer-detail/" + row.id}
                            >
                              {item?.valueDisplay(row[item?.id])}
                            </Link>
                          </TableCell>
                        );
                      } else if (item.id === "email") {
                        return (
                          <TableCell
                            key={i}
                            style={{
                              maxWidth: "200px",
                              wordWrap: "break-word",
                              cursor: "pointer",
                            }}
                          >
                            <Link
                              style={{
                                textDecoration: "none",
                                color: "black",
                              }}
                              to={"/customer-detail/" + row.id}
                            >
                              {item?.valueDisplay(row[item?.id])}
                            </Link>
                          </TableCell>
                        );
                      } else if (item.id === "verificationStatus") {
                        return (
                          <TableCell key={i}>
                            {!item?.valueDisplay(row["status"]) ? (
                              <button
                                onClick={() => this.handleOpenModalSendPassword(row.email, "Are you sure you want to send verification to")}
                                className={"btn btn-outline-info btn-sm"}
                              >
                                Send Verification
                              </button>
                            ) : item?.valueDisplay(row["status"]) === "Pending Verification" ? (
                              <button
                                className={"btn btn-outline-primary btn-sm"}
                                onClick={() => this.setState({ selectedCustomer: row, showModalVerify: true })}
                              >
                                Verify Account
                              </button>
                            ) : (
                              <span className={item?.valueDisplay(row["status"]) === "Active" ? "active-status" : ""}>
                                {item?.valueDisplay(row["status"])}
                              </span>
                            )}
                          </TableCell>
                        );
                      } else if (item.id === "action") {
                        return (
                          <TableCell key={i}>
                            <button
                              onClick={() => this.handleOpenModalSendPassword(row.email, "Are you sure you want to send a password reset to")}
                              className={"btn btn-outline-danger btn-sm"}
                            >
                              Reset Password
                            </button>
                          </TableCell>
                        );
                      } else if (item.id === "name") {
                        return (
                          <TableCell
                            key={i}
                            style={{
                              maxWidth: "200px",
                              wordWrap: "break-word",
                            }}
                          >
                            <Link to={"/staff-detail/" + row.id}>{item?.valueDisplay(row[item?.id])}</Link>
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell
                            key={i}
                            style={
                              item?.width
                                ? {
                                    width: item?.width,
                                    wordWrap: "break-word",
                                  }
                                : {
                                    maxWidth: "120px",
                                    wordWrap: "break-word",
                                  }
                            }
                          >
                            {item?.valueDisplay(row[item?.id])}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </MaterialTable>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 25, 100]}
          component="div"
          count={this.props.objCustomer.count}
          rowsPerPage={this.props.rowsPerPage}
          page={this.props.pageNumber - 1}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />

        <Modal
          isOpen={this.state.showModal}
          //onRequestClose={()=>this.setState({showDeclineModal: false})}
          contentLabel={"Add Note"}
          className={"confirm-modal confirm-modal-big-approve warranty-decline-modal"}
        >
          <button onClick={() => this.setState({ showModal: false })} className={"confirm-modal-close"}>
            <MdClose />
          </button>
          <form onSubmit={this.handleSubmitAssign}>
            <div className={"approveDeclineSet confirmApproveDeclineSet"}>
              <div className={"form-field"}>
                <div className="mb-3">Confirm Assigned?</div>
                <button type="submit" className={"approve confirmDecline"}>
                  Assign
                </button>
                <button type={"button-cancel"} className={"neutral confirmNeutral"} onClick={() => this.setState({ showModal: false })}>
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </Modal>

        <Modal isOpen={this.state.showModalResetPassword} contentLabel={"Reset Password"} className={"input-modal-small"}>
          <div className="card">
            <button onClick={() => this.setState({ showModalResetPassword: false })} className={"confirm-modal-close"}>
              <MdClose />
            </button>
            <div className="card-header">
              <h6>{this.state.modalCaption.toLowerCase().includes("reset") ? "Reset Password" : "Send Verification"}</h6>
            </div>
            <form>
              <div className="card-body text-center">
                <div className="mb-3">{this.state.modalCaption}</div>
                <div> {`${this.state.emailForReset}?`}</div>
              </div>
              <div className="card-footer">
                <div className="row ">
                  <div className="col-6 text-right ">
                    <button
                      type="button"
                      className={"btn btn-outline-primary"}
                      onClick={(event) => {
                        if (this.state.modalCaption.toLowerCase().includes("reset")) this.handleResetPassword();
                        else this.handleSendVerification(event);
                        this.setState({ showModalResetPassword: false });
                      }}
                    >
                      Send
                    </button>
                  </div>
                  <div className="col-6 text-left">
                    <button type={"button"} className={"btn btn-outline-secondary"} onClick={() => this.setState({ showModalResetPassword: false })}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal>

        <Modal isOpen={this.state.showModalVerify} contentLabel={"Reset Password"} className={"input-modal-small"}>
          <div className="card">
            <button onClick={() => this.setState({ showModalVerify: false })} className={"confirm-modal-close"}>
              <MdClose />
            </button>
            <div className="card-header">
              <h6>{"Verification Account"}</h6>
            </div>
            <form>
              <div className="card-body text-center">
                <div> {`Verify this customer ${this.state.selectedCustomer.email}?`}</div>
              </div>
              <div className="card-footer">
                <div className="row ">
                  <div className="col-6 text-right ">
                    <button
                      type="button"
                      className={"btn btn-outline-primary"}
                      onClick={(event) => {
                        if (this.state.modalCaption.toLowerCase().includes("reset")) this.handleResetPassword();
                        else this.handleVerify(event);
                        this.setState({ showModalResetPassword: false });
                      }}
                    >
                      Send
                    </button>
                  </div>
                  <div className="col-6 text-left">
                    <button type={"button"} className={"btn btn-outline-secondary"} onClick={() => this.setState({ showModalVerify: false })}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal>

        <Modal isOpen={this.state.showModalResetPasswordSuccess} contentLabel={"Reset Password"} className={"input-modal"}>
          <div className="card">
            <div className="card-header">Reset Password</div>
            <form className="card-body text-center">
              <div className="mb-3">The reset password email is sent to</div>
              <div>{this.state.emailForReset}</div>
              <div className="mt-5">
                <button
                  type={"button"}
                  className={"btn btn-outline-primary"}
                  onClick={() =>
                    this.setState({
                      showModalResetPasswordSuccess: false,
                      showModalResetPassword: false,
                    })
                  }
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </Modal>
        <Modal isOpen={this.state.showModalVerificationSuccess} contentLabel={"Reset Password"} className={"input-modal"}>
          <div className="card">
            <div className="card-header">Email Verification </div>
            <form className="card-body text-center">
              <div className="mb-3">The email verification is sent to</div>
              <div>{this.state.emailForReset}</div>
              <div className="mt-5">
                <button
                  type={"button"}
                  className={"btn btn-outline-primary"}
                  onClick={() =>
                    this.setState({
                      showModalVerificationSuccess: false,
                      showModalResetPassword: false,
                    })
                  }
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </Modal>

        {/* Modal for confirm status ticket type */}
        <Modal isOpen={this.state.modalStatus} contentLabel={"Set as Out of Warranty"} className={"confirm-modal"}>
          <form onSubmit={this.handleChangeStatusTicketType}>
            <div className={"approveDeclineSet confirmApproveDeclineSet"}>
              <div className={"form-field"}>
                <div className="mb-3">Change Status to {this.state.statusTicketType}?</div>

                <button type="submit" className={"btn btn-outline-primary"}>
                  Confirm
                </button>

                <button type={"button"} className={"btn btn-outline-secondary"} onClick={this.handleCancel}>
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}
