import React, { Component } from "react";
import Table from "../../../components/Table/Table";
import { IoIosRocket as InTransitIcon } from "react-icons/io";
import moment from "moment";
import referenceLibrary from "../../../store/referenceLibrary";

export default class Tickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      warranties: [],
      statusFilter: "All",
      searchFilter: "",
    };
  }

  formatValueWithCommas = (value) => {
    if (value === "") return value;

    // Split the value into integer and decimal parts (if applicable)
    const parts = value?.toString().split(".");
    let formattedValue = parts && parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (parts?.length > 1) {
      const decimalPart = parts && parts[1].slice(0, 2);
      formattedValue += `.${decimalPart}`;
    }

    return formattedValue;
  };

  filterChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  statusFilterChange = (value) => {
    this.setState({ statusFilter: value });
  };
  render() {
    let filteredWarranties = [];
    if (!this.props.data) return <div>No ticket</div>;
    if (this.state.searchFilter) {
      this.props.data?.forEach((item) => {
        if (
          item.owner_name?.toLowerCase().includes(this.state.searchFilter.toLowerCase()) ||
          item.user_device.device.name?.toLowerCase().includes(this.state.searchFilter.toLowerCase()) ||
          parseInt(item.id) === parseInt(this.state.searchFilter) ||
          item.ticket_number?.toLowerCase().includes(this.state.searchFilter.toLowerCase())
        ) {
          filteredWarranties.push(item);
        }
      });
    } else {
      this.props.data?.map((val) => filteredWarranties.push(val));
    }

    const tableHead = [
      {
        id: "ticket_number",
        label: "ID",

        valueDisplay: (value) => value,
      },
      {
        id: "date_received",
        label: "Date Received",

        defaultSort: "desc",
        valueDisplay: (value) => moment(value).format("DD-MM-YYYY"),
      },
      {
        id: "last_activity_date",
        label: "Last Activity Date",

        valueDisplay: (value) => moment(value).format("DD-MM-YYYY"),
      },
      {
        id: "ticket_type",
        label: "Ticket Type",

        valueDisplay: (value) => value || "(missing)",
      },
      {
        id: "user_device",
        label: "Device Name",

        valueDisplay: (value) => value || "(missing)",
      },

      {
        id: "refund_amount",
        label: "Refund Amount",
        width: "50px",

        valueDisplay: (value) => `$ ${this.formatValueWithCommas(value)}`,
      },
      //{id: 'serial_number', label: 'Serial Number', width: '230px', valueDisplay: (value) => value},
      {
        id: "status",
        label: "Status",
        valueDisplay: (value) => {
          const selector = referenceLibrary.statusSelectors.filter((item) => {
            return item.name?.toLocaleLowerCase().includes(value?.toLocaleLowerCase());
          });
          if (selector) {
            return (
              <span
                style={{
                  color: selector.color,
                }}
              >
                {selector.icon}
                <span style={{ fontSize: "0.8em" }}>{value}</span>
              </span>
            );
          } else {
            return (
              <span>
                <InTransitIcon className={"warranties-summary-individual-icon"} />
                <span style={{ fontSize: "0.8em" }}>{value}</span>
              </span>
            );
          }
        },
      },
      {
        id: "assigned_to",
        label: "Assigned To",
        valueDisplay: (value) => value,
      },
    ];

    if (!(this.props.data && this.props.data?.length > 0)) return <div>No ticket</div>;
    return (
      <div>
        <div className="d-flex justify-content-end" style={{ marginBottom: "10px" }}>
          <div className="input-group col-4">
            <input
              type="text"
              placeholder="Search tickets"
              id={"searchFilter"}
              className={"form-control"}
              onChange={this.filterChange}
              value={this.state.searchFilter}
              width={"70px"}
            />
            <div className="input-group-prepend">
              <span className="form-control ">
                <i className="fa fa-search form-control-icon"></i>
              </span>
            </div>
          </div>
        </div>
        <div className={"admin-table-container"}>
          <Table
            head={tableHead}
            data={filteredWarranties}
            reloadMini={this.retrieveWarranties}
            protectedFetch={this.props.protectedFetch}
            selectedbrandID={this.props.selectedbrandID}
            role={this.userRole}
          />
        </div>
      </div>
    );
  }
}
