import React, { Component } from "react";
import Modal from "react-modal";
import { Redirect } from "react-router-dom";
import "../../common.css";

import LoadingPage from "../../../components/Loading/Loading";
import config from "../../../config";
import errorFunctions from "../../../store/errorFunctions";
import { postNewStaffFunction } from "./api";
import "./StaffAdd.css";
Modal.setAppElement("body");

export default class StaffAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      redirect: false,
      statusTicket: "",
      modalStatusConfirm: false,
      brands: [],
      roles: [],
      currentRole: "",
      name: "",
      email: "",
      password1: "",
      password2: "",
      errorPassword: false,
      errorBrand: false,
      typePassword: true,
    };
  }

  componentDidMount() {
    this.retriveAllBrand();
    this.retriveAllRoles();
  }

  retriveAllBrand = () => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + "brands/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({ loading: false, brands: res });
      })
      .catch(() => {
        console.log("fail brand");
        this.setState({ loading: false });
      });
  };

  retriveAllRoles = () => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + "roles/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({ loading: false, roles: res });
      })
      .catch(() => {
        console.log("fail brand");
        this.setState({ loading: false });
      });
  };

  handleSubmit = async (event) => {
    this.setState({ loading: true });
    event.preventDefault();
    const selectedBrandIds = Array.from(document.querySelectorAll("input[type='checkbox']:checked")).map((checkbox) => checkbox.value);

    let payload = {
      brands: selectedBrandIds,
      name: this.state.name ? this.state.name : this.state.details.name,
      email: this.state.email ? this.state.email : this.state.details.email,
      user_role: this.state.currentRole,
      password1: this.state.password1,
      password2: this.state.password2,
    };

    if (payload.password1 !== payload.password2 && payload.brands.length === 0) {
      this.setState({ loading: false, errorPassword: true, errorBrand: true });
    } else if (payload.password1 !== payload.password2) {
      this.setState({ loading: false, errorPassword: true, errorBrand: false });
    } else if (payload.brands.length === 0) {
      this.setState({ loading: false, errorBrand: true, errorPassword: false });
    } else {
      const result = await postNewStaffFunction(payload);
      if (result.status === 200 || result.status === 201) {
        this.setState({ redirect: true });
      } else {
        alert(result?.response?.data.non_field_errors?.[0]);
        this.setState({ loading: false });
      }
    }
  };
  handleRole = (e) => {
    this.setState({ currentRole: e.target.value });
  };
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  render() {
    if (this.state.redirect) {
      // Redirect only occurs when its a super user
      return <Redirect to={"/manage-staff"} />;
    } else if (this.state.loading) {
      return <LoadingPage />;
    }

    return (
      <div className={"page"}>
        <div className="card">
          <h4 className={"card-header"}>Add Staff</h4>
          <form onSubmit={this.handleSubmit}>
            <div className="col-6">
              <div className="card-body">
                <div className={"single-warranty-details-item"}>
                  <label className={"single-warranty-details-label required"}>
                    Full name<span>:</span>
                  </label>
                  <input type="text" id="name" required={true} className="form-control" onChange={this.handleChange} />
                </div>

                <div className={"single-warranty-details-item"}>
                  <label className={"single-warranty-details-label required"}>
                    Email <span>:</span>
                  </label>
                  <input type="email" id="email" required={true} className="form-control" onChange={this.handleChange} />
                </div>

                <div className={"single-warranty-details-item"}>
                  <label className={"single-warranty-details-label required"}>
                    Brands<span>:</span>
                  </label>
                  <span>
                    {this.state.brands.map((brand) => (
                      <div className="col-6" key={brand.id}>
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" id={`brand-${brand.id}`} value={brand.id} />
                          <label className="form-check-label" htmlFor={`brand-${brand.id}`}>
                            {brand.name}
                          </label>
                        </div>
                      </div>
                    ))}
                  </span>
                  {this.state.errorBrand ? <span className="error-text">Pick at least one brand.</span> : null}
                </div>
                <div className={"single-warranty-details-item"}>
                  <label className={"single-warranty-details-label required"}>
                    Password{" "}
                    <i
                      className={this.state.typePassword ? "password-show fa fa-eye" : "password-show fa fa-eye-slash"}
                      aria-hidden="true"
                      onClick={() => this.setState({ typePassword: !this.state.typePassword })}
                    ></i>
                    <span>:</span>
                  </label>
                  <input
                    type={this.state.typePassword ? "password" : "text"}
                    id="password1"
                    required={true}
                    className="form-control"
                    onChange={this.handleChange}
                  />
                  {this.state.errorPassword ? <span className="error-text">Password did not match</span> : null}
                </div>

                <div className={"single-warranty-details-item"}>
                  <label className={"single-warranty-details-label required"}>
                    Confirm Password <span>:</span>
                  </label>
                  <input
                    type={this.state.typePassword ? "password" : "text"}
                    id="password2"
                    required={true}
                    className="form-control"
                    onChange={this.handleChange}
                  />
                  {this.state.errorPassword ? <span className="error-text">Password did not match</span> : null}
                </div>

                <div className={"single-warranty-details-item"}>
                  <label className={"single-warranty-details-label required"}>
                    Role<span>:</span>
                  </label>

                  <span className="container">
                    <select className="form-control custom-select" onChange={this.handleRole} required={true}>
                      <option id="currentRole" value={""}>
                        -Select Role-
                      </option>
                      {this.state?.roles?.map((val, i) => {
                        return (
                          <option key={i} id="currentRole" value={val.name}>
                            {val.name}
                          </option>
                        );
                      })}
                    </select>
                  </span>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-2">
                  <button className={"btn btn-outline-primary"}>Create</button>
                </div>
                <div className="col-2">
                  <a className={"btn btn-outline-secondary"} href={"/manage-staff"}>
                    Close
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
