import React, { Component } from "react";
import { Link } from "react-router-dom";
import config from "../../../config";
import errorFunctions from "../../../store/errorFunctions";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";

import { icons } from "../../../img/icons";
import utilityFunctions from "../../../store/utilityFunctions";
Modal.setAppElement("body");

export default class DeviceDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      deviceName: "",
      width: "",
      length: "",
      height: "",
      weight: "",
      description: "",
      shippingVendor: "auspost",
      image: null,
      imageName: "",
      accessories: [],
      errorNotification: "",
      accessory: null,
      showAddAccessory: false,
      accName: "",
      accQty: "",
      deviceId: this.props.computedMatch?.params.id,
      showDeleteAccessory: false,
    };
  }

  retrieveDevice = async (id) => {
    this.setState({ loading: true });
    await this.props
      .protectedFetch(config.backendServer + "device/" + id + "/", "GET", { id }, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({
          loading: false,
          deviceName: res.name,
          id: res.id,
          width: res.width,
          length: res.length,
          height: res.height,
          weight: res.weight,
          description: res.description,
          shippingVendor: res.shipping_vendor,
          imageName: res.image,
          accessories: res.accessories.sort(function (a, b) {
            return a.id - b.id;
          }),
        });
      })
      .catch(() => {
        this.setState({ loading: false });
        console.log("fail");
      });
  };
  componentDidMount() {
    const deviceId = this.props.computedMatch?.params.id;
    if (deviceId && deviceId !== "new") this.retrieveDevice(deviceId);
  }
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  handleFileUpload = (e) => {
    // TODO - verify file upload to make sure it's good
    const file = e.target.files[0];
    const imageFile = URL.createObjectURL(file);
    const allowedTypes = ["image/jpeg", "image/png"];
    this.setState({ image: imageFile, imageName: file.name });
    if (!allowedTypes.includes(file.type)) {
      alert("Only JPG, JPEG and PNG files are allowed.");
      this.setState({ allowUpload: false });
    } else {
      this.setState({ allowUpload: true });
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.state.deviceName) {
      this.setState({ errorNotification: "Device name can not be blank" });
      return;
    }

    const mediaFile = document.getElementById("uploadFile")?.files[0] || null;
    let formData = new FormData();
    formData.append("name", this.state.deviceName);
    formData.append("width", this.state.width);
    formData.append("length", this.state.length);
    formData.append("height", this.state.height);
    formData.append("weight", this.state.weight);
    formData.append("description", this.state.description);
    formData.append("brand", this.props.selectedbrandID);
    formData.append("shipping_vendor", this.state.shippingVendor);
    formData.append("accessories", JSON.stringify(this.state.accessories));
    if (mediaFile) formData.append("image", mediaFile);

    const isEdit = this.state.deviceId !== "new";

    const url = isEdit ? config.backendServer + `device/` + this.state.deviceId + "/" : config.backendServer + `device/`;

    this.props
      .protectedFetch(url, isEdit ? "PUT" : "POST", formData, (res) => res.ok, true)
      .then(() => {
        //mini reload
        alert("Device saved");
        window.location.href = "/device";
        this.props.reloadDevice();
        this.props.handleCloseModal();
      })
      .catch((err) => {
        console.log(err);
        this.setState({ errorNotification: err });
      });
  };

  handleSubmitAccessory = () => {
    let payload = {
      name: this.state.accName || this.state.accessory.name,
      quantity: parseInt(this.state.accQty) || this.state.accessory.quantity,
    };

    const url = this.state.accessory
      ? config.backendServer + `device/` + this.state.deviceId + "/accessory/" + this.state.accessory.id + "/"
      : config.backendServer + `device/` + this.state.deviceId + "/accessory/";

    this.props
      .protectedFetch(url, this.state.accessory ? "PATCH" : "POST", payload, errorFunctions.checkResponseThenJson)
      .then(() => {
        //mini reload
        this.setState({
          accessory: null,
          accName: "",
          accQty: 0,
          showAddAccessory: false,
        });
        this.retrieveDevice(this.state.deviceId);
      })
      .catch((err) => {
        alert(err);
      });
  };

  handleDeleteAccessory = (e) => {
    const userInfo = localStorage.getItem("userInfo") || utilityFunctions.getCookie("userInfo");
    e.stopPropagation();
    //state jika di refresh akan kembali null, jadi ambil data dari localstorage atau cookies solusinya
    const token = userInfo?.access_token || JSON.parse(userInfo)?.access_token;
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const deviceToDelete = this.state.accessory.id; // Storing array id in showDeleteModal
    this.setState({ loading: true, showDeleteModal: false });
    const url = config.backendServer + "device/" + this.state.deviceId + "/accessory/" + deviceToDelete;

    fetch(url, options)
      .then((resp) => {
        if (resp.status === 406) alert("Cannot delete device. Accessory is already registered by at least one user(s)");
        else if (resp.status === 204) alert("Delete success");
        else alert("status:" + resp.status);

        this.setState({ showDeleteAccessory: false });
        this.retrieveDevice(this.state.deviceId);
        // this.props.reloadDevice();
      })
      .catch((err) => {
        alert(err);
        this.setState({ showModal: false });
      });
  };

  render() {
    const imgArray = this.state.imageName?.split("/");
    const imgName = imgArray?.length > 0 ? imgArray[imgArray.length - 1] : imgArray;
    return (
      <div className="page">
        <h5>Device Detail</h5>
        <form onSubmit={this.handleSubmit} className="card">
          <div className="row card-body">
            <div className="col-lg-3 col-sm-12">
              <div className="card">
                <div className="card-header">Image</div>
                <div className="card-body text-center">
                  <img src={this.state.image || this.state.imageName} alt={"device image"} width="100%" />
                  <div className="m-3">
                    <small>{`${this.state.imageName ? imgName : "No file choosen"}`}</small>
                  </div>
                  <div className="text-center">
                    <label htmlFor="uploadFile" className="custom-file-upload m-3 ">
                      {"Upload Image "} <img src={icons.Upload} alt="" />
                    </label>
                    <input accept="image/jpeg, image/png" type={"file"} id={"uploadFile"} onChange={this.handleFileUpload} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-sm-12">
              <div className="card">
                <div className="card-header">Device Property</div>
                <div className="card-body">
                  <div className={"mb-3"} style={{ display: "none" }}>
                    <label className={"single-warranty-details-label"} htmlFor="deviceId">
                      Device ID
                    </label>
                    <input type="text" id="deviceId" className="form-control" value={this.props.deviceData?.id} disabled />
                  </div>

                  <div className={"mb-3"}>
                    <label className={"single-warranty-details-label "} htmlFor="deviceName">
                      Device Name*
                    </label>

                    <input
                      type="text"
                      id="deviceName"
                      //  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      required={true}
                      className="form-control"
                      onChange={this.handleChange}
                      value={this.state.deviceName}
                    />
                  </div>

                  <div className="row mb-3">
                    <div className="col">
                      <label className={"single-warranty-details-label "} htmlFor="width">
                        Width*
                      </label>
                      <div className="input-group">
                        <input
                          type="number"
                          id="width"
                          required={true}
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.width}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">cm</span>
                        </div>
                      </div>
                    </div>

                    <div className="col">
                      <label className={"single-warranty-details-label "} htmlFor="length">
                        Length*
                      </label>
                      <div className="input-group">
                        <input
                          type="number"
                          id="length"
                          //  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          required={true}
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.length}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">cm</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className={"col"}>
                      <label className={"single-warranty-details-label"} htmlFor="height">
                        Height*
                      </label>
                      <div className="input-group">
                        <input
                          type="number"
                          id="height"
                          //  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          required={true}
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.height}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">cm</span>
                        </div>
                      </div>
                    </div>
                    <div className={"col"}>
                      <label className={"single-warranty-details-label"} htmlFor="weight">
                        Weight*
                      </label>
                      <div className="input-group">
                        <input
                          type="number"
                          id="weight"
                          //  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          required={true}
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.weight}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">kg</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={"mb-3"}>
                    <label className={"single-warranty-details-label"} htmlFor="description">
                      Descriptions
                    </label>
                    <input
                      type="text"
                      id="description"
                      //  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      // required={true}
                      className="form-control"
                      onChange={this.handleChange}
                      value={this.state.description}
                    />
                  </div>
                  <div className={"mb-3"}>
                    <label className={"single-warranty-details-label"} htmlFor="shippingVendor">
                      Shipping vendor
                    </label>
                    <select id="shippingVendor" onChange={this.handleChange} value={this.state.shippingVendor} className="form-select" required>
                      <option value="auspost">auspost</option>
                      <option value="transdirect">transdirect</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg col-sm-12 overflow-auto" style={{ height: "70vh" }}>
              <div className="card ">
                <div className="card-header d-flex justify-content-between">
                  Accessories
                  {this.state.deviceId !== "new" && (
                    <button
                      className="btn btn-outline-dark btn-sm"
                      type="button"
                      onClick={() => this.setState({ showAddAccessory: true, accessory: null })}
                    >
                      Add
                    </button>
                  )}
                </div>

                <div className="card-body ">
                  {this.state.accessories?.length > 0 ? (
                    <table className="table table-striped ">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Name</th>
                          <th scope="col">Qty</th>
                          <th scope="col"></th>
                          {/* <th scope="col">Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.accessories?.map((item, i) => {
                          return (
                            <tr key={i} onClick={() => this.setState({ showAddAccessory: true, accessory: item })} style={{ cursor: "pointer" }}>
                              <th scope="row" className="border-0">
                                {i + 1}
                              </th>
                              <td className="border-0">{item.name}</td>
                              <td className="border-0">{item.quantity}</td>
                              <td className="border-0">
                                <button
                                  type="button"
                                  className="btn btn-outline-danger btn-sm"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.setState({ showDeleteAccessory: true, accessory: item });
                                  }}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <div>Data not found</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <div className="d-flex justify-content-start gap-3">
              <button className="btn btn-outline-primary ">Save</button>
              <Link to="/device" className="link-dark text-decoration-none">
                <button className="btn btn-outline-secondary " type="button">
                  Close
                </button>
              </Link>
            </div>
          </div>
        </form>
        <Modal
          isOpen={this.state.showAddAccessory}
          //onRequestClose={()=>this.setState({showDeclineModal: false})}
          contentLabel={"Add Device"}
          className={"input-modal"}
        >
          <button onClick={() => this.props.handleCloseModal()} className={"confirm-modal-close"}>
            <MdClose />
          </button>{" "}
          <form className="card" onSubmit={() => this.handleSubmitAccessory()}>
            <div className="card-header">{this.state.accessory ? "Edit Accessory" : "Add Accessory"}</div>
            <div className="card-body">
              <div>
                <div>Name*</div>
                <input
                  id={"accName"}
                  className="form-control"
                  required
                  type="text"
                  value={this.state.accName || this.state.accessory?.name}
                  onChange={(e) => this.handleChange(e)}
                />
              </div>
              <div>
                <div>Quantity*</div>
                <input
                  id={"accQty"}
                  className="form-control"
                  required
                  type="number"
                  value={this.state.accQty || this.state.accessory?.quantity}
                  onChange={(e) => this.handleChange(e)}
                />
              </div>
            </div>
            <div className="card-footer d-flex justify-content-start gap-3">
              <button type="submit" className="k-btn k-btn-primary">
                Save
              </button>

              <button className="k-btn k-btn-outline-primary" type="button" onClick={() => this.setState({ showAddAccessory: false })}>
                Close
              </button>
            </div>
          </form>
        </Modal>
        <Modal isOpen={this.state.showDeleteAccessory} contentLabel={"Set as Out of Device"} className={"confirm-modal"}>
          <div className="card">
            <div className="card-header">Delete</div>

            <div className={"card-body"}>
              <div className="card-title">Are you sure want to delete Device {this.state.accessory?.name}</div>
            </div>
            <div className="card-footer">
              {" "}
              <button type="button" className={"btn btn-outline-danger mr-3"} onClick={(e) => this.handleDeleteAccessory(e)}>
                Delete
              </button>
              <button type={"button"} className={"btn btn-outline-secondary "} onClick={() => this.setState({ showDeleteAccessory: false })}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
