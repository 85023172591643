import CreditCard from "./credit_card.png";
import StoreFront from "./storefront.png";
import LocationOn from "./location_on.svg";
import DateRange from "./date_range.svg";
import Upload from "./upload.svg";
import Download from "./download.svg";
import Paper from "./paper.svg";

export const icons = {
  CreditCard: CreditCard,
  StoreFront: StoreFront,
  LocationOn: LocationOn,
  DateRange: DateRange,
  Upload: Upload,
  Download: Download,
  Paper: Paper,
};
