import React, { Component } from "react";
import moment from "moment";

export default class Logs extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    if (this.props.customer.change_logs?.length < 1) return <></>;
    else
      return (
        <div>
          <b>Change Log</b>
          <ul className="list-group list-group overflow-auto h-50">
            <li className="list-group-item bg-light">
              <div className="row">
                <div className="col-2">Field</div>
                <div className="col-4">Value</div>
                <div className="col-3">By</div>
                <div className="col-3">Time</div>
              </div>
            </li>
            {this.props.logData &&
              this.props.customer.change_logs
                ?.sort((a, b) => moment(b.created) - moment(a.created))
                .map((log, idx) => {
                  return (
                    <li className="list-group-item mb-2" key={idx}>
                      <div className="row">
                        <div className="col-2">{log.field_name}</div>
                        <div className="col-4">{`${log.old_value} => ${log.new_value}`}</div>
                        <div className="col-3">{log.changed_by || this.props.customer.first_name + " " + this.props.customer.last_name}</div>
                        <div className="col-3">{moment(log.created).format("DD-MM-yyyy HH:mm")}</div>
                      </div>
                    </li>
                  );
                })}
          </ul>
        </div>
      );
  }
}

// {
//     "field_name": "first_name",
//     "old_value": "Amirazyz",
//     "new_value": "Amirazyzw",
//     "changed_by": "William Tekimam",
//     "created": "2024-07-12T17:59:42.210709+07:00"
// },
