import React, { Component } from "react";
import Modal from "react-modal";
import "../../common.css";
import { MdClose } from "react-icons/md";
import config from "../../../config";
import errorFunctions from "../../../store/errorFunctions";
// import { postNewStaffFunction } from "./api";

Modal.setAppElement("body");

export default class InventoryAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      id: "",
      material_number: this.props.inventoryData?.material_number || "",
      device_name: this.props.inventoryData?.device_name || "",
      device: this.props.inventoryData?.device || 0,
      spare_part_name: this.props.inventoryData?.spare_part_name || "",
      quantity: this.props.inventoryData?.quantity || "",
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let payload = {
      material_number: this.state.material_number,
      device: this.state.device,
      spare_part_name: this.state.spare_part_name,
      quantity: parseInt(this.state.quantity),
    };

    const url = this.props.isEdit
      ? config.backendServer + `inventories/` + this.props.inventoryData.inventory_id + "/"
      : config.backendServer + `inventories/`;

    this.props
      .protectedFetch(url, this.props.isEdit ? "PUT" : "POST", payload, errorFunctions.checkResponseThenJson)
      .then(() => {
        //mini reload
        this.setState({
          showAssignModal: false,
          material_number: "",
          device: 0,
          spare_part_name: "",
          quantity: 0,
        });
        this.props.reloadInventory();
        this.props.handleCloseModal();
      })
      .catch((err) => {
        alert(err);
      });
  };
  handleRole = (e) => {
    this.setState({ currentRole: e.target.value });
  };
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  render() {
    if (this.state.redirect) {
      // Redirect only occurs when its a super user
      // return <Redirect to={"/manage-staff"} />;
    }

    return (
      <div className={"card"} style={{ width: "100%" }}>
        <button onClick={() => this.props.handleCloseModal()} className={"confirm-modal-close"}>
          <MdClose />
        </button>
        <h4 className={"card-header"}>{`${this.props.isEdit ? "Edit" : "Add"} Inventory`}</h4>
        <form onSubmit={this.handleSubmit}>
          <div className="card-body">
            <div className={"single-warranty-details-item  mb-3"}>
              <label className={"single-warranty-details-label required"} htmlFor="maerial_number">
                Material Number<span></span>
              </label>
              <input
                type="text"
                id="material_number"
                required={true}
                className="form-control"
                value={this.state.material_number}
                onChange={this.handleChange}
              />
            </div>
            <div className={"single-warranty-details-item mb-3"}>
              <label className={"single-warranty-details-label required"} htmlFor="device">
                Model<span></span>
                {this.state.device.id}
              </label>

              <select required={true} id="device" className="form-select" onChange={this.handleChange} value={this.state.device}>
                <option value="">----Select Device----</option>
                {this.props.devices &&
                  this.props.devices.length > 0 &&
                  this.props.devices.map(function (device, i) {
                    return (
                      <option key={i} value={device.id}>
                        {device.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className={"single-warranty-details-item mb-3"}>
              <label className={"single-warranty-details-label required"} htmlFor="spare_part_name">
                Part Name<span></span>
              </label>
              <input
                type="text"
                id="spare_part_name"
                //  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                required={true}
                className="form-control"
                onChange={this.handleChange}
                value={this.state.spare_part_name}
              />
            </div>
            <div className={"single-warranty-details-item mb-3"}>
              <label className={"single-warranty-details-label required"} htmlFor="quantity">
                Stock Amount<span></span>
              </label>
              <input type="number" id="quantity" required={true} className="form-control" onChange={this.handleChange} value={this.state.quantity} />
            </div>
          </div>
          <div className="card-footer ">
            <div className="d-flex justify-content-start gap-2">
              <button className="k-btn k-btn-primary ">Save</button>
              <button className="k-btn k-btn-outline-primary" onClick={this.props.handleCloseModal}>
                Close
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
