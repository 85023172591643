import React, { Component } from "react";
import Chart from "chart.js/auto";

export default class PieChart extends Component {
  chartRef = React.createRef();

  componentDidMount() {
    const ctx = this.chartRef.current.getContext("2d");

    new Chart(ctx, {
      type: "pie",
      data: {
        labels: this.props.data?.labels,
        datasets: [
          {
            data: this.props.data?.data,
            label: "Total",
            backgroundColor: this.props.data?.backgroundColor,
            borderColor: this.props.data?.borderColor,
            fill: true,
          },
        ],
      },
      options: {
        // onClick: (e, element) => {
        //   if (element.length > 0) {
        //     var label = this.props.data.labels[element[0].index];
        //     window.location.href = `tickets?allocated=${label}`;
        //   }
        // },
        legend: {
          display: true, // Ensure the legend is displayed
          position: "bottom", // Can be 'top' or 'bottom' for horizontal layout
          align: "start", // Can be 'start', 'center', or 'end'
          fullWidth: true, // This is the default, ensuring the legend takes the full width
          labels: {
            boxWidth: 40, // Adjust the box width for legend items
            padding: 10, // Adjust the padding between legend items
          },
        },
        onHover: (event, chartElement) => {
          if (chartElement.length == 1) {
            event.native.target.style.cursor = "pointer";
          }
          if (chartElement.length == 0) {
            event.native.target.style.cursor = "default";
          }
        },
        plugins: {
          legend: {
            position: "bottom",
          },
        },
      },
    });
  }
  render() {
    return (
      <div>
        <canvas id="myChart" ref={this.chartRef} />
      </div>
    );
  }
}
