import React, { Component } from "react";
import config from "../../../../../config";
import { IoMdRemoveCircle } from "react-icons/io";
import utilityFunctions from "../../../../../store/utilityFunctions";
import axios from "axios";
import { icons } from "../../../../../img/icons";
import LoadingPage from "../../../../../components/Loading/Loading";
import Select from "react-select";

export default class QualityReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      actionIndex: 0,
      check_detail: this.props.reportContent?.check_detail || "",
      videos: this.props.reportContent?.videos || [],
      videoFiles: [],
      newVideoFiles: [],
      technician: this.props.reportContent?.technician || 0,
    };
  }
  componentDidMount() {
    this.getVideoFiles();
  }
  getVideoFiles = () => {
    if (this.state.videos.length > 0) {
      this.state.videos.forEach((v) => {
        const vidType = typeof v.video;
        if (vidType === "string") {
          this.setState({ loadVideo: true });
          const vidName = v.video?.split("/")[v.video?.split("/").length - 1];
          fetch(v.video)
            .then((res) => res.blob())
            .then((blob) => {
              const file = new File([blob], vidName, blob);
              const videoFiles = [...this.state.videoFiles, file];
              this.setState({ videoFiles: videoFiles, loadVideo: false });
            });
        }
      });
    }
  };

  blobToFile = (theBlob, fileName) => {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  };

  handleSubmit = (e) => {
    if (this.state.videoFiles.length === 0) {
      alert("Video must not be empty");
      this.props.closeModal();
      this.props.setDefaultTab();
    } else {
      this.setState({ loading: true });
      e.preventDefault();
      console.log(this.state.addComment);

      let formData = new FormData();
      if (this.state.videoFiles.length > 0) {
        this.state.videoFiles.forEach((video) => {
          formData.append("videos", video);
        });
      }
      formData.append("check_detail", this.state.check_detail);
      formData.append("technician", this.state.technician);
      const url =
        this.props.reportMode === "new"
          ? `${config.backendServer}ticket/${this.props.ticketId}/quality-check/new/`
          : `${config.backendServer}ticket/quality-check-reports/${this.props.reportContent.id}/`;

      const userInfo = localStorage.getItem("lgn") || utilityFunctions.getCookie("userInfo");
      const token = JSON.parse(userInfo).access_token;
      let options = {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      };
      if (this.props.reportMode === "new")
        axios
          .post(url, formData, options)
          .then(() => {
            this.setState({
              check_detail: "",
              videos: [],
              technician: 0,
              loading: false,
            });
            this.props.retrieveWarranty();
            this.props.closeModal();

            // this.retrieveWarranty(dataToSend.id);
          })
          .catch(() => {
            console.log("fail");
            this.setState({ loading: false });
            this.props.closeModal();
          });
      else
        axios
          .put(url, formData, options)
          .then(() => {
            this.setState({
              check_detail: "",
              videos: [],
              technician: 0,
              loading: false,
            });
            this.props.retrieveWarranty();
            this.props.closeModal();
            this.props.setDefaultTab();

            // this.retrieveWarranty(dataToSend.id);
          })
          .catch(() => {
            console.log("fail");
            this.setState({ loading: false });
            this.props.closeModal();
          });
    }
  };
  handleInputChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });
  };
  handleDropDown = (e) => {
    // let technician = e.target.value;
    this.setState({ technician: e.value });
  };
  handleFileUpload = (e) => {
    // TODO - verify file upload to make sure it's good
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "video/mp4", "video/webm", "video/quicktime"];

    const imageFile = URL.createObjectURL(file);
    this.setState({ image: imageFile, imageName: file.name });

    if (!allowedTypes.includes(file?.type)) {
      alert("Only JPG, JPEG, PNG, WEBM , MOV and MP4 files are allowed.");
      this.setState({ allowUpload: false });
    } else {
      this.setState({ allowUpload: true });
    }
    const video = document.getElementById("uploadFile").files[0];
    const videos = [...this.state.newVideoFiles, video];
    const videoFiles = [...this.state.videoFiles, video];
    this.setState({ newVideoFiles: videos, videoFiles: videoFiles, newVideoName: video.name });
  };
  handleDeletePart = (item) => {
    const vidName = item.video?.split("/")[item.video?.split("/").length - 1];

    if (this.props.reportMode !== "view") {
      const resp = window.confirm("Do you really want to delete  " + vidName);
      const videoString = this.state.videos.filter((x) => x.video !== item.video);
      const videoNew = this.state.newVideoFiles.filter((x) => x.name !== item.name);
      const videoPayload = this.state.videoFiles.filter((x) => x.name !== vidName);
      if (resp)
        this.setState({
          videos: videoString,
          newVideoFiles: videoNew,
          videoFiles: videoPayload,
        });
    }
  };

  render() {
    if (this.state.loading) {
      return <LoadingPage small />;
    }
    const imgArray = this.state.imageName?.split("/");
    const imgName = imgArray?.length > 0 ? imgArray[imgArray?.length - 1] : imgArray;
    // const isVideo =
    //   this.state.imageName &&
    //   (this.state.imageName.includes(".mp4") || this.state.imageName.includes(".webm") || this.state.imageName.includes(".mov"));
    const technicianOption = this.props.employeeList?.map((val) => {
      return { value: val.id, label: val.name };
    });

    const selectedTechnician = technicianOption.find((x) => x.value === this.state.technician);
    return (
      <div className="card">
        <form onSubmit={this.handleSubmit}>
          <div className="card-header">
            <h5>Quality Check Report</h5>
          </div>
          <div className="card-body" style={{ width: "800px", height: "60vh", overflow: "auto" }}>
            <div className="mb-3 border rounded p-3">
              <b>Testing & Quality Check</b>
              <div>
                {this.props.reportMode !== "view" ? (
                  <textarea
                    id="check_detail"
                    className="form-control"
                    required={true}
                    rows={4}
                    style={{ width: "100%" }}
                    onChange={this.handleInputChange}
                    value={this.state.check_detail}
                  />
                ) : (
                  <textarea
                    id="check_detail"
                    className="form-control"
                    disabled
                    rows={4}
                    style={{ width: "100%" }}
                    onChange={this.handleInputChange}
                    value={this.state.check_detail}
                  />
                )}
              </div>
            </div>
            <div className="mb-3">
              <b>Upload Videos</b>

              <div className="border p-0 " style={{ borderRadius: "8px" }}>
                <div className="text-start">
                  {this.props.reportMode !== "view" && (
                    <div>
                      <div>
                        <label htmlFor="uploadFile" className="custom-file-upload m-3 ">
                          {"Upload "} <img src={icons.Upload} alt="" />
                        </label>
                        <small>{`${this.state.imageName ? imgName : "No file choosen"}`}</small>
                        <input accept="video/mp4, video/webm, video/quicktime" type={"file"} id={"uploadFile"} onChange={this.handleFileUpload} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div style={{ display: "grid" }} className="p-3">
                <div className="row">
                  {this.state.videos.map((item, i) => {
                    return (
                      <div className="col-4 p-1" key={i}>
                        <div>
                          <video width="200px" controls>
                            <source src={item.video} type="video/mp4" />
                            <source src={item.video} type="video/webm" />
                            <source src={item.video} type="video/quicktime" />
                            <source src={item.video} type="video/x-quicktime" />
                          </video>

                          {this.props.reportMode !== "view" && (
                            <div
                              className="ml-3"
                              onClick={() => {
                                this.handleDeletePart(item);
                              }}
                              style={{ color: "red", cursor: "pointer" }}
                            >
                              <IoMdRemoveCircle />
                              <span className="ml-2">Delete</span>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                  {this.state.newVideoFiles.map((item, i) => {
                    return (
                      <div className="col-4 p-1" key={i}>
                        <div>
                          <video width="200px" controls>
                            <source src={item.File} type="video/mp4" />
                            <source src={item.File} type="video/webm" />
                            <source src={item.File} type="video/quicktime" />
                            <source src={item.File} type="video/x-quicktime" />
                          </video>

                          {this.props.reportMode !== "view" && (
                            <div
                              className="ml-3"
                              onClick={() => {
                                this.handleDeletePart(item);
                              }}
                              style={{ color: "red", cursor: "pointer" }}
                            >
                              <IoMdRemoveCircle />
                              <span className="ml-2">Delete</span>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>

                {/* {this.state.newVideoFiles.map((item, i) => {
                  return (
                    <div className="row p-1 border" key={i}>
                      <div className="col-11">
                        <a href={item.video}>{"video " + (i + 1).toString()}</a>
                      </div>
                      <div
                        className="col-1"
                        onClick={() => {
                          this.handleDeletePart(item);
                        }}
                      >
                        {this.props.reportMode !== "view" && <IoMdRemoveCircle style={{ color: "red", cursor: "pointer" }} />}
                      </div>
                    </div>
                  );
                })} */}
              </div>
            </div>
            <div className="mb-3 border rounded p-3">
              <b>Worked Performed</b>
              <div className={"single-warranty-details-item"}>
                <span>Technician:</span>
                <Select
                  data-testid="assign-to-select"
                  value={selectedTechnician}
                  isSearchable={true}
                  options={technicianOption}
                  onChange={(obj) => {
                    this.handleDropDown(obj);
                  }}
                  isDisabled={this.props.reportMode === "view"}
                />
                {/* <select
                  style={{ width: "100%" }}
                  required={true}
                  disabled={this.props.reportMode === "view"}
                  name="technician"
                  className="form-select"
                  id="technician"
                  onChange={(e) => {
                    if (this.props.reportMode !== "view") this.handleDropDown(e);
                  }}
                  value={this.state.technician}
                >
                  <option key={-1}>Employee</option>
                  {this.props.employeeList?.map((val, i) => {
                    return (
                      <option key={i} value={parseInt(val?.id)}>
                        {val?.name}
                      </option>
                    );
                  })}
                </select> */}
              </div>
            </div>
          </div>
          <div className="card-footer">
            {this.props.reportMode !== "view" && (
              <button
                type="submit"
                // onClick={() => this.props.closeModal()}
                className="btn btn-outline-primary m-2"
              >
                Save
              </button>
            )}
            <button onClick={() => this.props.closeModal()} className="btn btn-outline-secondary m-2">
              Close
            </button>
          </div>
        </form>
      </div>
    );
  }
}
