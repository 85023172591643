import React, { Component } from "react";
import LoadingPage from "../../components/Loading/Loading";
import Table from "../../components/Table/TableDevice";
import Modal from "react-modal";
import { Link } from "react-router-dom";
// import { IoMdAddCircle } from "react-icons/io";
import config from "../../config";
import errorFunctions from "../../store/errorFunctions";
import referenceLibrary from "../../store/referenceLibrary";
import utilityFunctions from "../../store/utilityFunctions";
import DeviceAdd from "./DeviceAdd/DeviceAdd";
import "../common.css";
import "./Device.css";

export default class Device extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      statusFilter: "All",
      searchFilter: "",
      statusSelector: 0,
      devices: [],
      deviceId: undefined,
      showAddDevice: false,
      isEdit: false,
      rowDevice: undefined,
    };
    const userInfo = utilityFunctions.getCookie("userInfo");
    this.userRole = userInfo && JSON.parse(userInfo).user.role;
    this.user = userInfo && JSON.parse(userInfo).user;
  }

  componentDidMount() {
    this.retrieveDevice();
  }
  // componentDidUpdate(prevProps) {
  //   // if (this.props.selectedbrandID !== prevProps.selectedbrandID) {
  //   //   this.retrieveDevice(this.props.selectedbrandID);
  //   //   this.retriveDevices();
  //   // }
  // }
  retrieveDevice() {
    this.setState({
      isLoading: true,

      // deviceId: brandDevice[0]?.id,
    });
    this.props
      .protectedFetch(config.backendServer + "devices/?brandId=" + this.props.selectedbrandID, "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({
          devices: res,
          isLoading: false,
          // deviceId: brandDevice[0]?.id,
        });
      })
      .catch((err) => console.log(err));
  }

  filterChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  handleFilterStatus = (val) => {
    const statusText = referenceLibrary.statusSelectors.find((x) => x.id === parseInt(val))?.name || "";
    if (statusText === "") this.setState({ statusFilter: "All" });
    else this.setState({ statusFilter: "status", statusSelector: statusText });
  };
  statusFilterChange = (value) => {
    this.setState({ statusFilter: value });
  };

  formatValueWithCommas = (value) => {
    if (value === "") return value;

    // Split the value into integer and decimal parts (if applicable)
    const parts = value.toString().split(".");
    let formattedValue = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (parts.length > 1) {
      const decimalPart = parts[1].slice(0, 2);
      formattedValue += `.${decimalPart}`;
    }

    return formattedValue;
  };

  // Service Complete
  // Not Approve
  // Did not Proceed

  render() {
    // Filter out warranties
    let filteredDevice = [];

    //RMAS-37 search filter function
    if (this.state.searchFilter) {
      this.state.devices.forEach((item) => {
        if (
          item.id.toString().includes(this.state.searchFilter.toLowerCase()) ||
          item.name.toLowerCase().includes(this.state.searchFilter.toLowerCase())
        ) {
          filteredDevice.push(item);
        }
      });

      // filteredDevice.push(val));
    } else {
      this.state.devices.map((val) => filteredDevice.push(val));
    }

    const tableHead = [
      {
        id: "id",
        label: "Device ID",
        width: "2vw",
        valueDisplay: (value) => value,
      },
      {
        id: "name",
        label: "Dreame Model",
        width: "30vw",
        valueDisplay: (value) => value,
      },
      {
        id: "width",
        label: "Width",
        width: "3vw",
        valueDisplay: (value) => value,
      },
      {
        id: "length",
        label: "Length",
        width: "3vw",
        valueDisplay: (value) => value,
      },
      {
        id: "height",
        label: "Height",
        width: "3vw",
        valueDisplay: (value) => value,
      },
      {
        id: "weight",
        label: "Weight",
        width: "3vw",
        valueDisplay: (value) => value,
      },
      {
        id: "description",
        label: "Descriptions",
        width: "15vw",
        valueDisplay: (value) => value,
      },
      {
        id: "shipping_vendor",
        label: "Shipping Vendor",
        width: "10vw",
        valueDisplay: (value) => value,
      },
      {
        id: "action",
        label: "Action",
        valueDisplay: (value) => value || "",
      },
    ];

    return (
      <div>
        {this.state.isLoading && <LoadingPage />}
        <div className={"page"}>
          <div className="d-flex justify-content-between">
            <div style={{ width: "100px", borderBottom: "1px solid black", textAlign: "center" }}>
              <h5>Devices</h5>
            </div>
            <Link to={"/device-detail/new"} className="link-light text-decoration-none">
              <button title="Add device item" className="k-btn k-btn-primary k-btn-sm">
                Add New Device
              </button>
            </Link>
          </div>

          <div className="d-flex justify-content-end mt-2">
            <div className="mt-1">
              <div className="input-group mb-3 position-relative bottom-0 end-0">
                <input
                  type="text"
                  placeholder="Search device"
                  id={"searchFilter"}
                  className={"form-control"}
                  onChange={this.filterChange}
                  value={this.state.searchFilter}
                  // width={"70px"}
                />
                <div className="input-group-prepend">
                  <span className="form-control ">
                    <i className="fa fa-search form-control-icon"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>

          {this.state.devices.length > 0 ? (
            <div className={"pageContent clearfix"}>
              <div className={"admin-table-container card"}>
                <Table
                  head={tableHead}
                  data={filteredDevice}
                  reloadDevice={() => this.retrieveDevice(this.props.selectedbrandID)}
                  protectedFetch={this.props.protectedFetch}
                  selectedbrandID={this.props.selectedbrandID}
                  role={this.userRole}
                  statusSelectors={referenceLibrary.statusSelectors}
                  filteredStatus={this.handleFilterStatus}
                  editDevice={(row) =>
                    this.setState({
                      showAddDevice: true,
                      isEdit: true,
                      rowDevice: row,
                    })
                  }
                />
              </div>
            </div>
          ) : (
            <div>No device</div>
          )}
        </div>
        <Modal
          isOpen={this.state.showAddDevice}
          //onRequestClose={()=>this.setState({showDeclineModal: false})}
          contentLabel={"Add Device"}
          className={"input-modal"}
        >
          <DeviceAdd
            protectedFetch={this.props.protectedFetch}
            handleCloseModal={() => this.setState({ showAddDevice: false, isEdit: false })}
            reloadDevice={() => this.retrieveDevice(this.props.selectedbrandID)}
            selectedBrandId={this.props.selectedbrandID}
            isEdit={this.state.isEdit}
            deviceData={this.state.rowDevice}
          />
        </Modal>
      </div>
    );
  }
}
