import React, { Component } from "react";
import config from "../../config";
import utilityFunctions from "../../store/utilityFunctions";
import { MdClose } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

export default class Reports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      fromDate: null,
      toDate: null,
      showFilterModal: true,
    };
    const userInfo = utilityFunctions.getCookie("userInfo");
    this.userRole = JSON.parse(userInfo).user.role;
    this.user = JSON.parse(userInfo).user;
  }
  

  handleSubmit = (e) => {
    if (this.state.fromDate > this.state.toDate) {
      alert("From date can not be greater than to date");
      return;
    }
    this.setState({ loading: true });
    e.preventDefault();
    console.log(this.state.addComment);

    const fromDate = this.state.fromDate
      ? `from_date=${moment(this.state.fromDate).format("YYYY-MM-DD")}`
      : "";
    const toDate = this.state.toDate
      ? `${this.state.fromDate ? "&" : ""}to_date=${moment(
          this.state.toDate
        ).format("YYYY-MM-DD")}`
      : "";

    const url = `${config.backendServer}report/export_report?${fromDate}${toDate}`;

    this.props
      .protectedFetch(url, "GET", null, (res) => {
        return res; // Do everything in the 'then'
      })
      .then((res) => {
        if (res.ok) {
          res.blob().then((res) => {
            const blob = res;
            blob.name = "Export_excel_" + new Date().toISOString();
            utilityFunctions.downloadFileAutomatically(blob, res.type);
            this.props.handleCloseModal()
            
          });
        }
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false, showFilterModal: false });
        this.props.handleCloseModal()
      });
  };
  render() {
    return (
      <>

          <div className="card">
            <button
              onClick={() => this.props.handleCloseModal() }
              className={"confirm-modal-close"}
            >
              <MdClose />
            </button>
            <div className="card-header">
              <h6>Export Report to Excel</h6>
              </div>

            <div className={"card-body"}>
              <span>Date received</span>
              <div className="row p-2">
                <div className="col">
                  <span className="p-2">From</span>
                  <DatePicker
                    id="fromDate"
                    selected={this.state.fromDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(val) =>
                      this.setState({
                        fromDate: val,
                      })
                    }
                  />
                </div>
                <div className="col">
                  <span className="p-2">To</span>
                  <DatePicker
                    id="toDate"
                    selected={this.state.toDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(val) =>
                      this.setState({
                        toDate: val,
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <div className={"approveDeclineSet confirmApproveDeclineSet mb-4"}>
              <button
                type="submit"
                className={"approve confirmDecline"}
                onClick={this.handleSubmit}
              >
                Export
              </button>
              <button
                type="button"
                className={"neutral confirmNeutral"}
                onClick={() => this.props.handleCloseModal()}
              >
                Cancel
              </button>
            </div>
          </div>
      </>
    );
  }
}
