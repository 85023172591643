import React, { Component } from "react";
import Modal from "react-modal";
// import { Table as MaterialTable } from "@material-ui/core";
import config from "../../config";
import errorFunctions from "../../store/errorFunctions";
import utilityFunctions from "../../store/utilityFunctions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default class ServiceCenterAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sellers: [],
      filteredSellers: [],
      filteredStores: [],
      seller: null,
      newSeller: null,
      stores: [],
      store: null,
      showSeller: false,
      showStore: false,
      showDelete: false,
      deleteSeller: null,
      deleteStore: null,
      searchSeller: null,
    };
  }

  componentDidMount() {
    this.retrieveSeller();
  }

  retrieveSeller() {
    this.setState({
      isLoading: true,
      // deviceId: brandDevice[0]?.id,
    });
    this.props
      .protectedFetch(config.backendServer + "sellers/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({
          sellers: res,
          filteredSellers: res.sort((a, b) => {
            return b.name - a.name;
          }),
          isLoading: false,
          seller: res[0].name,
          // stores: res[0].store_locations,
          // deviceId: brandDevice[0]?.id,
        });
        this.retrieveStore(res[0].name);
      })
      .catch((err) => console.log(err));
  }

  updateSeller(e) {
    e.preventDefault();
    this.setState({
      isLoading: true,
      // deviceId: brandDevice[0]?.id,
    });
    const payload = {
      name: this.state.newSeller,
    };
    utilityFunctions
      .protectedFetch(config.backendServer + "sellers/" + this.state.seller + "/", "PATCH", payload, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({
          isLoading: false,
          seller: res.name,
          showSeller: false,
          stores: res.store_locations,
          // deviceId: brandDevice[0]?.id,
        });
        this.retrieveSeller();
        this.retrieveStore(res[0].name);
        // this.retrieveStore(res[0].name);
      })
      .catch((err) => console.log(err));
  }
  addSeller(e) {
    e.preventDefault();
    this.setState({
      isLoading: true,
      // deviceId: brandDevice[0]?.id,
    });
    const payload = {
      name: this.state.newSeller,
    };
    utilityFunctions
      .protectedFetch(config.backendServer + "sellers/", "POST", payload, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({
          isLoading: false,
          seller: res.name,
          showSeller: false,
          newSeller: null,
          // stores: res.store_locations,
          // deviceId: brandDevice[0]?.id,
        });
        this.retrieveSeller();
        // this.retrieveStore(res[0].name);
      })
      .catch((err) => {
        alert("Seller with this name already exists.");
        console.log(err);
      });
  }

  deleteSeller = () => {
    const userInfo = localStorage.getItem("userInfo") || utilityFunctions.getCookie("userInfo");

    //state jika di refresh akan kembali null, jadi ambil data dari localstorage atau cookies solusinya
    const token = userInfo?.access_token || JSON.parse(userInfo)?.access_token;
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    this.setState({ loading: true, showDeleteModal: false });
    const url = config.backendServer + "sellers/" + this.state.seller + "/";

    fetch(url, options)
      .then((resp) => {
        if (resp.status === 403) alert("Cannot delete seller. Device is already registered by at least one user(s)");
        else if (resp.status === 204) alert("Delete success");
        else alert("status:" + resp.status);

        this.setState({ showDelete: false, deleteStore: null });
        this.retrieveSeller();
      })
      .catch((err) => {
        alert(err);
        this.setState({ showDelete: false });
      });
  };
  updateStore(e) {
    e.preventDefault();
    this.setState({
      isLoading: true,
      // deviceId: brandDevice[0]?.id,
    });
    const payload = {
      name: this.state.newStore,
    };
    utilityFunctions
      .protectedFetch(
        config.backendServer + "sellers/" + this.state.seller + "/store_location/" + this.state.store + "/",
        "PATCH",
        payload,
        errorFunctions.checkResponseThenJson
      )
      .then((res) => {
        this.setState({
          isLoading: false,
          seller: res.name,
          showStore: false,
          // deviceId: brandDevice[0]?.id,
        });
        this.retrieveStore(this.state.seller);
      })
      .catch((err) => console.log(err));
    this.setState({
      showStore: false,
    });
  }
  addStore(e) {
    e.preventDefault();
    this.setState({
      isLoading: true,
      // deviceId: brandDevice[0]?.id,
    });
    const payload = {
      name: this.state.newStore,
    };
    utilityFunctions
      .protectedFetch(
        config.backendServer + "sellers/" + this.state.seller + "/store_location/",
        "POST",
        payload,
        errorFunctions.checkResponseThenJson
      )
      .then((res) => {
        this.setState({
          isLoading: false,
          store: res.name,
          newStore: null,
          showStore: false,

          // deviceId: brandDevice[0]?.id,
        });
        // this.retrieveSeller();
        this.retrieveStore(this.state.seller);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
    this.setState({
      showStore: false,
    });
  }

  retrieveStore(seller) {
    this.setState({
      isLoading: true,
      // deviceId: brandDevice[0]?.id,
    });

    utilityFunctions
      .protectedFetch(config.backendServer + "sellers/" + seller + "/store_locations/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        const sortRes = res.sort((a, b) => {
          return a.name > b.name;
        });
        this.setState({
          stores: sortRes,
          filteredStores: sortRes,
          isLoading: false,
          // deviceId: brandDevice[0]?.id,
        });
      })
      .catch((err) => console.log(err));
  }
  // deleteStore() {

  deleteStore = () => {
    const userInfo = localStorage.getItem("userInfo") || utilityFunctions.getCookie("userInfo");

    //state jika di refresh akan kembali null, jadi ambil data dari localstorage atau cookies solusinya
    const token = userInfo?.access_token || JSON.parse(userInfo)?.access_token;
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    this.setState({ loading: true, showDeleteModal: false });
    const url = config.backendServer + "sellers/" + this.state.seller + "/store_location/" + this.state.store + "/";

    fetch(url, options)
      .then((resp) => {
        if (resp.status === 403) alert("Cannot delete seller. Device is already registered by at least one user(s)");
        else if (resp.status === 204) alert("Delete success");
        else alert("status:" + resp.status);

        this.setState({ showDelete: false, deleteStore: null });
        this.retrieveStore(this.state.seller);
        // this.props.reloadDevice();
      })
      .catch((err) => {
        alert(err);
        this.setState({ showDelete: false });
      });
  };

  handleSellerClick(seller) {
    this.setState({ seller: seller, deleteItem: seller });
    this.retrieveStore(seller);
  }

  handleStoreClick(store) {
    this.setState({ store: store, deleteItem: store });
    // this.retrieveStore(seller);
  }

  handleEditSeller(seller) {
    this.setState({ seller: seller, showSeller: true });
  }
  handleDeleteSeller(seller) {
    this.setState({ seller: seller, showDelete: true, deleteSeller: seller });
  }
  handleDeleteStore(store) {
    this.setState({ store: store, showDelete: true, deleteStore: store });
  }

  handleInputChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });
  };

  handleSearchSeller = (e) => {
    this.handleInputChange(e);
    const filter = e.target.value.toLowerCase();
    if (e.target.value.length > 2) {
      const filteredSellers =
        this.state.sellers &&
        this.state.sellers
          .filter((s) => s.name.toLowerCase().includes(filter))
          .sort((a, b) => {
            return b.name - a.name;
          });
      this.setState({ filteredSellers });
    } else this.setState({ filteredSellers: this.state.sellers });
  };
  handleSearchStore = (e) => {
    this.handleInputChange(e);
    const filter = e.target.value.toLowerCase();
    if (e.target.value.length > 2) {
      const filteredStores = this.state.stores && this.state.stores.filter((s) => s.name.toLowerCase().includes(filter));
      this.setState({ filteredStores });
    } else this.setState({ filteredStores: this.state.stores });
  };

  handleAddSeller = (e) => {
    if (this.state.newSeller) {
      if (this.state.seller) this.updateSeller(e);
      else this.addSeller(e);
    } else alert("Seller can't be empty");
  };

  handleDeleteConfirmation = (e) => {
    if (this.state.deleteSeller) this.deleteSeller(e);
    else if (this.state.deleteStore) this.deleteStore(e);
  };
  render() {
    return (
      <div className={"page"}>
        <div style={{ width: "100px", borderBottom: "1px solid black", textAlign: "center" }}>
          <h5>Sellers</h5>
        </div>
        <div className="row">
          <div className="col">
            <div className="mt-1">
              <div className="input-group my-2">
                <input
                  type="text"
                  placeholder="Search sellers"
                  data-testid={"refund-amount"}
                  id="searchSeller"
                  value={this.state.searchSeller}
                  className="form-control"
                  onChange={(e) => this.handleSearchSeller(e)}
                />
                <div className="input-group-prepend">
                  <span className="form-control ">
                    <i className="fa fa-search form-control-icon"></i>
                  </span>
                </div>
              </div>

              <TableContainer>
                <Table aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table" component={Paper}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Seller</TableCell>
                      <TableCell className="text-end">
                        <button
                          className="k-btn k-btn-primary k-btn-sm"
                          onClick={() => this.setState({ seller: null, newSeller: null, showSeller: true })}
                        >
                          Add
                        </button>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.filteredSellers?.map((s, i) => {
                      return (
                        <TableRow key={i} className={this.state.seller === s.name && "font-weight-bold bg-light"}>
                          <TableCell
                            style={{
                              // width: "800px",
                              wordWrap: "break-word",
                              cursor: "pointer",
                            }}
                            onClick={() => this.handleSellerClick(s.name)}
                          >
                            {s.name}
                          </TableCell>
                          <TableCell
                            style={{
                              // width: "200px",
                              wordWrap: "break-word",
                            }}
                          >
                            <div>
                              <button
                                className={"btn btn-outline-primary btn-sm mr-1 "}
                                onClick={() => this.setState({ seller: s.name, newSeller: s.name, showSeller: true })}
                              >
                                Edit
                              </button>
                              <button className={"btn btn-outline-danger btn-sm "} onClick={() => this.handleDeleteSeller(s.name)}>
                                Delete
                              </button>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <div className="col">
            <div className="mt-1">
              <div className="input-group my-2">
                <input
                  type="text"
                  placeholder="Search stores"
                  data-testid={"refund-amount"}
                  id="searchStore"
                  value={this.state.searchStore}
                  className="form-control"
                  // style={{ height: "100%" }}
                  onChange={(e) => this.handleSearchStore(e)}
                />
                <div className="input-group-prepend">
                  <span className="form-control ">
                    <i className="fa fa-search form-control-icon"></i>
                  </span>
                </div>
              </div>
              <TableContainer sx={{ maxHeight: 600, overflowX: "hidden" }}>
                <Table sx={{ minWidth: 600 }} aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table" component={Paper}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: "450px" }}>Store Location</TableCell>
                      <TableCell className="text-end">
                        <button
                          className="k-btn k-btn-primary k-btn-sm"
                          onClick={() => this.setState({ store: null, newStore: null, showStore: true })}
                        >
                          Add
                        </button>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.filteredStores
                      ?.sort((a, b) => {
                        return b.name - a.name;
                      })
                      .map((s, i) => {
                        return (
                          <TableRow
                            key={i}
                            onClick={() => this.handleStoreClick(s.name)}
                            className={this.state.store === s.name && "font-weight-bold  bg-light"}
                          >
                            <TableCell
                              style={{
                                width: "400px",
                                cursor: "pointer",
                              }}
                            >
                              {s.name}
                            </TableCell>
                            <TableCell>
                              <div>
                                <button
                                  className={"btn btn-outline-primary btn-sm mr-1 "}
                                  onClick={() => this.setState({ store: s.name, newStore: s.name, showStore: true })}
                                >
                                  Edit
                                </button>
                                <button className={"btn btn-outline-danger btn-sm "} onClick={() => this.handleDeleteStore(s.name)}>
                                  Delete
                                </button>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.showSeller} contentLabel={"Set as Out of Warranty"} className={"confirm-modal "}>
          <div className="card">
            <div className="card-header">{this.state.seller ? "Edit Seller" : "Add Seller"}</div>
            <div className={"card-body"}>
              <div className="text-start m-3">
                <label className={"single-warranty-details-label "} htmlFor="deviceName">
                  Seller Name*
                </label>
                <input
                  type="text"
                  required
                  data-testid={"refund-amount"}
                  id="newSeller"
                  value={this.state.newSeller}
                  className="form-control"
                  style={{ height: "100%" }}
                  onChange={(e) => this.handleInputChange(e)}
                />
              </div>
              <button
                data-testid="confirm-status-ticket"
                type="button"
                className={"btn btn-outline-primary mr-3"}
                onClick={(e) => this.handleAddSeller(e)}
              >
                Submit
              </button>
              <button type={"button"} className={"btn btn-outline-secondary"} onClick={() => this.setState({ showSeller: false })}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <Modal isOpen={this.state.showStore} contentLabel={"Set as Out of Warranty"} className={"confirm-modal "}>
          <div className="card">
            <div className="card-header">{this.state.store ? "Edit Store Location" : "Add Store Location"}</div>
            <div className={"card-body"}>
              <div className="text-start m-3">
                <label className={"single-warranty-details-label "} htmlFor="deviceName">
                  Store Location Name*
                </label>
                <input
                  type="text"
                  required
                  data-testid={"refund-amount"}
                  id="newStore"
                  value={this.state.newStore}
                  className="form-control"
                  style={{ height: "100%" }}
                  onChange={(e) => this.handleInputChange(e)}
                />
              </div>
              <button
                data-testid="confirm-status-ticket"
                type="button"
                className={"btn btn-outline-primary mr-3"}
                onClick={(e) => (this.state.store ? this.updateStore(e) : this.addStore(e))}
              >
                Submit
              </button>
              <button type={"button"} className={"btn btn-outline-secondary"} onClick={() => this.setState({ showStore: false })}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <Modal isOpen={this.state.showDelete} contentLabel={"Set as Out of Warranty"} className={"confirm-modal "}>
          <div className="card">
            <div className="card-header">Delete Confirmation</div>
            <div className={"card-body"}>
              <div className="text-start m-3">{`Are you sure want to delete ${this.state.deleteSeller || this.state.deleteStore}?`}</div>
              <button
                data-testid="confirm-status-ticket"
                type="button"
                className={"btn btn-outline-primary mr-3"}
                onClick={() => this.handleDeleteConfirmation()}
              >
                Delete
              </button>
              <button type={"button"} className={"btn btn-outline-secondary"} onClick={() => this.setState({ showDelete: false })}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
