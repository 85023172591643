import React, { Component } from "react";
import Chart from "chart.js/auto";

export default class BarChart extends Component {
  chartRef = React.createRef();

  componentDidMount() {
    const ctx = this.chartRef.current.getContext("2d");

    new Chart(ctx, {
      type: "bar",
      data: {
        labels: this.props.data.labels,
        datasets: [
          {
            data: this.props.data.data,
            label: "Total",
            borderColor: "#3e95cd",
            backgroundColor: "#7bb6dd",
            fill: false,
          },
        ],
      },
      options: {
        onClick: (e, element) => {
          if (element.length > 0) {
            var label = this.props.data.labels[element[0].index];
            window.location.href = `tickets?status=${label}`;
          }
        },
        onHover: (event, chartElement) => {
          if (chartElement.length == 1) {
            event.native.target.style.cursor = "pointer";
          }
          if (chartElement.length == 0) {
            event.native.target.style.cursor = "default";
          }
        },
        barThickness: 30,
        scales: {
          x: {
            ticks: {
              stepSize: 1,
              display: true,
              callback: function (value) {
                return this.getLabelForValue(value).substring(0, 30) + (this.getLabelForValue(value).length > 30 ? "..." : "");
              },
            },
          },
          y: {
            ticks: {
              stepSize: 1,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }
  render() {
    return (
      <div>
        <canvas id="myChart" ref={this.chartRef} />
      </div>
    );
  }
}
