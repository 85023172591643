import React, { Component } from "react";
import { Link } from "react-router-dom";
import utilityFunctions from "../../../../store/utilityFunctions";

export default class CustomerInfo extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const details = this.props.details || {};
    const userInfo = utilityFunctions.getCookieAsJson("userInfo");
    const userRole = userInfo?.user?.role;

    return (
      <div className="card mb-3">
        <h6 className={"card-header"}>Customer Details</h6>
        <div className="card-body">
          <div className={"single-warranty-details-item"}>
            <span className={"single-warranty-details-label"}>Owner: </span>
            {/* Super admin links to user, normal user doesn't have link */}
            <span>
              {userRole === "Admin" ? (
                <Link to={"/customer-detail/" + details?.user_device?.customer_id}>{details?.user_device?.name}</Link>
              ) : (
                details?.user_device?.name
              )}
            </span>
          </div>
          <div className={"single-warranty-details-item"}>
            <span className={"single-warranty-details-label"}>Address Line 1: </span>
            <span>{details?.user_device?.address_line_one || "-"}</span>
          </div>
          <div className={"single-warranty-details-item"}>
            <span className={"single-warranty-details-label"}>Address Line 2: </span>
            <span>{details?.user_device?.address_line_two || "-"}</span>
          </div>
          <div className={"single-warranty-details-item"}>
            <span className={"single-warranty-details-label"}>Suburb: </span>
            <span>{details?.user_device?.suburb}</span>
          </div>
          <div className={"single-warranty-details-item"}>
            <span className={"single-warranty-details-label"}>State: </span>
            <span>{details?.user_device?.state}</span>
          </div>
          <div className={"single-warranty-details-item"}>
            <span className={"single-warranty-details-label"}>Post Code: </span>
            <span>{details?.user_device?.postcode}</span>
          </div>
          <div className={"single-warranty-details-item"}>
            <span className={"single-warranty-details-label"}>Mobile: </span>
            <span>{details?.user_device?.mobile}</span>
          </div>
          <div className={"single-warranty-details-item"}>
            <span className={"single-warranty-details-label"}>Email: </span>
            <span>{details?.user_device?.email}</span>
          </div>
        </div>
      </div>
    );
  }
}
