import { Table as MaterialTable } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import React, { Component } from "react";
import config from "../../../config";
import errorFunctions from "../../../store/errorFunctions";
import "./Table.css";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import { Link } from "react-router-dom";
import { createBrowserHistory } from "history";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{
                lineHeight: "1rem",
                boxSizing: "border-box",
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id === "verificationStatus" ? "status" : headCell.id)}
                style={{
                  fontSize: "12px",
                  color: "#777777",
                }}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export default class Table extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: "desc",
      orderBy: "date_received",
      page: 0,
      rowsPerPage: 15,
      employee: null,
      showModal: false,
      showModalResetPassword: false,
      ticketID: 0,
      employeeID: 0,
      emailForReset: "",
      statusTicketType: "",
      modalStatus: false,
      modalCaption: "",
    };
  }

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };
  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  handleDropDown = (e, id) => {
    let ticketID = id;
    let employeeID = e.target.value;
    this.setState({ showModal: true, employeeID, ticketID });
  };

  handleSubmitAssign = (event) => {
    event.preventDefault();
    let payload = {
      assigned_to: this.state.employeeID,
    };
    this.props
      .protectedFetch(config.backendServer + `ticket/${this.state.ticketID}/assign/`, "PATCH", payload, errorFunctions.checkResponseThenJson)
      .then(() => {
        this.props.reloadMini(this.props.selectedbrandID);
        this.setState({ showModal: false });
      })
      .catch(() => {
        console.log("fail");
        this.setState({ showModal: false });
      });
  };

  handleOpenModalSendPassword = (email, modalCaption) => {
    this.setState({
      showModalResetPassword: true,
      emailForReset: email,
      modalCaption,
    });
  };

  renderTicketType = (status, value) => {
    return value;
  };

  handleCancel = () => {
    this.props.reloadMini(this.props.selectedbrandID);
    this.setState({
      modalStatus: false,
    });
  };

  handleRowClick = (id) => {
    const history = createBrowserHistory({ forceRefresh: true });
    history.push(`/customer-detail/${id}`);
    // alert("row");
  };
  render() {
    const rows = this.props.data;
    const headCells = this.props.head;
    Modal.setAppElement("body");

    return (
      <div>
        <TableContainer>
          <MaterialTable aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
            <EnhancedTableHead
              order={this.state.order}
              orderBy={this.state.orderBy}
              onRequestSort={this.handleRequestSort}
              headCells={this.props.head}
              selectStatus={this.props.statusSelectors}
              filterStatus={this.props.filteredStatus}
            />
            <TableBody>
              {stableSort(rows, getComparator(this.state.order, this.state.orderBy))
                .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow tabIndex={-1} key={labelId}>
                      {/* Loop through head to get data order */}
                      {headCells.map((item, i) => {
                        if (item.id === "assigned_to") {
                          return (
                            <TableCell key={i}>
                              <select
                                name="brand"
                                id="brand"
                                disabled
                                value={parseInt(item.valueDisplay(row[item?.id]))}
                                onChange={(e) => this.handleDropDown(e, row.id)}
                                style={{
                                  maxWidth: "120px",
                                  width: "fitContent",
                                  wordWrap: "break-word",
                                }}
                              >
                                <option key={-1} value={""}>
                                  ---------
                                </option>
                                {this?.state?.employee?.map((val, i) => {
                                  return (
                                    <option key={i} value={parseInt(val?.id)}>
                                      {val?.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </TableCell>
                          );
                        } else if (item.id === "ticket_type") {
                          let value = item?.valueDisplay(row[item?.id]);
                          const capitalizedStr = value.charAt(0).toUpperCase() + value.slice(1);
                          return (
                            <TableCell
                              key={i}
                              style={{
                                maxWidth: "120px",
                                width: "fitContent",
                                wordWrap: "break-word",
                              }}
                            >
                              {capitalizedStr === "Out_of_warranty" ? "Out of Waranty (Service)" : "Warranty"}
                            </TableCell>
                          );
                        } else if (item.id === "ticket_number") {
                          return (
                            <TableCell
                              key={i}
                              style={{
                                maxWidth: "200px",
                                wordWrap: "break-word",
                              }}
                            >
                              <Link to={"/warranty/" + row.id}>{item?.valueDisplay(row[item?.id])}</Link>
                            </TableCell>
                          );
                        } else if (item.id === "user_device") {
                          return (
                            <TableCell
                              key={i}
                              style={{
                                maxWidth: "200px",
                                wordWrap: "break-word",
                                cursor: "pointer",
                              }}
                            >
                              {item?.valueDisplay(row[item?.id]?.device.name)}
                            </TableCell>
                          );
                        } else if (item.id === "last_name") {
                          return (
                            <TableCell
                              key={i}
                              style={{
                                maxWidth: "200px",
                                wordWrap: "break-word",
                                cursor: "pointer",
                              }}
                            >
                              <Link
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                                to={"/customer-detail/" + row.id}
                              >
                                {item?.valueDisplay(row[item?.id])}
                              </Link>
                            </TableCell>
                          );
                        } else if (item.id === "email") {
                          return (
                            <TableCell
                              key={i}
                              style={{
                                maxWidth: "200px",
                                wordWrap: "break-word",
                                cursor: "pointer",
                              }}
                            >
                              <Link
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                                to={"/customer-detail/" + row.id}
                              >
                                {item?.valueDisplay(row[item?.id])}
                              </Link>
                            </TableCell>
                          );
                        } else if (item.id === "verificationStatus") {
                          return (
                            <TableCell key={i}>
                              {!item?.valueDisplay(row["status"]) ? (
                                <button
                                  onClick={() => this.handleOpenModalSendPassword(row.email, "Are you sure you want to send verification to")}
                                  className={"button"}
                                >
                                  Send Verification
                                </button>
                              ) : (
                                <span className={item?.valueDisplay(row["status"]) === "Active" ? "active-status" : ""}>
                                  {item?.valueDisplay(row["status"])}
                                </span>
                              )}
                            </TableCell>
                          );
                        } else if (item.id === "action") {
                          return (
                            <TableCell key={i}>
                              <button
                                onClick={() => this.handleOpenModalSendPassword(row.email, "Are you sure you want to send a password reset to")}
                                className={"button"}
                              >
                                Reset Password
                              </button>
                            </TableCell>
                          );
                        } else if (item.id === "brands") {
                          let names = item?.valueDisplay(row[item?.id]).map((items) => items.name);
                          return (
                            <TableCell
                              key={i}
                              style={{
                                maxWidth: "200px",
                                wordWrap: "break-word",
                              }}
                            >
                              {names.join(", ")}
                            </TableCell>
                          );
                        } else if (item.id === "name") {
                          return (
                            <TableCell
                              key={i}
                              style={{
                                maxWidth: "200px",
                                wordWrap: "break-word",
                              }}
                            >
                              <Link to={"/staff-detail/" + row.id}>{item?.valueDisplay(row[item?.id])}</Link>
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell
                              key={i}
                              style={
                                item?.width
                                  ? {
                                      maxWidth: item?.width,
                                      wordWrap: "break-word",
                                    }
                                  : {
                                      maxWidth: "120px",
                                      wordWrap: "break-word",
                                    }
                              }
                            >
                              {item?.valueDisplay(row[item?.id])}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </MaterialTable>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />

        <Modal
          isOpen={this.state.showModal}
          //onRequestClose={()=>this.setState({showDeclineModal: false})}
          contentLabel={"Add Note"}
          className={"confirm-modal confirm-modal-big-approve warranty-decline-modal"}
        >
          <button onClick={() => this.setState({ showModal: false })} className={"confirm-modal-close"}>
            <MdClose />
          </button>
          <form onSubmit={this.handleSubmitAssign}>
            <div className={"approveDeclineSet confirmApproveDeclineSet"}>
              <div className={"form-field"}>
                <div className="mb-3">Confirm Assigned?</div>
                <button type="submit" className={"approve confirmDecline"}>
                  Assign
                </button>
                <button type={"button"} className={"neutral confirmNeutral"} onClick={() => this.setState({ showModal: false })}>
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </Modal>

        <Modal
          isOpen={this.state.showModalResetPassword}
          contentLabel={"Reset Password"}
          className={"confirm-modal confirm-modal-big-approve warranty-decline-modal"}
        >
          <button onClick={() => this.setState({ showModalResetPassword: false })} className={"confirm-modal-close"}>
            <MdClose />
          </button>
          <form>
            <div className={"approveDeclineSet confirmApproveDeclineSet"}>
              <div className={"form-field"}>
                <div className="mb-3">{`${this.state.modalCaption} ${this.state.emailForReset}?`}</div>
                <button
                  type="button"
                  className={"approve confirmDecline"}
                  onClick={(event) => {
                    if (this.state.modalCaption.toLowerCase().includes("reset")) this.handleResetPassword();
                    else this.handleSendVerification(event);
                  }}
                >
                  Send
                </button>
                <button type={"button"} className={"neutral confirmNeutral"} onClick={() => this.setState({ showModalResetPassword: false })}>
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </Modal>

        <Modal
          isOpen={this.state.showModalResetPasswordSuccess}
          contentLabel={"Reset Password"}
          className={"confirm-modal confirm-modal-big-approve warranty-decline-modal"}
        >
          <form>
            <div className={"approveDeclineSet confirmApproveDeclineSet"}>
              <div className={"form-field"}>
                <div className="mb-3">The reset password email is sent to {this.state.emailForReset}</div>
                <button
                  type={"button"}
                  className={"neutral confirmNeutral"}
                  onClick={() =>
                    this.setState({
                      showModalResetPasswordSuccess: false,
                      showModalResetPassword: false,
                    })
                  }
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </Modal>

        {/* Modal for confirm status ticket type */}
        <Modal isOpen={this.state.modalStatus} contentLabel={"Set as Out of Warranty"} className={"confirm-modal"}>
          <form onSubmit={this.handleChangeStatusTicketType}>
            <div className={"approveDeclineSet confirmApproveDeclineSet"}>
              <div className={"form-field"}>
                <div className="mb-3">Change Status to {this.state.statusTicketType}?</div>

                <button type="submit" className={"approve confirmDecline"}>
                  Confirm
                </button>

                <button type={"button"} className={"neutral confirmNeutral"} onClick={this.handleCancel}>
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}
