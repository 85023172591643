import React, { Component } from "react";
import PropTypes from "prop-types";
import Tab from "./Tab";
import "./Tabs.css";

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.defaultTab || this.props.children[0].props.label,
    };
  }

  onClickTabItem = (tab) => {
    this.props.resetTab();
    this.setState({ activeTab: tab });
  };

  render() {
    const {
      onClickTabItem,
      props: { children },
      state: { activeTab },
    } = this;

    return (
      <div className="tabs card">
        <ol className="tab-list card-header">
          {children.map((child) => {
            const { label } = child.props;

            return <Tab activeTab={activeTab} key={label} label={label} onClick={onClickTabItem} />;
          })}
        </ol>
        <div className="tab-content card-body">
          {children.map((child) => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

export default Tabs;
