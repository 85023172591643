import React, { Component } from "react";
import "../common.css";
import Tabs from "../../components/Tabs/Tabs";
import CustomTab from "./CustomTab/CustomTab";
import DailyTab from "./DailyTab/DailyTab";
import "react-datepicker/dist/react-datepicker.css";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultTab: "Daily Reports",
    };
  }

  render() {
    return (
      <div className="page">
        <Tabs defaultTab={this.state.defaultTab} resetTab={() => this.setState({ defaultTab: undefined })}>
          <div label="Daily Reports">
            <DailyTab setDefaultTab={() => this.setState({ defaultTab: "Daily Reports" })} protectedFetch={this.props.protectedFetch} />
          </div>
          <div label="Custom Reports">
            <CustomTab setDefaultTab={() => this.setState({ defaultTab: "Custom Reports" })} protectedFetch={this.props.protectedFetch} />
          </div>
        </Tabs>
      </div>
    );
  }
}
