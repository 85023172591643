import { Table as MaterialTable } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import React, { Component } from "react";
import config from "../../config";
// import errorFunctions from "../../store/errorFunctions";
import "./Table.css";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
// import { Link } from "react-router-dom";
import { createBrowserHistory } from "history";
import ServiceCenterAdd from "../../pages/ServiceCenter/ServiceCenterAdd";
import utilityFunctions from "../../store/utilityFunctions";

import "./Table.css";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{
                lineHeight: "1rem",
                boxSizing: "border-box",
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                style={{
                  fontSize: "12px",
                  color: "#777777",
                }}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export default class Table extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: "desc",
      orderBy: "id",
      page: 0,
      rowsPerPage: 15,

      showModal: false,
      showModalResetPassword: false,
      ticketID: 0,
      serviceCenter: null,
      deletedName: "",
      statusTicketType: "",
      modalStatus: false,
      modalCaption: "",
      serviceCenterId: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({ page: 0 });
    }
  }

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };
  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  handleDropDown = (e, id) => {
    let ticketID = id;

    this.setState({ showModal: true, ticketID });
  };

  handleOpenModalDelete = (name, modalCaption) => {
    this.setState({
      showModalDelete: true,
      deletedName: name,
      modalCaption,
      openServiceCenterAdd: false,
    });
  };

  viewServiceCenter(id) {
    const { history } = this.props;
    history.push("/service-center/" + id);
  }

  handleDelete = () => {
    let url = `${config.backendServer}service_center/${this.state.serviceCenterId}/`;
    const userInfo =
      localStorage.getItem("userInfo") ||
      utilityFunctions.getCookie("userInfo");
    const token = userInfo?.access_token || JSON.parse(userInfo)?.access_token;
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    fetch(url, options).then((response) => {
      console.log(response.status);
      if (response.status === 204) {
        this.setState({ showModalDelete: false });
        this.props.handleRetriveServiceCenterList(this.state.serviceCenterId);
      } else if (response.status === 406) {
        alert(
          `Delete is not allowed, Service Center is already linked to ticket`
        );
      } else if (response.status === 409) {
        alert(`Service does not exist.`);
      } else {
        alert("Problem with system. Please refresh and try again later.");
      }
      this.setState({ showModalDelete: false });
    });
  };

  renderTicketType = (status, value) => {
    return value;
  };

  handleCancel = () => {
    this.props.reloadMini(this.props.selectedbrandID);
    this.setState({
      modalStatus: false,
    });
  };

  handleRowClick = (id) => {
    const history = createBrowserHistory({ forceRefresh: true });
    history.push(`/service-center/${id}`);
    // alert("row");
  };
  render() {
    const rows = this.props.data;
    const headCells = this.props.head;
    Modal.setAppElement("body");

    return (
      <div>
        <TableContainer>
          <MaterialTable
            aria-labelledby="tableTitle"
            size={"small"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              order={this.state.order}
              orderBy={this.state.orderBy}
              onRequestSort={this.handleRequestSort}
              headCells={this.props.head}
              selectStatus={this.props.statusSelectors}
              filterStatus={this.props.filteredStatus}
            />
            <TableBody>
              {stableSort(
                rows,
                getComparator(this.state.order, this.state.orderBy)
              )
                .slice(
                  this.state.page * this.state.rowsPerPage,
                  this.state.page * this.state.rowsPerPage +
                    this.state.rowsPerPage
                )
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      tabIndex={-1}
                      key={labelId}
                      className={"customer"}
                      style={{ cursor: "pointer" }}
                    >
                      {/* Loop through head to get data order */}
                      {headCells.map((item, i) => {
                        if (item.id === "action") {
                          return (
                            <TableCell
                              key={i}
                              style={{
                                width: 150,
                                wordWrap: "break-word",
                              }}
                            >
                              <button
                                disabled={
                                  this.props.userInfo.user.role !== "Admin"
                                }
                                onClick={() =>
                                  this.setState({
                                    openServiceCenterAdd: true,
                                    serviceCenter: row,
                                  })
                                }
                                className={
                                  "btn btn-outline-primary btn-sm mr-1"
                                }
                              >
                                Edit
                              </button>
                              <button
                                disabled={
                                  this.props.userInfo.user.role !== "Admin"
                                }
                                onClick={() => {
                                  this.setState({ serviceCenterId: row.id });
                                  this.handleOpenModalDelete(
                                    row.name,
                                    "Are you sure you want delete"
                                  );
                                }}
                                className={"btn btn-outline-danger btn-sm"}
                              >
                                Delete
                              </button>
                            </TableCell>
                          );
                        } else if (item.id === "tickets") {
                          return (
                            <TableCell
                              key={i}
                              style={{
                                width: 50,
                                wordWrap: "break-word",
                              }}
                            >
                              {row.tickets}
                            </TableCell>
                          );
                        } else if (item.id === "staff") {
                          return (
                            <TableCell
                              key={i}
                              style={{
                                width: 50,
                                wordWrap: "break-word",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                this.setState({
                                  showStaff: true,
                                  serviceCenter: row,
                                })
                              }
                            >
                              <u className="text-primary">{row.staff.length}</u>
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell
                              key={i}
                              onClick={() => this.handleRowClick(row.id)}
                              style={
                                item?.width
                                  ? {
                                      width: item?.width,
                                      wordWrap: "break-word",
                                    }
                                  : {
                                      maxWidth: "120px",
                                      wordWrap: "break-word",
                                    }
                              }
                            >
                              {item?.valueDisplay(row[item?.id])}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </MaterialTable>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />

        <Modal
          isOpen={this.state.showModalDelete}
          contentLabel={"Reset Password"}
          className={"input-modal-small"}
        >
          <div className="card">
            <button
              onClick={() => this.setState({ showModalDelete: false })}
              className={"confirm-modal-close"}
            >
              <MdClose />
            </button>
            <div className="card-header">
              <h6>Delete Confirmation</h6>
            </div>
            <form>
              <div className="card-body text-center">
                <div className="mb-3">{this.state.modalCaption}</div>
                <div> {`${this.state.deletedName}?`}</div>
              </div>
              <div className="card-footer">
                <div className="row ">
                  <div className="col-6 text-right ">
                    <button
                      type="button"
                      className={"btn btn-outline-danger"}
                      onClick={() => {
                        this.handleDelete();
                        // if (this.state.modalCaption.toLowerCase().includes("reset")) this.handleResetPassword();
                        // else this.handleSendVerification(event);
                        // this.setState({ showModalResetPassword: false });
                      }}
                    >
                      Delete
                    </button>
                  </div>
                  <div className="col-6 text-left">
                    <button
                      type={"button"}
                      className={"btn btn-outline-secondary"}
                      onClick={() => this.setState({ showModalDelete: false })}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.showModalResetPasswordSuccess}
          contentLabel={"Reset Password"}
          className={"input-modal"}
        >
          <div className="card">
            <div className="card-header">Reset Password</div>
            <form className="card-body text-center">
              <div className="mb-3">The reset password email is sent to</div>
              <div>{this.state.deletedName}</div>
              <div className="mt-5">
                <button
                  type={"button"}
                  className={"button"}
                  onClick={() =>
                    this.setState({
                      showModalResetPasswordSuccess: false,
                      showModalResetPassword: false,
                    })
                  }
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </Modal>

        {/* Modal for confirm status ticket type */}
        <Modal
          isOpen={this.state.modalStatus}
          contentLabel={"Set as Out of Warranty"}
          className={"confirm-modal"}
        >
          <form onSubmit={this.handleChangeStatusTicketType}>
            <div className={"approveDeclineSet confirmApproveDeclineSet"}>
              <div className={"form-field"}>
                <div className="mb-3">
                  Change Status to {this.state.statusTicketType}?
                </div>

                <button type="submit" className={"approve confirmDecline"}>
                  Confirm
                </button>

                <button
                  type={"button"}
                  className={"button"}
                  onClick={this.handleCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </Modal>
        <Modal
          isOpen={this.state.openServiceCenterAdd}
          // onRequestClose={() => this.setState({ openServiceCenterAdd: false })}
          contentLabel={"Add New Device."}
          className={"input-modal"}
          // style={customStyles}
        >
          <button
            onClick={() => this.setState({ openServiceCenterAdd: false })}
            className={"info-modal-close"}
          >
            <MdClose />
          </button>

          <ServiceCenterAdd
            protectedFetch={this.props.protectedFetch}
            serviceCenter={this.state.serviceCenter}
            handleCloseModal={() =>
              this.setState({ openServiceCenterAdd: false })
            }
            handleRetriveServiceCenterList={() =>
              this.props.handleRetriveServiceCenterList(
                this.props.selectedbrandID
              )
            }
            selectedBrandId={this.props.selectedbrandID}
          />
        </Modal>
        <Modal isOpen={this.state.showStaff} className={"input-modal"}>
          <div className="card">
            <div className="card-header">{`${this.state.serviceCenter?.name} staff list`}</div>
            <div
              className="card-body overflow-auto "
              style={{ height: "400px" }}
            >
              <ul className="list-group">
                {this.state.serviceCenter?.staff.map((staff, i) => {
                  return (
                    <li className="list-group-item" key={i}>{`${i + 1}.  ${
                      staff.name
                    }`}</li>
                  );
                })}
              </ul>
            </div>
            <div className="card-footer">
              <button
                type={"button"}
                className={"btn btn-outline-secondary"}
                onClick={() => this.setState({ showStaff: false })}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
