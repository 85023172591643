import React, { Component } from "react";
import Stepper from "../../../../components/Stepper/Stepper";
import DatePicker from "react-datepicker";
import axios from "axios";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import moment from "moment";
import config from "../../../../config";
import errorFunctions from "../../../../store/errorFunctions";
import { icons } from "../../../../img/icons";
// import utilityFunctions from "../../../../store/utilityFunctions";
// import ShippingLabelAP from "../ShippingLabel/ShippingLabelAP";
// import ShippingLabelTD from "../ShippingLabel/ShippingLabelTD";
import ReceivedGoods from "../ReceivedGoods/ReceivedGoods";
import Select from "react-select";

Modal.setAppElement("body");

export default class TicketProcess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      due_date: new Date(moment().add(2, "d").format("YYYY-MM-DD")),
      showDueDateModal: false,
      statusTicket: this.props.details?.status,
      typeService: this.props.details?.type_of_service,
      statusTicketType: this.props.details?.ticket_type,
      modalStatusConfirm: false,
      replacementModal: false,
      selectedReplacement: this.props.deviceOptions?.find((x) => x.value === this.props.details.replacement),
      employeeID: this.props.details?.assigned_to,
      employeeName: "",
      refundAmount: this.formatDecimalValue(this.props.details?.refund_amount) || 0,
      showGoodReceived: false,
      ticketID: this.props.details?.id,

      serviceCenterCentrals: this.props.serviceCenterCentrals,
      selectedServiceCenters: [],
      optionServiceCenterCentrals: this.props.optionServiceCenterCentrals,
      optionServiceCenter: [],
      deviceOptions: [],
      serviceCenters: undefined,
      serviceCenterCentralId: this.props.details?.service_center_central || 0,
      serviceCenterLocationId: this.props.details?.service_center || 0,
      modalServiceCenter: false,
      optionEmployees: this.props.optionEmployees,
      newAssignTo: false,
      newServiceCenter: false,
      trackingNumber: this.props.details?.tracking_number,
      nextStatus: "",
    };
  }

  handleStatusTicket = (e) => {
    this.setState({ statusTicket: e.value, modalStatusConfirm: true });
  };

  handleConfirmTicket = (e) => {
    this.setState({ loading: true });
    e.preventDefault();
    let payload = {
      status: this.state.statusTicket,
    };
    this.props
      .protectedFetch(config.backendServer + `ticket/${this.props.details.id}/status/`, "PATCH", payload, errorFunctions.checkResponseThenJson)
      .then(() => {
        this.setState({ modalStatusConfirm: false, loading: false });
        this.props.retrieveWarranty(); //mini reload
      })
      .catch((err) => {
        console.log("fail", err);
        this.setState({ modalStatusConfirm: false, loading: false });
      });
  };

  formatDecimalValue = (val) => {
    const value = val?.toString().replace(/,/g, "") || 0;
    return parseFloat(value).toLocaleString("en-AU", {
      style: "decimal",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  };

  handleSubmitRefund = (e) => {
    e.preventDefault();

    let amountToSubmit = Number(this.state.refundAmount).toFixed(2);
    if (amountToSubmit === "NaN") {
      alert("Please input valid number");
    } else {
      let payload = {
        refund_amount: amountToSubmit,
      };
      this.props
        .protectedFetch(config.backendServer + `ticket/${this.props.details.id}/refund/`, "PUT", payload, errorFunctions.checkResponseThenJson)
        .then((res) => {
          if (res.refund_amount) {
            alert("Refund has successfully changed");
            this.props.retrieveWarranty(this.props.details.id); //mini reload
          }
        })
        .catch((err) => {
          console.log("fail refund", err);
        });
    }
  };

  handleSubmitTrackingNumber = (e) => {
    e.preventDefault();
    if (!this.state.trackingNumber) {
      alert("Tracking number is empty, please fill it");
      return;
    }
    let payload = {
      tracking_number: this.state.trackingNumber,
    };
    this.props
      .protectedFetch(
        config.backendServer + `ticket/${this.props.details.id}/tracking-number/`,
        "PATCH",
        payload,
        errorFunctions.checkResponseThenJson
      )
      .then((res) => {
        if (res.tracking_number) {
          alert("Tracking number successfully saved");
          this.props.retrieveWarranty(this.props.details.id); //mini reload
        }
      })
      .catch((err) => {
        console.log("fail ticket number saved", err);
      });
  };
  handleTicketTypeChanges = (e) => {
    e.preventDefault();
    this.setState({ statusTicketType: e.target.value, typeService: "" });
  };
  handleChangeStatusTicketType = (e) => {
    e.preventDefault();

    let payload = {
      ticket_type: this.state.statusTicketType === "out_of_warranty" ? "out_of_warranty" : "warranty",
      type_of_service: this.state.typeService,
    };

    this.props
      .protectedFetch(config.backendServer + `ticket/${this.props.details.id}/change-type/`, "PATCH", payload, errorFunctions.checkResponseThenJson)
      .then(() => {
        this.props.retrieveWarranty(this.props.details.id); //mini reload
        this.setState({
          modalServiceType: false,
          typeService: "",
          statusTicketType: "",
        });
      })
      .catch((err) => {
        console.log("fail", err);
        this.props.retrieveWarranty(this.props.details.id); //mini reload
        this.setState({
          modalServiceType: false,
          typeService: "",
          statusTicketType: "",
        });
        alert("Error try later");
      });
  };
  handleStatusTicketTypeModal = (e) => {
    e.preventDefault();
    if (e.target.value !== "") {
      this.setState({ modalServiceType: true, typeService: e.target.value });
    } else {
      alert("Must not empty");
      this.setState({ modalServiceType: false, typeService: "" });
    }
  };
  changeDueDate(note) {
    this.setState({ disableBtnDueDate: true });
    if (!this.state.showDueDateModal && !note) return;
    if (!this.state.showDueDateChangeModal && note) return;

    const data = {
      reason: "",
      due_date: moment(this.state.due_date).format("YYYY-MM-DD"),
    };

    const url = `${config.backendServer}ticket/${this.props.details.id}/set-due-date/`;
    this.props
      .protectedFetch(url, "PUT", data, errorFunctions.checkResponseThenJson)
      .then(() => {
        if (this.state.dueDateType === "note") this.handleAddNote();
        else if (this.state.dueDateType === "media") this.handleAddMedia();
        this.setState({
          dueDateReason: "",
          showDueDateModal: false,
          showDueDateChangeModal: false,
          disableBtnDueDate: false,
        });
        this.props.retrieveWarranty(this.ticketId);
      })
      .catch((error) => {
        // Handle the error or show an error message
        alert(error?.response?.data?.non_field_errors?.[0] || error?.response?.data?.serial_number?.[0] || "Something went wrong.. try later");
        this.setState({ isLoading: false, showDueDateModal: false });
      });
  }

  handleDropDownAssignTo = (e) => {
    if (e.value !== this.state.employeeID) this.setState({ newAssignTo: true });
    this.setState({ employeeID: e.value, employeeName: e.label });
  };

  handleDropDownReplacement = (e) => {
    const selectedReplacement = this.state.deviceOptions.find((x) => x.value === e.value);
    this.setState({ replacement: e.value, selectedReplacement });
  };
  handleDropDownServiceCenterCentral = (e) => {
    // const tempSelectedServiceCenter = this.props.serviceCenters.find((x) => x.id === parseInt(e.target.value));
    if (e.value !== this.state.serviceCenterCentralId) {
      const selectedServiceCenter = this.props.serviceCenterCentrals?.find((x) => x.id == e.value).service_centers;

      const selectedServiceCenterOption = selectedServiceCenter?.map((s) => {
        return { value: s.id, label: s.name };
      });
      this.setState({
        optionServiceCenter: selectedServiceCenterOption,
        selectedServiceCenters: selectedServiceCenter,
        serviceCenterCentralId: e.value,
        newServiceCenter: true,
      });
      if (!this.props.details?.service_center_central) this.setState({ modalServiceCenter: true });
      else {
        this.handleSubmitServiceCenterCentral(e.value);
      }
    }
  };

  handleDropDownServiceCenterLocation = (e) => {
    if (e.value !== this.state.serviceCenterLocationId) {
      this.setState({ serviceCenterLocationId: e.value, newServiceCenter: true });
      this.handleSubmitServiceCenterLocation(e.value);
    }
  };
  handleSubmitAssign = () => {
    // e.preventDefault();

    let payload = {
      assigned_to: this.state.employeeID,
    };
    this.props
      .protectedFetch(config.backendServer + `ticket/${this.props.details.id}/assign/`, "PATCH", payload, errorFunctions.checkResponseThenJson)
      .then(() => {
        this.setState({ showAssignModal: false });
        this.props.retrieveWarranty();
        //mini reload
      })
      .catch(() => {
        console.log("fail");
      });
  };

  handleCancel = () => {
    this.props.retrieveWarranty(this.ticketId); //mini reload
    this.setState({
      showAssignModal: false,
      modalStatusConfirm: false,
      modalServiceCenter: false,
      modalNext: false,
      modalServiceType: false,
      statusTicketType: "",
      typeService: "",
    });
  };
  handleApprove = (e) => {
    this.setState({ loading: true, showApproveModal: false });
    e.preventDefault();
    this.approveTicket(this.props.details.id);
  };
  approveTicket(id) {
    this.props
      .protectedFetch(config.backendServer + "ticket/" + id + "/approved/", "PUT", null, errorFunctions.checkResponseSent)
      .then(() => {
        this.props.retrieveWarranty(id);
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
  }
  replacement() {
    // e.preventDefault();
    let payload = {
      replacement: this.state.replacement,
    };
    this.props
      .protectedFetch(config.backendServer + "ticket/" + this.props.details.id + "/replacement/", "PATCH", payload, errorFunctions.checkResponseSent)
      .then(() => {
        this.props.retrieveWarranty(this.props.details.id);
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
  }
  handleDecline = (e) => {
    this.setState({ loading: true, showDeclineModal: false });
    e.preventDefault();
    this.declineTicket(this.state.declineReason, this.props.details.id);
  };
  declineTicket(data, id) {
    let reason = { note: data };
    this.props
      .protectedFetch(config.backendServer + "ticket/" + id + "/rejected/", "PUT", reason, errorFunctions.checkResponseSent)
      .then(() => {
        this.retrieveWarranty(id);
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
    this.addNote({ note: `Reason declined: ${data}` }, id);
  }
  addNote(formData, ticketId) {
    // const userInfo = localStorage.getItem("lgn") || utilityFunctions.getCookie("userInfo");
    const token = this.props.userInfo.access_token;
    let options = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "multipart/form-data",
      },
    };

    const url = `${config.backendServer}ticket/${ticketId}/note/`;
    axios
      .put(url, formData, options)
      .then(() => {
        this.setState({
          addedNote: "",
          editedNote: null,
          showAddNote: false,
          showAddMedia: false,
          disableBtnNote: false,
        });
        this.props.retrieveWarranty(ticketId);
      })
      .catch((error) => {
        // Handle the error or show an error message
        alert(error?.response?.data?.non_field_errors?.[0] || error?.response?.data?.serial_number?.[0] || "Something went wrong.. try later");
        this.setState({ isLoading: false });
      });
  }

  handleNextModalOpen = () => {
    this.setState({ modalNext: true });
  };
  handleServiceNext = (e) => {
    const { details } = this.props;
    const listProgress = details?.ticket_status_list;
    const currentProgress = details?.status;

    let currentIndex = listProgress.indexOf(currentProgress);
    let isLastIndex = currentIndex === listProgress.length - 1;
    let getNextIndexValue = this.state.nextStatus || listProgress[currentIndex + 1];

    if (isLastIndex || currentProgress?.includes("Completed")) {
      alert("Service done");
    } else {
      this.setState({ loading: true });
      e?.preventDefault();
      let payload = {
        status: getNextIndexValue,
      };
      this.props
        .protectedFetch(config.backendServer + `ticket/${this.props.details.id}/status/`, "PATCH", payload, errorFunctions.checkResponseThenJson)
        .then(() => {
          this.setState({ modalNext: false, loading: false });
          this.props.retrieveWarranty(this.ticketId); //mini reload
        })
        .catch((err) => {
          console.log("fail", err);
          this.setState({ modalNext: false, loading: false });
        });
    }
  };
  handleInputChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });
  };

  handleSubmitServiceCenterLocation = (id) => {
    // e.preventDefault();
    const payload = { service_center: id || this.state.serviceCenterLocationId };

    this.props
      .protectedFetch(
        config.backendServer + `ticket/${this.props.details.id}/service_center/`,
        "PATCH",
        payload,
        errorFunctions.checkResponseThenJson
      )
      .then(() => {
        this.setState({ modalServiceCenter: false, serviceCenterId: payload.service_center });
        this.props.retrieveWarranty(this.ticketId); //mini reload
      })
      .catch(() => {
        console.log("fail");
      });
  };

  handleSubmitServiceCenterCentral = (id) => {
    // e.preventDefault();
    const payload = { service_center_central: id || this.state.serviceCenterCentralId };
    this.props
      .protectedFetch(
        config.backendServer + `ticket/${this.props.details.id}/service_center_central/`,
        "PATCH",
        payload,
        errorFunctions.checkResponseThenJson
      )
      .then(() => {
        this.setState({ modalServiceCenter: false, serviceCenterCentralId: payload.service_center_central });
        this.props.retrieveWarranty(this.ticketId); //mini reload
      })
      .catch(() => {
        console.log("fail");
      });
  };
  handleFileUpload = (e) => {
    // TODO - verify file upload to make sure it's good
    const file = e.target.files[0];
    const allowedTypes = ["application/pdf"];

    const imageFile = URL.createObjectURL(file);
    const uploadFile = document.getElementById("uploadFile").files[0];
    this.setState({ image: imageFile, imageName: file.name, uploadFile: uploadFile });

    if (!allowedTypes.includes(file?.type)) {
      alert("Only PDF");
      this.setState({ allowUpload: false });
    } else {
      this.setState({ allowUpload: true });
    }
    // const video = document.getElementById("uploadFile").files[0];
    // const videos = [...this.state.newVideoFiles, video];
    // const videoFiles = [...this.state.videoFiles, video];
    // this.setState({ newVideoFiles: videos, videoFiles: videoFiles, newVideoName: video.name });
  };

  uploadLabel() {
    let formData = new FormData();
    formData.append("return_label", this.state.uploadFile);
    // const userInfo = localStorage.getItem("lgn") || utilityFunctions.getCookie("userInfo");
    const token = this.props?.userInfo?.access_token;
    let options = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "multipart/form-data",
      },
    };

    const url = `${config.backendServer}ticket/${this.props.details.id}/return-label/`;

    axios
      .patch(url, formData, options)
      .then(() => {
        this.setState({
          showShipping: false,
        });
        this.handleServiceNext();
        this.props.retrieveWarranty();
      })
      .catch(() => {
        // Handle the error or show an error message
        // if(error) alert(error?.response?.data?.non_field_errors?.[0] || error?.response?.data?.serial_number?.[0] || "Something went wrong.. try later");
        this.setState({ showEditNote: false });
      });
    this.props.retrieveWarranty();
  }

  render() {
    const completed = ["Completed", "replacement sent", "service completed"].map((element) => element.toLowerCase());
    const disableNext =
      this.props.details?.ticket_status_list?.indexOf(this.props.details?.status) === this.props.details?.ticket_status_list?.length - 1 ||
      completed.includes(this.props.details?.status.toLowerCase());

    const due_date = (this.props.details?.due_date && new Date(this.props.details?.due_date)) || this.state.due_date;
    const selectedServiceCenterCentralValue = this.props.optionServiceCenterCentrals?.find((x) => x.value === this.state.serviceCenterCentralId);
    const selectedServiceCenterLocationValue = this.props.optionServiceCenterLocations?.find((x) => x.value === this.state.serviceCenterLocationId);

    const tempdAssignTo = this.props?.employees?.find((s) => s && s.id === this.state.employeeID);

    const selectedAssignTo = tempdAssignTo && {
      value: tempdAssignTo.id,
      label: `${tempdAssignTo.name} - ${tempdAssignTo.user_role}`,
    };
    // const selectedServiceCenters = this.props.serviceCenterCentrals?.find((x) => x.id == this.state.serviceCenterCentralId)?.service_centers;
    // const selectedStaff = selectedServiceCenters?.find((x) => x.id === this.state.serviceCenterLocationId)?.staff;

    let assignTo = [];
    if (this.props?.employees) {
      assignTo = this.props?.employees
        ?.filter((x) => x.user_role === "Staff")
        .map((s) => {
          return { value: s.id, label: s.name + " - " + s.user_role };
        });

      if (this.props.details?.service_center) {
        const assignToTemp = this.props?.employees
          ?.filter((x) => x.service_center.map((x) => x.id).includes(this.props.details?.service_center))
          .map((s) => {
            return { value: s.id, label: s.name + " - " + s.user_role };
          });
        let assignToT2 = [];
        [...assignTo, ...assignToTemp].forEach((a) => {
          if (!assignToT2.map((x) => x.value).includes(a.value)) assignToT2.push(a);
        });
        assignTo = assignToT2;
      }
    }
    const isDisableSC = !(this.props.userInfo?.user.role === "Admin" || !this.props.details?.service_center_central);

    const isDisableSCLoc = !(this.props.userInfo?.user.role === "Admin" || this.props.userInfo?.user.role === "Service Center Admin");
    console.log("isDisableSC:" + isDisableSC);

    const statusListOption = this.props.details?.ticket_status_list?.map((status) => {
      return { value: status, label: status };
    });
    const repairStatuses = ["Pending Repair", "Awaiting Parts", "Goods received and under repair"];
    const pendingRepairStatuses = ["Awaiting Parts", "Goods received and under repair"];
    const awaitingPartStatuses = ["Pending Repair", "Goods received and under repair"];
    const underRepairStatuses = ["Pending Repair", "Awaiting Parts", "Repair complete, goods sent out"];
    const selectedReplacement = this.props.deviceOptions?.find((x) => x.value === this.props.details.replacement);

    let nextOption = [];
    switch (this.props.details?.status) {
      case "Pending Repair":
        nextOption = pendingRepairStatuses.map((st) => {
          return { value: st, label: st };
        });
        break;
      case "Awaiting Parts":
        nextOption = awaitingPartStatuses.map((st) => {
          return { value: st, label: st };
        });
        break;
      case "Goods received and under repair":
        nextOption = underRepairStatuses.map((st) => {
          return { value: st, label: st };
        });
        break;
      default:
      // code block
    }

    const isRepairStatus = repairStatuses.includes(this.props.details.status);

    return (
      <div className="card mb-3 ">
        <h4 className={"card-header"}>Ticket #{this.props.details?.ticket_number}</h4>
        <div className="card-body">
          <div>
            <b> Ticket type:</b>
            {this.props.details?.status?.toLowerCase().includes("pending approval") ? (
              <select
                data-testid="ticket-type-select"
                className="drop-down-tc form-select"
                onChange={(e) => this.handleTicketTypeChanges(e)}
                defaultValue={this.state.statusTicketType}
              >
                <option value="warranty"> Warranty </option>
                <option value="out_of_warranty"> Out of Warranty</option>
              </select>
            ) : (
              <span className="capitalize form-control" data-testid="ticket-type-span">
                {this.props.details?.ticket_type === "out_of_warranty" ? "Out of Warranty" : this.props.details?.ticket_type}
              </span>
            )}
          </div>
          <div>
            <b>Type of service:</b>
            {this.props.details?.status?.toLowerCase().includes("pending approval") ? (
              <select
                data-testid="service-type-select"
                className="drop-down-tc form-select"
                onChange={(e) => this.handleStatusTicketTypeModal(e)}
                value={this.state.typeService}
              >
                {this.state.statusTicketType === "out_of_warranty" ? (
                  <>
                    <option value="">----- Choose Type ----- </option>
                    <option value="service">Repair</option>
                    <option value="credit_claim">Commercial - Credit Claim</option>
                  </>
                ) : (
                  <>
                    <option value="">----- Choose Type ----- </option>
                    <option value="service">Repair</option>
                    <option value="refund">Refund </option>
                    <option value="replacement">Replace / Upgrade </option>
                    <option value="credit_claim">Commercial - Credit Claim</option>
                  </>
                )}
              </select>
            ) : (
              <span className="capitalize form-control">
                {this.props.details?.type_of_service === "service"
                  ? "Repair"
                  : this.props.details?.type_of_service === "credit_claim"
                  ? "Commercial - Credit Claim"
                  : this.props.details?.type_of_servic}
              </span>
            )}
          </div>
          <div>
            <b>Due Date:</b>
            {!this.props.details?.status?.toLowerCase().includes("service completed") &&
              !this.props.details?.status?.toLowerCase().includes("not approved") &&
              !this.props.details?.status?.toLowerCase().includes("did not proceed") && (
                <div>
                  <DatePicker
                    data-testid="due-date-picker"
                    id="ticket-datepicker"
                    className="form-control"
                    selected={due_date}
                    dateFormat="dd/MM/yyyy"
                    onChange={(val) =>
                      this.setState({
                        due_date: val,
                        showDueDateModal: true,
                      })
                    }
                  />
                </div>
              )}
          </div>
          <div className="card p-2 mt-2 mb-2" style={{ zIndex: 0 }}>
            {/* <ProgressBar
              values={this.props.details?.ticket_status_list}
              active={this.props.details?.ticket_status_list?.findIndex((value) =>
                value.toLowerCase().includes(this.props.details?.status.toLowerCase())
              )}
            /> */}
            <Stepper
              stepList={this.props.details?.ticket_status_list}
              statuses={this.props.details?.ticket_status}
              // currentIndex={this.props.details?.ticket_status.indexOf((x) => x.status === this.props.details?.status)}
            />

            {/* <div className={"single-warranty-details mt-2 text-center"}>
              <b>Status : </b>
              <span>
                <small>{this.props.details?.status}</small>
              </span>
            </div> */}
          </div>
          {this.props.details?.ticket_status_list?.indexOf(this.props.details?.status) > -1 &&
            ["Admin", "Service Center Admin"].includes(this.props.userInfo?.user.role) && (
              <div
                style={
                  this.props.details?.status.includes("Pending Approval")
                    ? { display: "none" }
                    : {
                        marginBottom: "10px",
                        textAlign: "center",
                      }
                }
              >
                <div className={"single-warranty-details text-start"}>
                  <b>Status: </b>
                  <div className="w-auto">
                    <Select
                      data-testid="select-status"
                      className="text-start"
                      value={{ value: this.state.statusTicket, label: this.state.statusTicket }}
                      options={statusListOption}
                      isSearchable={true}
                      // isDisabled={isDisableSC}
                      // onInputChange={(val) => this.handleSearchServiceCenter(val)}
                      onChange={(obj) => {
                        this.handleStatusTicket(obj);
                      }}
                      // onInputChange={(val) => this.searchLocation("suburb", val, this.state.stateVal)}
                    />
                  </div>
                </div>
              </div>
            )}
          {this.props.details?.out_of_warranty && <div className={"warranty-out-of-warranty-flag"}>Out of Warranty</div>}
          <div
            className={"single-warranty-details"}
            style={
              this.props.details?.status?.includes("Goods received pending replacement to be sent")
                ? {
                    marginBottom: "10px",
                    // textAlign: "center",
                  }
                : { display: "none" }
            }
          >
            <b>Replacement: </b>
            <div className="ticket_submit-wrapper">
              <Select
                data-testid="assign-to-select"
                value={selectedReplacement}
                isSearchable={true}
                options={this.props.deviceOptions}
                onChange={(obj) => {
                  this.handleDropDownReplacement(obj);
                }}
              />

              <button
                data-testid="assign-to-btn"
                onClick={() => this.setState({ replacementModal: true })}
                type="button"
                className="k-btn k-btn-outline-primary k-btn-sm"
              >
                Save
              </button>
            </div>
          </div>
          <div
            style={
              this.props.details?.status?.includes("Repair complete, goods sent out") || this.props.details?.status?.includes("Replacement Sent")
                ? {
                    marginBottom: "10px",
                    // textAlign: "center",
                  }
                : { display: "none" }
            }
          >
            <div className={"single-warranty-details text-start"}>
              <b>Tracking Number:</b>
              <div className="ticket_submit-wrapper">
                <input
                  type="text"
                  data-testid={"tracking-number"}
                  id="trackingNumber"
                  value={this.state.trackingNumber}
                  className="form-control"
                  style={{ height: "100%" }}
                  onChange={(e) => this.handleInputChange(e)}
                />

                <button
                  data-testid={"tracking-number-btn"}
                  onClick={(e) => this.handleSubmitTrackingNumber(e)}
                  className="k-btn k-btn-outline-primary k-btn-sm"
                >
                  Save
                </button>
              </div>
            </div>
          </div>

          <div
            className="m-3"
            style={
              this.props.details?.status?.includes("Pending Return Label to be Sent")
                ? {
                    marginBottom: "10px",
                    textAlign: "center",
                  }
                : { display: "none" }
            }
          >
            <button className="k-btn k-btn-outline-primary" onClick={() => this.setState({ showShipping: true })}>
              Return Label
            </button>
          </div>

          <div
            className="m-3"
            style={
              this.props.details?.status?.includes("Pending Receipt of Goods")
                ? {
                    marginBottom: "10px",
                    textAlign: "center",
                  }
                : { display: "none" }
            }
          >
            <button className="k-btn k-btn-outline-primary" onClick={() => this.setState({ showGoodReceived: true })}>
              Goods Received
            </button>
          </div>

          <div className={"single-warranty-details"}>
            <b>Service Center: </b>
            <div className="w-auto">
              <Select
                data-testid="service-center-central"
                value={selectedServiceCenterCentralValue}
                options={this.props.optionServiceCenterCentrals}
                isSearchable={true}
                isDisabled={isDisableSC}
                // onInputChange={(val) => this.handleSearchServiceCenter(val)}
                onChange={(obj) => {
                  this.handleDropDownServiceCenterCentral(obj);
                }}
                // onInputChange={(val) => this.searchLocation("suburb", val, this.state.stateVal)}
              />
            </div>
          </div>

          <div className={"single-warranty-details"}>
            <b>Service Center Location: </b>
            <div className="w-auto">
              <Select
                data-testid="service-center-location"
                value={selectedServiceCenterLocationValue}
                options={this.props.optionServiceCenterLocations}
                isSearchable={true}
                isDisabled={isDisableSCLoc}
                // onInputChange={(val) => this.handleSearchServiceCenter(val)}
                onChange={(obj) => {
                  this.handleDropDownServiceCenterLocation(obj);
                }}
                // onInputChange={(val) => this.searchLocation("suburb", val, this.state.stateVal)}
              />
            </div>
          </div>
          <div className={"single-warranty-details"}>
            <b>Assigned to: </b>
            <div className="ticket_submit-wrapper">
              <Select
                data-testid="assign-to-select"
                value={selectedAssignTo}
                isSearchable={true}
                options={assignTo}
                // onInputChange={(val) => this.handleSearchServiceCenter(val)}
                onChange={(obj) => {
                  // this.setState({ employeeID: obj.value });
                  this.handleDropDownAssignTo(obj);
                  // this.handleSearchServiceCenter(obj);
                }}
              />

              <button
                data-testid="assign-to-btn"
                onClick={() => this.setState({ showAssignModal: true })}
                type="button"
                className="k-btn k-btn-outline-primary k-btn-sm"
              >
                Save
              </button>
            </div>
          </div>
          <div className={"single-warranty-details"}>
            <b>Refund Amount:</b>
            <span>
              <div className="ticket_submit-wrapper">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">AUD</span>
                  </div>
                  <input
                    type="text"
                    data-testid={"refund-amount"}
                    id="refundAmount"
                    value={this.state.refundAmount}
                    className="form-control"
                    style={{ height: "100%" }}
                    onChange={(e) => this.handleInputChange(e)}
                  />
                </div>
                <button
                  data-testid={"refund-amount-btn"}
                  onClick={(e) => this.handleSubmitRefund(e)}
                  className="k-btn k-btn-outline-primary  k-btn-sm"
                >
                  Submit
                </button>
              </div>
            </span>
          </div>

          {this.props.warrantySteps[this.props.details?.status] && this.props.warrantySteps[this.props.details?.status].next && (
            <button
              className={"button"}
              onClick={() => {
                this.setState({ showNextStepModal: true });
              }}
            >
              {this.props.warrantySteps[this.props.details?.status].buttonText}
            </button>
          )}

          {["Service", "Staff", "Service Center Admin"].includes(this.props.userInfo?.user.role) ? (
            <div className="card-footer d-flex  justify-content-between gap-2">
              <button
                className={disableNext ? "btn btn-outline-secondary disabled  m-1 btn-block" : "btn btn-outline-danger  m-1 btn-block"}
                onClick={() => {
                  this.setState({ showDeclineModal: true });
                }}
                data-testid={"reject-btn"}
                disabled={disableNext}
              >
                Reject
              </button>
              <button
                data-testid={"next-btn"}
                className={disableNext ? "btn btn-outline-secondary disabled  m-1 btn-block" : "btn btn-outline-primary  m-1 btn-block"}
                onClick={this.handleNextModalOpen}
                disabled={disableNext}
              >
                Next
              </button>
            </div>
          ) : null}
        </div>
        {this.props.details?.status?.includes("Pending Approval") &&
          ["Admin", "Approver", "Service Center Admin"].includes(this.props.userInfo?.user.role) && (
            <div className="card-footer d-flex  justify-content-between gap-2">
              <button
                data-testid={"approve-btn"}
                className={"k-btn k-btn-primary-full"}
                onClick={() => {
                  this.setState({ showApproveModal: true });
                }}
              >
                Approve
              </button>
              <button
                data-testid={"decline-btn"}
                className={"k-btn k-btn-outline-primary-full"}
                onClick={() => {
                  this.setState({ showDeclineModal: true });
                }}
              >
                Decline
              </button>
            </div>
          )}
        {this.props.details?.return_label && (
          <div className="py-auto m-3">
            <a href={this.props.details?.return_label} target="_blank" rel="noreferrer" className="k-btn k-btn-outline-primary-full h-auto">
              View Return Label
            </a>
          </div>
        )}
        <Modal
          isOpen={this.state.showDueDateModal}
          //onRequestClose={()=>this.setState({showDeclineModal: false})}
          contentLabel={"Add Note"}
          className={"confirm-modal"}
        >
          <div className="card">
            <button onClick={() => this.setState({ showDueDateModal: false })} className={"confirm-modal-close"}>
              <MdClose />
            </button>
            <div className="card-header">Due Date</div>
            <div className="card-body">Are you sure you want to change the due date?</div>
            <div className="card-footer">
              <button
                data-testid={"save-due-date"}
                disabled={this.state.disableBtnDueDate}
                type="button"
                className={"btn btn-outline-primary "}
                onClick={() => this.changeDueDate(false)}
              >
                Save
              </button>
              <button
                type={"button"}
                className={"btn btn-outline-secondary"}
                onClick={() => this.setState({ showDueDateModal: false, dueDateReason: "" })}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <Modal isOpen={this.state.modalStatusConfirm} contentLabel={"Set as Out of Warranty"} className={"confirm-modal "}>
          <form>
            <div className="card">
              <div className="card-header">Ticket Status</div>
              <div className={"card-body"}>
                <div className="mb-3">Change status to {this.state.statusTicket}? </div>
                <button
                  data-testid="confirm-status-ticket"
                  type="button"
                  className={"btn btn-outline-primary mr-3"}
                  onClick={(e) => this.handleConfirmTicket(e)}
                >
                  Confirm
                </button>
                <button type={"button"} className={"btn btn-outline-secondary"} onClick={this.handleCancel}>
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </Modal>
        <Modal isOpen={this.state.modalServiceType} contentLabel={"Set as Out of Warranty"} className="confirm-modal confirm-modal-ticket">
          <form onSubmit={this.handleChangeStatusTicketType}>
            <div className="card">
              <div className="card-header">Ticket service type</div>
              <div className={"card-body"}>
                <div className="mb-3">Change service type to {this.state.typeService}? </div>
                <button
                  data-testid="confirm-service-type"
                  type="button"
                  className={"btn btn-outline-primary mr-3"}
                  onClick={(e) => this.handleChangeStatusTicketType(e)}
                >
                  Confirm
                </button>
                <button data-testid="cancel-service-type" type={"button"} className={"btn btn-outline-secondary"} onClick={this.handleCancel}>
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </Modal>
        <Modal isOpen={this.state.showAssignModal} contentLabel={"Add Note"} className={"confirm-modal"}>
          <form className="m-10">
            <div className="card">
              <div className="card-header">Assigned To</div>
              <div className={"card-body"}>
                <div className="mb-3">
                  {this.state.employeeID && this.state.newAssignTo ? (
                    `Confirm Assign ticket to ${this.state.employeeName}?`
                  ) : (
                    <div>Please assign anyone first. </div>
                  )}
                </div>

                <button
                  type="button"
                  className={"btn btn-outline-primary mr-3"}
                  data-testid="button-assign-to"
                  onClick={() => this.handleSubmitAssign()}
                >
                  Assign
                </button>

                <button type={"button"} className={"btn btn-outline-secondary"} onClick={this.handleCancel}>
                  Close
                </button>
              </div>
            </div>
          </form>
        </Modal>
        <Modal
          isOpen={this.state.showApproveModal}
          //onRequestClose={()=>this.setState({showDeclineModal: false})}
          contentLabel={"Add Note"}
          className={"confirm-modal"}
        >
          <div className="card">
            <button onClick={() => this.setState({ showApproveModal: false })} className={"confirm-modal-close"}>
              <MdClose />
            </button>
            <div className="card-header">Ticket Approval</div>
            <form onSubmit={this.handleApprove}>
              <div className={"card-body"}>
                <div className="mb-3">Do you really want to approve this ticket?</div>
                <button type="submit" className={"btn btn-outline-primary mr-3"} data-testid={"modal-approve-btn"}>
                  Approve
                </button>
                <button
                  type={"button"}
                  className={"btn btn-outline-secondary"}
                  onClick={() => this.setState({ showApproveModal: false })}
                  data-testid={"modal-approve-btn-cancel"}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Modal>
        <Modal isOpen={this.state.showShipping} contentLabel={"Export Report"} className={"input-modal"}>
          <div className="card">
            <div className="card-header">Upload Shipping Label</div>
            <div className="card-body">
              <div className="border">
                {/* <img src={this.state.image} alt="" /> */}
                <embed type="application/pdf" src={this.state.image || this.props.details.return_label} width="600" height="400"></embed>
                <div>
                  <label htmlFor="uploadFile" className="custom-file-upload m-3 ">
                    {"Upload "} <img src={icons.Upload} alt="" />
                  </label>
                  <small>{`${this.state.imageName ? this.state.imageName : "No file choosen"}`}</small>
                  <input accept="application/pdf" type={"file"} id={"uploadFile"} onChange={this.handleFileUpload} />
                </div>
              </div>
            </div>
            <div className="card-footer">
              <button
                type="submit"
                className={`btn btn-outline-primary mr-3 ${!this.state.imageName && " disabled"}`}
                data-testid={"modal-upload-btn"}
                onClick={() => this.uploadLabel()}
              >
                Save
              </button>
              <button
                type={"button"}
                className={"btn btn-outline-secondary"}
                data-testid={"modal-decline-btn-cancel"}
                onClick={() => this.setState({ showShipping: false })}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        {/* <Modal
          isOpen={this.props.details?.user_device.device.shipping_vendor === "auspost" && this.state.showShipping_}
          contentLabel={"Export Report"}
          className={"confirm-modal"}
        >
          <ShippingLabelAP
            protectedFetch={this.props.protectedFetch}
            userDevice={this.props.details?.user_device}
            disableInput={!!this.props.details?.transdirect_status}
            handleCloseModal={() => this.setState({ showShipping: false })}
            ticketId={this.props.details?.id}
            retrieveWarranty={() => this.props.retrieveWarranty(this.props.details?.id)}
          />
        </Modal> */}
        {/* <Modal
          isOpen={this.props.details?.user_device.device.shipping_vendor === "transdirect" && this.state.showShipping}
          contentLabel={"Export Report"}
          className={"fullpage-modal"}
        >
          <ShippingLabelTD
            protectedFetch={this.props.protectedFetch}
            userDevice={this.props.details?.user_device}
            disableInput={!!this.props.details?.transdirect_status}
            handleCloseModal={() => this.setState({ showShipping: false })}
            ticketId={this.props.details?.id}
            retrieveWarranty={() => this.props.retrieveWarranty(this.props.details.id)}
          />
        </Modal> */}
        <Modal
          isOpen={this.state.showDeclineModal}
          //onRequestClose={()=>this.setState({showDeclineModal: false})}
          contentLabel={"Decline warranty"}
          className={"confirm-modal warranty-decline-modal"}
        >
          <div className="card">
            <button onClick={() => this.setState({ showDeclineModal: false })} className={"confirm-modal-close"}>
              <MdClose />
            </button>
            <form onSubmit={this.handleDecline}>
              <div className="card-header">Reason for decline</div>
              <div className={"card-body"}>
                <textarea
                  required={true}
                  onChange={this.handleInputChange}
                  id={"declineReason"}
                  value={this.state.declineReason}
                  className="form-control"
                />
                <div>Are you sure you want to decline?</div>
              </div>

              <div className="card-footer">
                <button type="submit" className={"btn btn-outline-primary mr-3"} data-testid={"modal-decline-btn"}>
                  Decline
                </button>
                <button
                  type={"button"}
                  className={"btn btn-outline-secondary"}
                  data-testid={"modal-decline-btn-cancel"}
                  onClick={() => this.setState({ showDeclineModal: false })}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Modal>
        {this.props.warrantySteps[this.props.details?.status] && (
          <Modal
            isOpen={this.state.showNextStepModal}
            contentLabel={"Continue to next step of warranty"}
            className={"confirm-modal"}
            style={{ overlay: { zIndex: 10 } }}
          >
            <button onClick={() => this.setState({ showNextStepModal: false })} className={"confirm-modal-close"}>
              <MdClose />
            </button>
            <div className="confirm-main-text">{this.props.warrantySteps[status].modalText}</div>
            <div>
              <button onClick={this.handleNextStep} className={"btn btn-outline-primary mr-3"}>
                Yes
              </button>
              <button className={"btn btn-outline-secondary"} onClick={() => this.setState({ showNextStepModal: false })}>
                No
              </button>
            </div>
          </Modal>
        )}
        <Modal isOpen={this.state.modalNext} contentLabel={"Set as Out of Warranty"} className={"confirm-modal"}>
          <form onSubmit={this.handleServiceNext}>
            <div className="card">
              <div className="card-header">Ticket status</div>
              <div className={"card-body"}>
                <div className="mb-3">Move to next progress?</div>
                {isRepairStatus && (
                  <Select options={nextOption} required onChange={(e) => this.setState({ nextStatus: e.value })} className="text-start" />
                )}
              </div>
              <div className="card-footer">
                <button type="submit" className={"btn btn-outline-primary mr-3"} data-testid={"modal-next-btn"}>
                  Confirm
                </button>
                <button type={"button"} className={"btn btn-outline-secondary"} onClick={this.handleCancel} data-testid={"modal-reject-btn-cancel"}>
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </Modal>
        <Modal
          isOpen={this.state.modalServiceCenter}
          //onRequestClose={()=>this.setState({showDeclineModal: false})}
          contentLabel={"Add Note"}
          className={"confirm-modal"}
        >
          <div className="card">
            <button onClick={() => this.setState({ modalServiceCenter: false })} className={"confirm-modal-close"}>
              <MdClose />
            </button>
            <div className="card-header">Service Center</div>
            {/* <form onSubmit={() => this.setState({ modalServiceCenter: false })}> */}
            <form>
              <div className={"card-body"}>
                {this.state.newServiceCenter ? (
                  <div className="mb-3">
                    <div>Once this service centre has been selected, only a Super Admin can change it, are you sure?</div>
                  </div>
                ) : (
                  <div className="mb-3">Please select service center first!</div>
                )}
                {this.state.newServiceCenter && (
                  <button
                    type="submit"
                    className={"btn btn-outline-primary mr-3"}
                    data-testid={"modal-approve-btn"}
                    onClick={() => this.handleSubmitServiceCenterCentral(this.state.serviceCenterCentralId)}
                  >
                    Change
                  </button>
                )}
                <button
                  type={"button"}
                  className={"btn btn-outline-secondary"}
                  onClick={() => this.setState({ modalServiceCenter: false, serviceCenterCentralId: 0 })}
                  data-testid={"modal-approve-btn-cancel"}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Modal>

        <Modal isOpen={this.state.showGoodReceived} contentLabel={"Set as Out of Warranty"} className={"input-modal"}>
          <ReceivedGoods
            userDevices={this.props.details?.user_device}
            handleCloseModal={() => this.setState({ showGoodReceived: false })}
            serviceNext={() => this.handleServiceNext()}
            ticketId={this.props.details?.id}
          />
        </Modal>
        <Modal
          isOpen={this.state.replacementModal}
          //onRequestClose={()=>this.setState({showDeclineModal: false})}
          contentLabel={"Add Note"}
          className={"confirm-modal"}
        >
          <div className="card">
            <button onClick={() => this.setState({ replacementModal: false })} className={"confirm-modal-close"}>
              <MdClose />
            </button>
            <div className="card-header">Device Replacement</div>
            <form>
              <div className={"card-body"}>
                {this.state.replacement ? (
                  <div className="mb-3">Do you really want to replace current device?</div>
                ) : (
                  <div className="mb-3">Please add replacement device first?</div>
                )}

                <button type="submit" className={"btn btn-outline-primary mr-3"} data-testid={"modal-approve-btn"} onClick={() => this.replacement()}>
                  Confirm
                </button>
                <button
                  type={"button"}
                  className={"btn btn-outline-secondary"}
                  onClick={() => this.setState({ replacementModal: false })}
                  data-testid={"modal-approve-btn-cancel"}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    );
  }
}
