import React from "react";
import "./ErrorPage.css";

const NotFound = () => {
  return (
    <div className="ErrorPage">
      <h1>500</h1>
      <h3>{"Server Error"}</h3>
      <h6 className="mb-5">
        <span className="text-primary">The server is under maintenance </span>
        <span>or encountered an unexpected condition that prevented it from fulfilling the request .</span>
      </h6>
      <button onClick={() => (window.location.href = "/")} className="k-btn kbtn-outline-danger k-btn-sm">
        Go Back
      </button>
    </div>
  );
};
export default NotFound;
