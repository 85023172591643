import React, { Component } from "react";
import utilityFunctions from "../../../../store/utilityFunctions";
import errorFunctions from "../../../../store/errorFunctions";
import config from "../../../../config";

export default class ReceivedGoods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      checkedList: [],
    };
    this.handleChangeCheckList = this.handleChangeCheckList.bind(this);
    this.handleAllCheckList = this.handleAllCheckList.bind(this);
  }

  handleChangeCheckList = (e, data) => {
    let tempCheckList = [...this.state.checkedList];
    if (e.target.checked) {
      if (this.state.checkedList.length > 0 && this.checkedList?.include(data.id)) {
        tempCheckList = tempCheckList.filter((item) => item.accessory !== data.id);
      } else {
        tempCheckList.push({ accessory: data.id, quantity: data.quantity });
      }
    } else tempCheckList = tempCheckList.filter((item) => item.accessory !== data.id);

    this.setState({ checkedList: tempCheckList });
  };

  handleAllCheckList = (e) => {
    let tempCheckList = [];
    if (e.target.checked) {
      tempCheckList = this.props.userDevices?.device.accessories?.map((data) => {
        return { accessory: data.id, quantity: data.quantity };
      });
    }
    this.setState({ checkedList: tempCheckList });
  };

  handleSubmit = () => {
    this.setState({ loading: true });
    const payload = this.state.checkedList;
    if (payload.length === 0) return alert("There's no Device Part is selected");
    const nullQty = payload.find((x) => !x.quantity);
    if (nullQty) {
      const nullQtyItem = this.props.userDevices?.device.accessories.find((x) => nullQty.accessory === x.id);
      alert(`${nullQtyItem.name} quantity can not be null`);
      return;
    }
    utilityFunctions
      .protectedFetch(
        `${config.backendServer}ticket/${this.props.ticketId}/received-accessories/`,
        "POST",
        payload,
        errorFunctions.checkResponseThenJson
      )
      .then((res) => {
        console.log(res);
        this.setState({ loading: false });
        this.props.serviceNext();
        this.props.handleCloseModal();
      });
  };

  quantityChange = (e, data) => {
    // const qty = parseInt(e.target.value || "0");
    const qty = e.target.value;
    const isSelected = !!this.state.checkedList.find((item) => item.accessory === data.id);
    if (!isSelected) {
      alert("Please select device part first");
      return;
    }
    let tempCheckList = this.state.checkedList.filter((item) => item.accessory !== data.id);

    tempCheckList.push({ accessory: data.id, quantity: qty });
    this.setState({ checkedList: tempCheckList });
  };

  render() {
    return (
      <div className="card text-left">
        <div className="card-header">
          <b>Checklist of device parts received</b>
        </div>
        <div className="card-body ">
          <ul className="list-group">
            <li className="list-group-item">
              <b>Device</b>
              <div>{this.props.userDevices.device.name}</div>
            </li>

            <li className="list-group-item">
              <b>Device Part Received</b>
              <li className="list-group-item" style={{ backgroundColor: "#D2D6DC" }}>
                <div className="row ">
                  <div className="col-1 text-center">
                    <input
                      className={"form-check-input"}
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      checked={this.state.checkedList.length === this.props.userDevices?.device.accessories.length}
                      onChange={(e) => this.handleAllCheckList(e)}
                    />
                  </div>
                  <div className="col-5 text-center">Name</div>
                  <div className="col-2 text-right">Qty</div>
                  <div className="col-4 text-right">Qty Received</div>
                </div>
              </li>
              {this.props.userDevices?.device.accessories?.map((dev, i) => {
                const checkedDevice = this.state.checkedList.find((x) => x.accessory === dev.id);
                return (
                  <li className={"list-group-item"} key={i}>
                    <div className="row ">
                      <div className="form-check col-7">
                        <input
                          className={"form-check-input col-1"}
                          type="checkbox"
                          // value={checkedDevice}
                          id="flexCheckChecked"
                          checked={checkedDevice}
                          onChange={(e) => this.handleChangeCheckList(e, dev)}
                        />
                        <label style={{ backgroundColor: "#D2D6DC" }} className="form-check-label col" htmlFor="flexCheckChecked">
                          {dev.name}
                        </label>
                      </div>
                      {/* <div className="col-7">{g.name}</div> */}
                      <div className="col-2 mr-1" style={{ backgroundColor: "#D2D6DC" }}>
                        {dev.quantity}
                      </div>
                      <input
                        type="number"
                        min="1"
                        className="col"
                        style={{
                          border: "solid 1px #D2D6DC",
                        }}
                        onChange={(e) => this.quantityChange(e, dev)}
                        onBlur={(e) => {
                          if (this.state.checkedList.find((x) => x.accessory == dev.id) && (!e.target.value || e.target.value === "0"))
                            alert(`${dev.name} quantity can not be null`);
                        }}
                        defaultValue={this.state.checkedList.find((x) => x.accessory == dev.id)?.quantity || dev.quantity}
                        value={this.state.checkedList.find((x) => x.accessory == dev.id)?.quantity}
                      />
                    </div>

                    {/* <div className="form-check">
                      <input
                        className={"form-check-input"}
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                        checked={this.checkedList?.include(g.id)}
                        onChange={() => this.handleChangeCheckList(g)}
                      />
                      <label className="form-check-label" htmlFor="flexCheckChecked">
                        {g.name}
                      </label>
                    </div> */}
                  </li>
                );
              })}
            </li>
          </ul>
        </div>
        <div className="card-footer">
          <button type="button" className="k-btn k-btn-primary mr-3" onClick={() => this.handleSubmit()}>
            Save
          </button>
          <button className="k-btn k-btn-outline-primary " onClick={() => this.props.handleCloseModal()}>
            Close
          </button>
        </div>
      </div>
    );
  }
}
