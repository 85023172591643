import React, { Component } from "react";
import config from "../../config";
import errorFunctions from "../../store/errorFunctions";
import LoadingPage from "../../components/Loading/Loading";
import Tabs from "../../components/Tabs/Tabs";
import "./CustomerDetail.css";
import Tickets from "./Tickets/Tickets";
import Activities from "./Activities/Activities";
import Devices from "./Devices/Devices";
import Notes from "./Notes/Notes";
import moment from "moment";
import Modal from "react-modal";
import CustomerEdit from "./CustomerEdit/CustomerEdit";
import Logs from "./Logs/Logs";

export default class CustomerDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      customerId: "",
      customer: undefined,
      searchFilter: "",
      warranties: undefined,
      tickets: [],
      ticketActivities: [],
      customerDevices: [],
      customerNotes: [],
      defaultTab: "Activity",
    };
  }
  componentDidMount() {
    const customerId = this.props.computedMatch.params.id;
    this.setState({ customerId });
    this.retrieveCustomer(customerId);
    this.retrieveCustomerDevices(customerId);
  }

  retrieveCustomer = (id) => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + `customers/${id}/`, "GET", { id }, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({ loading: false, customer: res });
        // this.retrieveWarranties(this.props.selectedbrandID);
        this.retrieveCustomerNotes(id);
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
  };

  retrieveCustomerDevices = (id) => {
    this.props
      .protectedFetch(config.backendServer + `customers/${id}/devices`, "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        const ticketTemp = res.map((x) => x.tickets).flat();
        const ticketActivitiesTemp = ticketTemp
          .map((ticket) =>
            ticket.ticket_activities.map((item) => {
              return {
                activity: item,
                ticketNumber: ticket.ticket_number,
                ticketId: ticket.id,
              };
            })
          )
          .flat();

        this.setState({
          loading: false,
          customerDevices: res,
          tickets: ticketTemp,
          ticketActivities: ticketActivitiesTemp,
        });
        console.log(res);
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
  };

  retrieveTicketDetails = (id) => {
    this.props
      .protectedFetch(config.backendServer + `ticket/${id}/`, "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        const ticketDetailTemp = [...this.state.ticketDetails, res];
        this.setState({ loading: false, ticketDetails: ticketDetailTemp });
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
  };
  render() {
    if (this.state.loading) return <LoadingPage />;
    if (!this.state.customer) {
      return (
        <div className={"page"}>
          <h2 className={"pageHeader"}>Error - page not found</h2>
        </div>
      );
    }

    return (
      <div className={"page"}>
        <div className="row">
          <div className="col-md-4 border-start">
            <div className={"pageHeader"}>
              <div className="card mb-3">
                <div className="card-body bg-light">
                  <h2>{`${this.state.customer.first_name} ${this.state.customer.last_name}`}</h2>
                  <h6>{this.state.customer.email}</h6>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <h6 className="col-9 my-auto">Customer Details</h6>
                  <button className="k-btn k-btn-primary k-btn-sm col-2 m-auto text" onClick={() => this.setState({ showEditCustomer: true })}>
                    Edit
                  </button>{" "}
                </div>
              </div>

              <div className="card-body">
                <div className={"customer-details-item"}>
                  <span className={"customer-details-label"}>First Name: </span>
                  <span>{this.state.customer.first_name}</span>
                </div>
                <div className={"customer-details-item"}>
                  <span className={"customer-details-label"}>Last Name: </span>
                  <span>{this.state.customer.last_name}</span>
                </div>

                <div className={"customer-details-item"}>
                  <span className={"customer-details-label"}>Mobile: </span>
                  <span>{this.state.customer.mobile}</span>
                </div>
                <div className={"customer-details-item"}>
                  <span className={"customer-details-label"}>Email: </span>
                  <span>{this.state.customer.email}</span>
                </div>
                <div className={"customer-details-item"}>
                  <span className={"customer-details-label"}>Last Contacted: </span>
                  <span>
                    {this.state.tickets && this.state.tickets.length > 0 && moment(this.state.tickets[0].last_activity_date).format("DD/MM/YYYY")}
                  </span>
                </div>
                <div className={"customer-details-item"}>
                  <span className={"customer-details-label"}>Address Line 1: </span>
                  <span>{this.state.customer.address_line_one}</span>
                </div>
                <div className={"customer-details-item"}>
                  <span className={"customer-details-label"}>Address Line 2: </span>

                  <span>{this.state.customer.address_line_two}</span>
                </div>
                <div className={"customer-details-item"}>
                  <span className={"customer-details-label"}>Suburb: </span>
                  <span>{this.state.customer.suburb}</span>
                </div>
                <div className={"customer-details-item"}>
                  <span className={"customer-details-label"}>State: </span>
                  <span>{this.state.customer.state}</span>
                </div>
                <div className={"customer-details-item"}>
                  <span className={"customer-details-label"}>Postcode: </span>
                  <span>{this.state.customer.postcode}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div>
              <Tabs resetTab={() => this.setState({ defaultTab: undefined })} defaultTab={this.state.defaultTab}>
                <div label="Activity">
                  {this.state.ticketActivities && this.state.ticketActivities.length > 0 && (
                    <Activities
                      data={this.state.ticketActivities}
                      protectedFetch={this.props.protectedFetch}
                      selectedbrandID={this.props.selectedbrandID}
                      role={this.userRole}
                    />
                  )}
                </div>
                <div label="Notes">
                  <Notes protectedFetch={this.props.protectedFetch} selectedbrandID={this.props.selectedbrandID} customerId={this.state.customerId} />
                </div>
                <div label="Device">
                  <Devices
                    customerId={this.state.customerId}
                    data={this.state.customerDevices}
                    protectedFetch={this.props.protectedFetch}
                    selectedbrandID={this.props.selectedbrandID}
                    role={this.userRole}
                    refreshTickets={() => this.retrieveWarranties(this.props.selectedbrandID)}
                    retrieveCustomerDevices={() => this.retrieveCustomerDevices(this.state.customerId)}
                  />
                </div>
                <div label="Tickets">
                  <Tickets
                    data={this.state.tickets}
                    protectedFetch={this.props.protectedFetch}
                    selectedbrandID={this.props.selectedbrandID}
                    role={this.userRole}
                  />
                </div>
                <div label="Logs">
                  <Logs logData={this.state.customer.change_logs || []} customer={this.state.customer} />
                </div>
              </Tabs>
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.showEditCustomer} onRequestClose={() => this.setState({ showEditCustomer: false })} className={"input-modal"}>
          <CustomerEdit
            retrieveCustomer={() => this.retrieveCustomer(this.state.customer.id)}
            customer={this.state.customer}
            handleCloseModal={() => this.setState({ showEditCustomer: false })}
          />
        </Modal>
      </div>
    );
  }
}
