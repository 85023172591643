import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
export default class Tickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      warranties: [],
      statusFilter: "All",
      searchFilter: "",
    };
  }

  render() {
    if (!(this.props.data && this.props.data.length > 0)) return <div>No Activity</div>;
    return (
      <div className={"activity-wrapper"}>
        {/* <h4 className={"single-warranty-header"}>activities and comments</h4> */}
        <ul style={{ padding: "0px" }}>
          {this.props.data
            .sort((a, b) => {
              return b.activity.id - a.activity.id;
            })
            .map(function (item, i) {
              const isNote = item.activity.subject.includes("Note by");
              // const isPicture =
              //   item.activity.media &&
              //   (item.activity.media.includes(".jpg") || item.activity.media.includes(".png") || item.activity.media.includes(".jpeg"));
              // const isVideo =
              //   item.activity.media &&
              //   (item.activity.media.includes(".mp4") || item.activity.media.includes(".webm") || item.activity.media.includes(".mov"));
              return (
                <div key={i} className="card" style={{ marginBottom: "10px" }}>
                  <div>
                    <div style={{ padding: "3px", margin: "1px" }} className={isNote ? "card-note-title row" : "card-ticket-title row"}>
                      <div className="col-8">{item.activity.subject}</div>
                    </div>
                    <div className="card-body">
                      {/* {isPicture ? (
                        <img src={item.activity.media} alt={item.activity.media} width="100%" height="100%" />
                      ) : isVideo ? (
                        <video width="100%" height="100%" controls>
                          <source src={item.activity.media} type="video/mp4" />
                          <source src={item.activity.media} type="video/webm" />
                        </video>
                      ) : (
                        <p className="card-text">{item.activity.detail}</p>
                      )} */}
                      <div className="row d-flex justify-content-left">
                        {item.activity.list_media?.length > 0 ? (
                          item.activity.list_media.map((media, idx) => {
                            const className =
                              item.activity.list_media?.length > 1
                                ? "p-2 col-6 border border-light d-flex  justify-content-left "
                                : "p-2 border border-light d-flex  justify-content-left ";
                            const isPicture =
                              media.media.toLowerCase().includes(".jpg") ||
                              media.media.toLowerCase().includes(".png") ||
                              media.media.toLowerCase().includes(".jpeg");
                            const isVideo =
                              media.media.toLowerCase().includes(".mp4") ||
                              media.media.toLowerCase().includes(".mov") ||
                              media.media.toLowerCase().includes(".webm");
                            if (isPicture)
                              return (
                                <div key={idx} className={className}>
                                  <img width="100%" height="100%" src={media.media} alt={media} key={media} />
                                </div>
                              );
                            else if (isVideo)
                              return (
                                <div key={idx} className={className}>
                                  <video width="100%" height="100%" controls data-testid={`video-${item.activity.id}`} key={idx}>
                                    <source src={media.media} type="video/mp4" />
                                    <source src={media.media} type="video/webm" />
                                    <source src={media.media} type="video/quicktime" />
                                    <source src={media.media} type="video/x-quicktime" />
                                  </video>
                                </div>
                              );
                          })
                        ) : (
                          <div className="card-text">{item.activity.detail}</div>
                        )}
                      </div>

                      <div className="row">
                        <small className="card-text col">{moment(item.activity.created).format("DD MMMM YYYY HH:mm")}</small>
                        <div className="card-text col" style={{ textAlign: "right" }}>
                          <small>
                            Ticket #:
                            <Link to={"/warranty/" + item.ticketId}>{item.ticketNumber}</Link>
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </ul>
      </div>
    );
  }
}
