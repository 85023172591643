import React, { Component } from "react";
import config from "../../config";
import errorFunctions from "../../store/errorFunctions";
import LoadingPage from "../../components/Loading/Loading";
import Tabs from "../../components/Tabs/Tabs";
import "./ServiceCenterDetail.css";
import Tickets from "./Tickets/Tickets";
// import Devices from "./Devices/Devices";
import Notes from "./Notes/Notes";
import Users from "./Users/Users";
// import moment from "moment";

export default class ServiceCenterDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      serviceCenterId: "",
      serviceCenter: undefined,
      searchFilter: "",
      warranties: undefined,
      tickets: [],
      notes: [],
      defaultTab: "Notes",
      employees: null,
    };
  }
  componentDidMount() {
    const serviceCenterId = this.props.computedMatch.params.id;
    this.setState({ serviceCenterId });
    this.retrieveServiceCenter(serviceCenterId);
    this.retrieveEmployeeList();
    // this.retrieveCustomerDevices(customerId);
  }
  retrieveEmployeeList = () => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + "employee/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        console.log(res);
        this.setState({ employees: res, loading: false });
      })
      .catch(() => {
        console.log("fail");
      });
  };

  retrieveServiceCenter = (id) => {
    this.setState({ loading: true, serviceCenterId: id });

    this.props
      .protectedFetch(config.backendServer + `service_center/${id}/`, "GET", { id }, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({ loading: false, serviceCenter: res, tickets: res.tickets, notes: res.notes });
        // this.retrieveWarranties(this.props.selectedbrandID);
        // this.retrieveCustomerNotes(id);
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
  };

  render() {
    if (!this.state.serviceCenter) {
      return (
        <div className={"page"}>
          <h2 className={"pageHeader"}>Error - page not found</h2>
        </div>
      );
    }

    return (
      <div className={"page"}>
        {this.state.loading && <LoadingPage />}
        <div className="row">
          <div className="col-md-4 border-start">
            <div className={"pageHeader"}>
              <div className="card mb-3">
                <div className="card-body bg-light">
                  <h4>{this.state.serviceCenter.service_center}</h4>
                </div>
              </div>
            </div>
            <div className="card">
              <h6 className="card-header">{this.state.serviceCenter.name}</h6>
              <div className="card-body">
                <div className={"customer-details-item"}>
                  <span className={"customer-details-label"}>Address: </span>
                  <span>{this.state.serviceCenter.address}</span>
                </div>
                <div className={"customer-details-item"}>
                  <span className={"customer-details-label"}>Contact Number: </span>
                  <span>{this.state.serviceCenter.contact_number}</span>
                </div>
                <div className={"customer-details-item"}>
                  <span className={"customer-details-label"}>Email: </span>
                  <span>{this.state.serviceCenter.email}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div>
              <Tabs resetTab={() => this.setState({ defaultTab: undefined })} defaultTab={this.state.defaultTab}>
                <div label="Notes">
                  <Notes
                    retrieveServiceCenter={this.retrieveServiceCenter}
                    data={this.state.notes}
                    protectedFetch={this.props.protectedFetch}
                    selectedbrandID={this.props.selectedbrandID}
                    serviceCenterId={this.state.serviceCenterId}
                  />
                </div>
                <div label="Tickets">
                  <Tickets
                    serviceCenterId={this.state.serviceCenterId}
                    // data={this.state.tickets}
                    protectedFetch={this.props.protectedFetch}
                    selectedbrandID={this.props.selectedbrandID}
                    role={this.userRole}
                  />
                </div>
                <div label="Staffs">
                  <Users
                    // data={this.state.serviceCenter.staff}
                    employees={this.state.employees}
                    protectedFetch={this.props.protectedFetch}
                    selectedbrandID={this.props.selectedbrandID}
                    role={this.userRole}
                    retrieveServiceCenter={() => this.retrieveServiceCenter(this.state.serviceCenterId)}
                    serviceCenterId={this.state.serviceCenterId}
                    setDefaultTab={() => this.setState({ defaultTab: "Staffs" })}
                  />
                </div>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
