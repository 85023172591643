import React, { Component } from "react";
import { Link } from "react-router-dom";
import LoadingPage from "../../components/Loading/Loading";
import Table from "../../components/Table/TableStaff";
import config from "../../config";
import errorFunctions from "../../store/errorFunctions";
import "../common.css";
import "./ManageStaff.css";

export default class ManageStaff extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      staff: [],
      searchFilter: "",
    };
  }

  componentDidMount() {
    this.retriveStaffList();
    // this.retrievewarramtiesDummy();
  }
  retriveStaffList = () => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + "employee/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        const tempStaff = res.filter((x) => !x.email.includes("@treeclouds.com") && !x.email.includes("@karadigital.co"));
        this.setState({ loading: false, staff: tempStaff });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  filterChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  render() {
    if (this.state.loading) {
      return <LoadingPage />;
    }
    let staff = this.state.staff;

    // Filter out warranties
    let filterStaff = [];

    //RMAS-37 search filter function
    if (this.state.searchFilter) {
      staff.forEach((item) => {
        if (
          (item.first_name + item.last_name)?.toLowerCase()?.includes(this.state.searchFilter.trim().toLowerCase()) ||
          item.email?.includes(this.state.searchFilter.trim().toLowerCase())
        ) {
          filterStaff.push(item);
        }
      });
    } else {
      staff.map((val) => filterStaff.push(val));
    }

    const tableHead = [
      {
        id: "name",
        label: "Name",
        valueDisplay: (value) => value || "",
      },
      {
        id: "email",
        label: "email",
        valueDisplay: (value) => value || "email empty",
      },
      {
        id: "user_role",
        label: "User Role",
        valueDisplay: (value) => value || "",
      },
      {
        id: "brands",
        label: "Brands",
        valueDisplay: (value) => value || "",
      },
      {
        id: "service_center",
        label: "Service Center Count",
        valueDisplay: (value) => value || "",
      },
      {
        id: "action",
        label: "Action",
        valueDisplay: (value) => value || "",
      },
    ];

    return (
      <div className={"page"}>
        <div className="d-flex justify-content-between mb-3">
          <div style={{ width: "160px", borderBottom: "1px solid black", textAlign: "center" }}>
            <h5>Manage Staff</h5>
          </div>
          <Link to="/staff-new" className="link-light text-decoration-none">
            <button title="Add device item" className="k-btn k-btn-primary k-btn-sm">
              Add New Staff
            </button>
          </Link>
        </div>

        <div className="d-flex justify-content-end">
          <div className="col-3 input-group pr-0">
            <input
              type="text"
              placeholder="Search Staff"
              id={"searchFilter"}
              className={"form-control"}
              onChange={this.filterChange}
              value={this.state.searchFilter}
              width={"70px"}
            />
            <div className="input-group-prepend">
              <span className="form-control ">
                <i className="fa fa-search form-control-icon"></i>
              </span>
            </div>
          </div>
        </div>

        <div className={"pageContent clearfix"}>
          {filterStaff.length === 0 && <div>No staff</div>}
          {filterStaff.length > 0 && (
            <div className={"admin-table-container"}>
              <Table head={tableHead} data={filterStaff} reloadMini={this.retriveStaffList} protectedFetch={this.props.protectedFetch} />
            </div>
          )}
        </div>
      </div>
    );
  }
}
