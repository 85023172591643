/*
Functions to use for random error checks that are fairly common to different functions
e.g. initial fetch then to work out whether to when to jsonify a return or not
 */

let errorFunctions = {
  checkResponseThenJson: function (response) {
    //Check status of error - if ok convert to json
    if (!response.ok) {
      return response;
    }
    return response.json();
  },
  checkResponseSent: function (response) {
    //Check status of error - this one is just status returns
    return response.ok;
  },
};

export default errorFunctions;
