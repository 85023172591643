import React, { Component } from "react";
import LoadingPage from "../../components/Loading/Loading";
import Table from "../../components/Table/TableInventory";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
// import { IoMdAddCircle, IoMdCloudUpload } from "react-icons/io";
import axios from "axios";
import config from "../../config";
import errorFunctions from "../../store/errorFunctions";
import referenceLibrary from "../../store/referenceLibrary";
import utilityFunctions from "../../store/utilityFunctions";
import InventoryAdd from "./InventoryAdd/InventoryAdd";
import moment from "moment";
import "../common.css";
import "./Inventory.css";

export default class Inventory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      inventory: [],
      statusFilter: "All",
      searchFilter: "",
      statusSelector: 0,
      devices: [],
      deviceId: undefined,
      showAddInventory: false,
      showAddBulkInventory: false,
      isEdit: false,
      rowInventory: undefined,
      rowsPerPage: 10,
      pageNumber: 0,
      objInventory: undefined,
    };
  }

  componentDidMount() {
    this.retrieveInventory();
    this.retrieveDevices();
  }

  retrieveDevices() {
    this.props
      .protectedFetch(config.backendServer + "devices/?brandId=" + this.props.selectedbrandID, "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        const brandDevice = res.map((dev) => {
          if (res.length > 0)
            return {
              id: dev.id,
              name: dev.brand.name + " " + dev.name,
            };
          else return [];
        });
        this.setState({
          isLoading: false,
          devices: brandDevice,
          deviceId: brandDevice[0]?.id,
        });
      });
  }

  retrieveInventory = (urlTxt, rowPerPage, pageNumber, searchTxt, orderTxt) => {
    let apiUrl = config.backendServer + `inventories`;
    if (urlTxt) apiUrl = urlTxt;
    else {
      const pageSize = `?page_size=${rowPerPage || this.state.rowsPerPage || ""}`;
      // const pageNo = pageNumber > 0 ? `&page=${pageNumber || this.state.pageNumber + 1}` : "";
      const search = searchTxt ? `&search=${searchTxt || this.state.search || ""}` : "";
      const ordering = orderTxt ? `&ordering=${orderTxt || this.state.orderingBy || ""}` : "";

      apiUrl = apiUrl + `${pageSize}${search}${ordering}`;
    }
    this.setState({ loading: true });
    this.props
      .protectedFetch(apiUrl, "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        const inventoryTemp = res.results.map((item) => {
          return {
            material_number: item.material_number,
            device_name: item.device.name,
            spare_part_name: item.spare_part_name,
            part_used: item.part_used,
            device: item.device.id,
            inventory_id: item.id,
          };
        });
        this.setState({
          loading: false,
          objInventory: res,
          inventory: inventoryTemp,
          showAddInventory: false,
        });
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false, showAddInventory: false });
      });
  };
  retrieveHistory = (id) => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + `inventories/${id}/histories`, "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({
          loading: false,
          history: res.filter((x) => x.type === "used"),
        });
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
  };

  filterChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  onKeyDown = (e) => {
    if (e.key === "Enter") this.retrieveInventory(null, null, null, this.state.searchFilter);
  };
  handleFilterStatus = (val) => {
    const statusText = referenceLibrary.statusSelectors.find((x) => x.id === parseInt(val))?.name || "";
    if (statusText === "") this.setState({ statusFilter: "All" });
    else this.setState({ statusFilter: "status", statusSelector: statusText });
  };

  handleHistory = (row) => {
    console.log(row);
    this.setState({ showHistory: true, rowInventory: row });
    this.retrieveHistory(row.inventory_id);
  };

  statusFilterChange = (value) => {
    this.setState({ statusFilter: value });
  };

  formatValueWithCommas = (value) => {
    if (value === "") return value;

    // Split the value into integer and decimal parts (if applicable)
    const parts = value.toString().split(".");
    let formattedValue = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (parts.length > 1) {
      const decimalPart = parts[1].slice(0, 2);
      formattedValue += `.${decimalPart}`;
    }

    return formattedValue;
  };
  handleSubmitBulkInventory = () => {
    if (this.state.allowUpload) {
      const fileInventory = document.getElementById("uploadInventory").files[0];
      let formData = new FormData();
      formData.append("file", fileInventory);
      this.uploadInventory(formData);
      // this.setState({ mediaNotes: media, dueDateType: "media" })
      // this.changeDueDate(true);
    } else {
      alert("CSV files are allowed.");
      this.setState({ loading: false, showAddMedia: true });
    }
  };

  uploadInventory = (formData) => {
    this.setState({ loading: true });
    const userInfo = localStorage.getItem("lgn") || utilityFunctions.getCookie("userInfo");
    const token = userInfo && JSON.parse(userInfo).access_token;
    let options = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "multipart/form-data",
      },
    };
    // e.preventDefault();
    const url = config.backendServer + "inventories/bulk_upload/?brandId=" + this.props.selectedbrandID;
    axios
      .post(url, formData, options)
      .then(() => {
        this.setState({ showAddBulkInventory: false });
        // this.retrieveWarranty(this.state.id);
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false, showAddBulkInventory: false });
      });
  };

  handleFileUpload = (e) => {
    // TODO - verify file upload to make sure it's good
    const file = e.target.files[0];
    const allowedTypes = ["text/csv"];

    if (!allowedTypes.includes(file.type)) {
      alert("Only CSV files are allowed.");
      this.setState({ allowUpload: false });
    } else {
      this.setState({ allowUpload: true, fileName: file.name });
    }
  };

  // Service Complete
  // Not Approve
  // Did not Proceed

  render() {
    let inventory = this.state.inventory;

    // Filter out warranties
    let filteredInventory = this.state.inventory;

    const tableHead = [
      {
        id: "material_number",
        label: "Material Number",
        valueDisplay: (value) => value,
      },
      {
        id: "device_name",
        label: "Dreame Model",
        valueDisplay: (value) => value,
      },

      {
        id: "spare_part_name",
        label: "Spare Part Name",
        valueDisplay: (value) => value,
      },
      {
        id: "part_used",
        label: "Part Used",
        align: "right",
        width: "100px",
        valueDisplay: (value) => value,
      },
      {
        id: "history",
        label: "",
        align: "right",
        no_sort: true,
        valueDisplay: (value) => value,
      },
    ];

    return (
      <div>
        <div className={"page"}>
          {this.state.loading && <LoadingPage />}
          <div className="row">
            <div className="d-flex justify-content-between">
              <div
                style={{
                  width: "120px",
                  borderBottom: "1px solid black",
                  textAlign: "center",
                }}
              >
                <h5>Inventories </h5>
              </div>
              <div>
                <button
                  className="k-btn k-btn-primary k-btn-sm"
                  onClick={() =>
                    this.setState({
                      showAddInventory: true,
                      rowInventory: undefined,
                    })
                  }
                >
                  Add Inventory
                </button>
                {/* <button className="k-btn k-btn-primary k-btn-sm" onClick={() => this.setState({ showAddBulkInventory: true })}>
                  Add Bulk Inventory
                </button> */}
              </div>
            </div>

            <div className="d-flex justify-content-end col">
              <div className="mt-1">
                <div className="input-group mb-3 position-relative bottom-0 end-0">
                  <input
                    type="text"
                    placeholder="Search inventory"
                    id={"searchFilter"}
                    className={"form-control"}
                    onChange={this.filterChange}
                    onKeyDown={this.onKeyDown}
                    value={this.state.searchFilter}
                    width={"70px"}
                  />
                  <div className="input-group-prepend">
                    <span className="form-control ">
                      <i className="fa fa-search form-control-icon"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={"pageContent clearfix"}>
            {inventory?.length === 0 && <div>No inventory</div>}
            {inventory?.length > 0 && (
              <div className={"admin-table-container card"}>
                <Table
                  head={tableHead}
                  data={filteredInventory}
                  objInventory={this.state.objInventory}
                  retrieveInventory={(url, rowPerPage, pageNumber, searchTxt, orderTxt) =>
                    this.retrieveInventory(url, rowPerPage, pageNumber, searchTxt, orderTxt)
                  }
                  protectedFetch={this.props.protectedFetch}
                  selectedbrandID={this.props.selectedbrandID}
                  role={this.props.userInfo?.user?.role}
                  statusSelectors={referenceLibrary.statusSelectors}
                  filteredStatus={this.handleFilterStatus}
                  rowsPerPage={this.state.rowsPerPage}
                  rowsPerPageChange={(val) => this.setState({ rowsPerPage: val })}
                  pageNumber={this.state.pageNumber}
                  pageNumberChange={(val) => this.setState({ pageNumber: val })}
                  editInventory={(row) =>
                    this.setState({
                      showAddInventory: true,
                      isEdit: true,
                      rowInventory: row,
                    })
                  }
                  onOrderBy={(orderBy_) => this.setState({ orderBy: orderBy_ })}
                  order={this.state.order}
                  onOrder={(val) =>
                    this.setState({
                      order: val,
                    })
                  }
                  onHistory={(row) => this.handleHistory(row)}
                />
              </div>
            )}
          </div>
        </div>
        <Modal
          isOpen={this.state.showAddBulkInventory}
          //onRequestClose={()=>this.setState({showDeclineModal: false})}
          contentLabel={"Add Note"}
          className={"input-modal"}
        >
          <div className="card">
            <button onClick={() => this.setState({ showAddBulkInventory: false })} className={"confirm-modal-close"}>
              <MdClose />
            </button>
            <div className="card-header">
              <span>Add Bulk Inventory</span>
            </div>
            <div className="card-body">
              <div className="file-upload-container mb-3">
                <label htmlFor="uploadFile" className="custom-file-upload ">
                  Upload File
                </label>
                <span>{`  ${this.state.fileName || "No file choosen"}`}</span>
                <input accept="text/csv" type={"file"} className="form-control" id={"uploadFile"} required={true} onChange={this.handleFileUpload} />
              </div>
            </div>
            <div className="card-footer">
              <button type="submit" className={"btn btn-outline-primary mr-3"} onClick={() => this.handleSubmitBulkInventory()}>
                Upload
              </button>
              <button type="button" className={"btn btn-outline-secondary"} onClick={() => this.setState({ showAddBulkInventory: false })}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showAddInventory}
          //onRequestClose={()=>this.setState({showDeclineModal: false})}
          contentLabel={"Add Inventory"}
          className={"input-modal"}
          // className={"confirm-modal confirm-modal-big warranty-decline-modal"}
        >
          {/* <form onSubmit={this.handleSubmitNote}> */}
          <InventoryAdd
            protectedFetch={this.props.protectedFetch}
            handleCloseModal={() => this.setState({ showAddInventory: false, isEdit: false })}
            reloadInventory={() => this.retrieveInventory()}
            selectedBrandId={this.props.selectedbrandID}
            isEdit={this.state.isEdit}
            inventoryData={this.state.rowInventory}
            devices={this.state.devices}
          />
          {/* </form> */}
        </Modal>
        <Modal
          isOpen={this.state.showHistory}
          //onRequestClose={()=>this.setState({showDeclineModal: false})}
          contentLabel={"Part usage history"}
          className={"input-modal"}
          // className={"confirm-modal confirm-modal-big warranty-decline-modal"}
        >
          <div className="card">
            <div className="card-header">
              <b>Part Usage History</b>
            </div>
            <div className="card-body" style={{ maxHeight: "500px", overflow: "auto" }}>
              <div className="row">
                <div className="col-4">{"Material Number :"}</div>
                <div className="col">{this.state.rowInventory?.material_number}</div>
              </div>
              <div className="row">
                <div className="col-4">{"Part Name :"}</div>
                <div className="col">{this.state.rowInventory?.spare_part_name}</div>
              </div>

              <table className="table mt-2">
                <thead>
                  <tr>
                    {/* <th>type</th> */}
                    <th>Quantity</th>
                    <th>Notes</th>
                    <th>Used date</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.history?.length > 0 ? (
                    this.state.history.map((row) => {
                      return (
                        <tr key={row.id}>
                          {/* <td>{row.type}</td> */}
                          <td>{row.quantity}</td>
                          <td>{row.notes}</td>
                          <td>{moment(row.created).format("DD MMM YYYY HH:mm")}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">
                        No History found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="card-footer">
              <button className="btn btn-outline-secondary" onClick={() => this.setState({ showHistory: false })}>
                Close
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
