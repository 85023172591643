import React, { Component } from "react";
import config from "../../../../../config";
import errorFunctions from "../../../../../store/errorFunctions";
import { IoMdAddCircle, IoMdRemoveCircle } from "react-icons/io";
import Select from "react-select";
import moment from "moment";

const repairLeveOption = [
  { value: "level_0", label: "Level 0" },
  { value: "level_1", label: "Level 1" },
  { value: "level_2", label: "Level 2" },
  { value: "level_3", label: "Level 3" },
];
export default class RepairReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      actionIndex: 0,
      repair_details: this.props.reportContent?.repair_details || "",
      repair_level: this.props.reportContent?.repair_level || "level_0",
      technician: this.props.reportContent?.technician || 0,
      repair_parts: this.props.reportContent?.repair_parts || [],
      deviceName: "",
      sparePartName: "",
      addedParts: [],
      deletedParts: [],
      quantity: 1,
      is_repurposed: false,
      sparePartOptions: [],
    };
  }

  componentDidMount() {
    this.retriveInventory(this.props.device.id);
  }

  handleSubmit = (e) => {
    this.setState({ loading: true });
    e.preventDefault();
    console.log(this.state.addComment);
    if (!this.state.quantity) {
      alert("Quantity must not be empty. ");
      return;
    }

    const repairPartAddPayload = this.state.addedParts.map((part) => {
      return {
        part_id: null,
        inventory: part.inventory,
        quantity: part.quantity,
        is_repurposed: !!part.is_repurposed,
        action: "add",
      };
    });
    const repairPartDelPayload =
      this.state.deletedParts.length > 0
        ? this.state.deletedParts.map((part) => {
            return {
              part_id: part.part_id,
              inventory: part.inventory,
              quantity: part.quantity,
              is_repurposed: !!part.is_repurposed,
              action: "delete",
            };
          })
        : [];

    const repairPartPayload = [...repairPartAddPayload, ...repairPartDelPayload];
    if (!repairPartPayload?.length) {
      alert("Repair part must be added first");
      return;
    }
    const payload = {
      repair_details: this.state.repair_details,
      repair_level: this.state.repair_level,
      technician: this.state.technician,
      repair_parts: repairPartPayload,
    };

    const url =
      this.props.reportMode === "new"
        ? `${config.backendServer}ticket/${this.props.ticketId}/repair-reports/new/`
        : `${config.backendServer}ticket/repair-reports/${this.props.reportContent.id}/`;

    this.props
      .protectedFetch(url, this.props.reportMode === "new" ? "POST" : "PATCH", payload, errorFunctions.checkResponseSent)
      .then(() => {
        this.setState({
          summary_of_issue: "",
          received_items: "",
          technician: 0,
          loading: false,
        });
        this.props.retrieveWarranty();
        this.props.closeModal();
        this.props.setDefaultTab();

        // this.retrieveWarranty(dataToSend.id);
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
        this.props.closeModal();
      });
  };

  retriveInventory(deviceId) {
    //inventories/?brandId=6&device_id=2
    this.props
      .protectedFetch(
        config.backendServer + "inventories/?brandId=" + this.props.selectedbrandID + "&device_id=" + deviceId,
        "GET",
        null,
        errorFunctions.checkResponseThenJson
      )
      .then((res) => {
        let sparePartOptions = [];
        if (res.results.length > 0) {
          sparePartOptions = res.results.map((sp) => {
            return {
              value: sp.id,
              label: sp.material_number + " | " + sp.spare_part_name,
            };
          });
        }

        this.setState({
          isLoading: false,
          sparePartOptions: sparePartOptions,
        });
      });
  }
  handleInputChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });

    if (id === "device") {
      this.retriveInventory(value);
      const name = this.props.devices?.find((x) => x.id.toString() === value)?.name;
      this.setState({ deviceName: name });
    }

    if (id === "is_repurposed") {
      this.setState({ is_repurposed: e.target.checked });
    }
  };

  handleInventoryChange = (obj) => {
    // this.retriveInventory(value);
    if (obj) {
      const val = this.state.sparePartOptions?.find((x) => x.value === obj.value);
      this.setState({ selectedSparePart: val, inventory: obj.value });
    }
  };

  handleTechnicianChange = (obj) => {
    // this.retriveInventory(value);
    if (obj) {
      this.setState({ technician: obj.value });
    }
  };

  handleRepairChange = (obj) => {
    // this.retriveInventory(value);
    if (obj) {
      this.setState({ repair_level: obj.value });
    }
  };

  handleAddPart = () => {
    // if (!this.state.selectedSparePart) return;
    if (!this.state.selectedSparePart) {
      alert("Spare part must not be empty. ");
      return;
    }
    if (!this.state.quantity) {
      alert("Quantity must not be empty. ");
      return;
    }
    const checkAdd = this.state.addedParts.find((x) => x.inventory === this.state.selectedSparePart.value);
    const checkExisting = this.state.repair_parts.find((x) => x.inventory === this.state.selectedSparePart.value);
    if (checkAdd || checkExisting) {
      alert("Can not added this part. Spare part already added previously. ");
      return;
    }

    const repairPart = {
      inventory: parseInt(this.state.selectedSparePart.value),
      spare_part_name: this.state.selectedSparePart.label,
      quantity: this.state.quantity,
      is_repurposed: this.state.is_repurposed,
      technician: this.state.technician,
      part_id: 100000 + Math.floor(Math.random() * 100).toString(),
    };
    const addedParts = [...this.state.addedParts, repairPart];
    const repairParts = [...this.state.repair_parts, repairPart];
    this.setState({
      repair_parts: repairParts,
      addedParts,
      inventory: "",
      quantity: 1,
      device: "",
      is_repurposed: false,
      selectedSparePart: null,
      error: null,
    });
  };

  handleDeletePart = (item) => {
    if (this.props.reportMode !== "view") {
      const resp = window.confirm("Do you really want to delete  " + item.spare_part_name);
      const repairPartTemp = this.state.repair_parts.filter((x) => x.part_id !== item.part_id);
      const deletedRepairPart = this.state.repair_parts.find((x) => x.part_id < 100000 && x.part_id === item.part_id);
      const addedParts = this.state.addedParts.filter((x) => x.part_id !== item.part_id);

      const deletedRepairParts = deletedRepairPart ? [...this.state.deletedParts, deletedRepairPart] : [...this.state.deletedParts];
      if (resp)
        this.setState({
          repair_parts: repairPartTemp,
          addedParts: addedParts,
          deletedParts: deletedRepairParts,
        });
    }
  };

  render() {
    const technicianOption = this.props.employeeList?.map((val) => {
      return { value: val.id, label: val.name };
    });
    const selectedTechnician = technicianOption.find((x) => x.value === this.state.technician);
    const selectedRepairLevel = repairLeveOption.find((x) => x.value === this.state.repair_level);
    return (
      <div className="card">
        <form onSubmit={this.handleSubmit}>
          <div className="card-header">
            <h5>Repair Device </h5>
          </div>
          <div className="card-body" style={{ width: "800px", height: "60vh", overflow: "auto" }}>
            <div className="mb-3 border rounded p-3">
              <div className="mb-3">
                <b>{`Device name: ${this.props.device.name}`}</b>
              </div>
              <b>Repair Detail</b>

              <div>
                <textarea
                  id="repair_details"
                  className="form-control"
                  disabled={this.props.reportMode === "view"}
                  rows={4}
                  style={{ width: "100%" }}
                  onChange={this.handleInputChange}
                  value={this.state.repair_details}
                  required
                />
              </div>
            </div>
            <div className="m-2">
              <b>Repair Part Used</b>
              <div className="mb-4 ml-2 ">
                <div className="row border">
                  {/* <div className="col-3 text-center ">Model</div> */}
                  <div className="col-6 text-center ">Material Number</div>
                  <div className="col-1 text-center ">Qty</div>
                  <div className="col-2 text-center ">Repurposed</div>
                  <div className="col-2 text-center ">Date</div>
                  {/* <div className="col-1 text-right "></div> */}
                </div>
                {this.props.reportMode !== "view" && (
                  <div className="row border p-1">
                    <div className="col-5" style={{ zIndex: 999 }}>
                      <Select
                        data-testid="assign-to-select"
                        id="inventory"
                        value={this.state.selectedSparePart}
                        options={this.state.sparePartOptions}
                        isSearchable={true}
                        onChange={(val) => this.handleInventoryChange(val)}
                        isDisabled={this.props.reportMode === "view"}
                      />
                    </div>
                    <div className="col-2">
                      <input
                        disabled={this.props.reportMode === "view"}
                        id="quantity"
                        className="form-control"
                        style={{ width: "100%", textAlign: "right" }}
                        onChange={this.handleInputChange}
                        value={this.state.quantity}
                      />
                    </div>
                    <div className="col-2 mt-2">
                      <input
                        disabled={this.props.reportMode === "view"}
                        type="checkbox"
                        id="is_repurposed"
                        className="form-check"
                        style={{ width: "100%", textAlign: "right" }}
                        onChange={this.handleInputChange}
                        checked={this.state.is_repurposed}
                      />
                    </div>
                    <div className="col-2">{moment(Date()).format("DD-MM-YYYY")}</div>
                    <div className="col-1">
                      <button className="btn btn-light" type="button" onClick={this.handleAddPart}>
                        <IoMdAddCircle title="Add inventory item" style={{ color: "green", cursor: "pointer" }} />
                      </button>
                    </div>
                  </div>
                )}

                {this.state.repair_parts.map((item, i) => {
                  return (
                    <div className="row p-1 border" key={i}>
                      <div className="col-6 ">{item.spare_part_name}</div>
                      <div className="col-1" style={{ textAlign: "right" }}>
                        {item.quantity}
                      </div>
                      <div className="col-2" style={{ textAlign: "center" }}>
                        {item.is_repurposed ? "Yes" : "No"}
                      </div>
                      <div className="col-2" style={{ textAlign: "center" }}>
                        {moment(item.created).format("DD-MM-YYYY")}
                      </div>
                      <div className="col-1">
                        {this.props.reportMode !== "view" && (
                          <button
                            className="btn btn-light"
                            type="button"
                            onClick={() => {
                              this.handleDeletePart(item);
                            }}
                          >
                            <IoMdRemoveCircle title="Delete Part" style={{ color: "red", cursor: "pointer" }} />
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="mb-3 border rounded p-3">
              <h6>
                <b>Worked Performed</b>
              </h6>
              <div className={"single-warranty-details-item mb-3"} style={{ zIndex: 999 }}>
                <span className={"single-warranty-details-label"}>Repair Level:</span>
                <Select
                  className="z-3"
                  data-testid="assign-to-select"
                  value={selectedRepairLevel}
                  options={repairLeveOption}
                  onChange={(obj) => {
                    if (this.props.reportMode !== "view") this.handleRepairChange(obj);
                  }}
                  isDisabled={this.props.reportMode === "view"}
                  required
                />

                <span className={"single-warranty-details-label"}>Technician:</span>
                <Select
                  data-testid="assign-to-select"
                  value={selectedTechnician}
                  isSearchable={true}
                  options={technicianOption}
                  onChange={(obj) => {
                    if (this.props.reportMode !== "view") this.handleTechnicianChange(obj);
                  }}
                  isDisabled={this.props.reportMode === "view"}
                  required
                />
              </div>
            </div>
          </div>
          <div className="card-footer">
            {this.props.reportMode !== "view" && (
              <button type="submit" className="btn btn-outline-primary m-2">
                Save
              </button>
            )}
            <button onClick={() => this.props.closeModal()} className="btn btn-outline-secondary m-2">
              Close
            </button>
          </div>
        </form>
      </div>
    );
  }
}
