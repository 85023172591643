import React, { Component } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import StepContent from "@mui/material/StepContent";
import moment from "moment";
import "./Stepper.css";

export default class TicketProcess extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Box sx={{ maxWidth: 400 }}>
        <Stepper orientation="vertical" activeStep={this.props.statuses?.length}>
          {this.props.statuses?.map((step, index) => (
            <Step
              key={index}
              expanded
              sx={{
                "& .MuiStepLabel-root .Mui-completed": {
                  color: index === this.props.statuses?.length - 1 ? "black" : "grey.500", // circle color (COMPLETED)
                },
              }}
            >
              <StepLabel>
                <Typography sx={{ fontSize: "0.9rem" }}>{step.status}</Typography>
              </StepLabel>
              <StepContent
                sx={{
                  color: index === this.props.statuses?.length - 1 ? "black" : "grey.500",
                  fontSize: "0.8rem", // circle color (COMPLETED)
                }}
              >
                <small>{moment(step.created).format("DD MMMM YYYY")}</small>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    );
  }
}
