import React, { Component } from "react";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import moment from "moment";
import config from "../../../../config";
import axios from "axios";
import ActivityItem from "./ActivityItem";
import NoteItem from "./NoteItem";
import DatePicker from "react-datepicker";

// import utilityFunctions from "../../../../store/utilityFunctions";
import errorFunctions from "../../../../store/errorFunctions";
// import config from "../../../../config";
// const userInfo = utilityFunctions.getCookie("userInfo");
// const userRole = JSON.parse(userInfo).user.role;
Modal.setAppElement("body");
export default class Activities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditNote: false,
      showAddNote: false,
      showAddMedia: false,
      showActivities: true,
      showNotes: true,
      editedNoteId: "",
      addedNote: "",
      editedNote: "",
      mediaTypeWarning: "",
      showDueDateChangeModal: false,
    };
  }

  handleSubmitNote = () => {
    this.setState({
      disableBtnNote: true,
      showAddNote: false,
      showDueDateChangeModal: true,
      dueDateType: "note",
    });
    // this.changeDueDate(true);
  };
  handleSubmitMediaNote = () => {
    this.setState({
      mediaTypeWarning: "",
      showAddMedia: false,
      showDueDateChangeModal: true,
      dueDateType: "media",
    });
  };
  handleInputChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });
  };
  handleFileUpload = (e) => {
    // TODO - verify file upload to make sure it's good
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "video/mp4", "video/webm", "video/quicktime"];

    if (!allowedTypes.includes(file.type)) {
      alert("Only JPG, JPEG, PNG, WEBM , MOV and MP4 files are allowed.");
      this.setState({ allowUpload: false, mediaTypeWarning: "Only JPG, JPEG, PNG, WEBM, MOV and MP4  files are allowed." });
    } else {
      this.setState({ allowUpload: true });
    }
    const media = document.getElementById("uploadNoteMedia").files[0];
    this.setState({ mediaNotes: media, dueDateType: "media", fileName: file.name });
  };

  handleAddNote = () => {
    let formData = new FormData();
    formData.append("note", this.state.addedNote);
    this.addNote(formData);
  };

  addNote(formData) {
    // const userInfo = localStorage.getItem("lgn") || utilityFunctions.getCookie("userInfo");
    const token = this.props.userInfo.access_token;
    let options = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "multipart/form-data",
      },
    };

    const url = this.state.editedNoteId
      ? `${config.backendServer}ticket/${this.props.details.id}/note/${this.state.editedNoteId}/`
      : `${config.backendServer}ticket/${this.props.details.id}/note/`;
    axios
      .put(url, formData, options)
      .then(() => {
        this.setState({
          addedNote: "",
          editedNoteId: "",
          editedNote: null,
          showAddNote: false,
          showAddMedia: false,
          disableBtnNote: false,
          isLoading: false,
        });
        this.props.retrieveWarranty();
      })
      .catch((error) => {
        // Handle the error or show an error message
        if (error)
          alert(error?.response?.data?.non_field_errors?.[0] || error?.response?.data?.serial_number?.[0] || "Something went wrong.. try later");
        this.setState({ isLoading: false });
      });
  }

  handleAddMedia = () => {
    this.setState({ loading: true, showAddMedia: false });
    // e.preventDefault();
    if (this.state.allowUpload && this.state.mediaNotes) {
      // const mediaNotes = document.getElementById("uploadNoteMedia").files[0];
      let formData = new FormData();
      formData.append("media", this.state.mediaNotes);
      formData.append("note", this.state.mediaNotes.type);
      this.addNote(formData);
    } else {
      // alert("Only JPG, JPEG, PNG, WEBM, MOV and MP4  files are allowed.");
      this.setState({ loading: false, showAddMedia: true });
    }
  };
  changeDueDate(note) {
    this.setState({ disableBtnDueDate: true });
    if (!this.state.showDueDateModal && !note) return;
    if (!this.state.showDueDateChangeModal && note) return;

    const data = {
      reason: "",
      due_date: moment(this.state.due_date).format("YYYY-MM-DD"),
    };

    const url = `${config.backendServer}ticket/${this.props.details.id}/set-due-date/`;
    this.props
      .protectedFetch(url, "PUT", data, errorFunctions.checkResponseThenJson)
      .then(() => {
        if (this.state.dueDateType === "note") this.handleAddNote();
        else if (this.state.dueDateType === "media") this.handleAddMedia();
        this.setState({
          dueDateReason: "",
          showDueDateModal: false,
          showDueDateChangeModal: false,
          disableBtnDueDate: false,
        });
        this.props.retrieveWarranty(this.ticketId);
      })
      .catch((error) => {
        // Handle the error or show an error message
        alert(error?.response?.data?.non_field_errors?.[0] || error?.response?.data?.serial_number?.[0] || "Something went wrong.. try later");
        this.setState({ isLoading: false, showDueDateModal: false });
      });
  }
  componentDidMount() {
    Modal.setAppElement("body");
  }

  render() {
    const details = this.props.details || {};

    let that = this;
    let history = details.ticket_activities;
    if (this.state.showNotes && this.state.showActivities) history = details.ticket_activities;
    else if (!this.state.showNotes && !this.state.showActivities) history = [];
    else if (this.state.showNotes) history = details.ticket_activities.filter((d) => d.subject.includes("Note by"));
    else if (this.state.showActivities) history = details.ticket_activities.filter((d) => !d.subject.includes("Note by"));

    return (
      <div>
        <div className="d-flex flex-row">
          <div className="p-2">
            {" "}
            <button
              type="button"
              className="btn btn-outline-primary btn-sm"
              onClick={() => {
                this.setState({
                  showAddNote: true,
                  editedNoteId: "",
                  editedNote: null,
                });
              }}
            >
              Add Note
            </button>
          </div>
          <div className="p-2">
            <button
              type="button"
              className="btn btn-outline-primary mr-3 btn-sm"
              onClick={() => {
                this.setState({
                  showAddMedia: true,
                  editedNoteId: "",
                  editedNote: null,
                });
              }}
            >
              Add Picture/Video
            </button>
          </div>
          <div className="p-2" style={{ width: "auto", right: "20px", position: "absolute" }}>
            <p className="float-right ml-2">
              <input
                type="checkbox"
                id="note"
                data-testid="note"
                name="notes"
                value={this.state.showNotes}
                checked={this.state.showNotes}
                onChange={() => (this.state.showNotes ? this.setState({ showNotes: false }) : this.setState({ showNotes: true }))}
              />
              <label htmlFor="vehicle1" className="ml-2">
                {" "}
                Notes
              </label>
            </p>
            <p className="float-right ml-2">
              <input
                type="checkbox"
                id="change"
                data-testid="change"
                name="vehicle1"
                value={this.state.showActivities}
                checked={this.state.showActivities}
                onChange={() => (this.state.showActivities ? this.setState({ showActivities: false }) : this.setState({ showActivities: true }))}
              />
              <label htmlFor="change" className="ml-2">
                {" "}
                Changes
              </label>
            </p>
          </div>
        </div>
        <div className={"activity-wrapper"}>
          {/* <h4 className={"single-warranty-header"}>History and comments</h4> */}
          <ul style={{ padding: "0px" }}>
            {history
              ?.sort((a, b) => {
                return b.id - a.id;
              })
              .map(function (item, i) {
                const isNote = item.subject.includes("Note by");
                const isMyNote = item.subject.includes(that.props.userInfo?.user.name);

                const isPicture =
                  item.media &&
                  (item.media.toLowerCase().includes(".jpg") ||
                    item.media.toLowerCase().includes(".png") ||
                    item.media.toLowerCase().includes(".jpeg"));
                const isVideo =
                  item.media &&
                  (item.media.toLowerCase().includes(".mp4") ||
                    item.media.toLowerCase().includes(".mov") ||
                    item.media.toLowerCase().includes(".webm"));
                if (isNote)
                  return (
                    <NoteItem
                      userInfo={that.props?.userInfo}
                      activity={item}
                      isMyNote={isMyNote}
                      isPicture={isPicture}
                      isVideo={isVideo}
                      key={i}
                      handleEditNote={(formData) => that.addNote(formData)}
                      ticketId={that.props.details.id}
                      retrieveWarranty={() => that.props.retrieveWarranty()}
                      // handleShowAddNote={() => that.setState({ showAddNote: true, editedNote: item.detail, editedNoteId: item.id })}
                      // handleShowAddMedia={() =>
                      //   that.setState({ showAddMedia: true, mediaNotes: item.media, editedNoteId: item.id, editedNote: item.detail })
                      // }
                    />
                  );
                else return <ActivityItem activity={item} key={i} />;
              })}
          </ul>
        </div>
        <Modal
          isOpen={this.state.showAddNote}
          //onRequestClose={()=>this.setState({showDeclineModal: false})}
          contentLabel={"Add Note"}
          className={"input-modal"}
        >
          <div className="card">
            <button onClick={() => this.setState({ showAddNote: false })} className={"confirm-modal-close"}>
              <MdClose />
            </button>
            <div className={"card-header"}>{`${this.state.editedNote ? "Edit" : "Add"} Note`}</div>
            <div className="card-body">
              <textarea
                className="form-control w-100"
                placeholder="Add Note"
                rows="3"
                required={true}
                onChange={this.handleInputChange}
                id={"addedNote"}
                data-testid={"addedNote"}
                value={this.state.addedNote || this.state.editedNote || ""}
              />
            </div>
            <div className="card-footer">
              <button disabled={this.state.disableBtnNote} type="button" className={"btn btn-outline-primary mr-3"} onClick={this.handleSubmitNote}>
                Save
              </button>
              <button type={"button"} className={"btn btn-outline-secondary"} onClick={() => this.setState({ showAddNote: false, addedNote: "" })}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showAddMedia}
          //onRequestClose={()=>this.setState({showDeclineModal: false})}
          contentLabel={"Add Note"}
          className={"input-modal"}
        >
          <div className="card">
            <button onClick={() => this.setState({ showAddMedia: false })} className={"confirm-modal-close"}>
              <MdClose />
            </button>
            <div className="card-header">{`${this.state.editedNote ? "Edit" : "Add"} Picture / Video`}</div>
            <div className={"card-body"}>
              <div
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  width: "100%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {this.state.editedNote}
              </div>
              <div className="border p-0 " style={{ borderRadius: "8px" }}>
                <label htmlFor="uploadNoteMedia" className="custom-file-upload-outline half-radius">
                  Choose File
                </label>
                <span>{`  ${this.state.fileName ? this.state.fileName : "No file choosen"}`}</span>
                <input
                  accept="image/jpeg, image/png, video/mp4, video/webm, video/quicktime"
                  type={"file"}
                  id={"uploadNoteMedia"}
                  data-testid="uploadNoteMedia"
                  // className="custom-file-upload"
                  required={true}
                  onChange={this.handleFileUpload}
                />
              </div>

              <div className="text-danger">
                <small>{this.state.mediaTypeWarning}</small>
              </div>
            </div>
            <div className="card-footer">
              <button
                data-testid={"saveNoteMedia"}
                disabled={this.state.disableBtnNote}
                type="submit"
                className={"btn btn-outline-primary mr-3"}
                onClick={() => this.state.allowUpload && this.handleSubmitMediaNote()}
              >
                Save
              </button>
              <button type={"button"} className={"btn btn-outline-secondary"} onClick={() => this.setState({ showAddMedia: false, addedNote: "" })}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showDueDateChangeModal}
          //onRequestClose={()=>this.setState({showDeclineModal: false})}
          contentLabel={"Add Note"}
          className={"input-modal"}
        >
          <div className="card">
            <button onClick={() => this.setState({ showDueDateChangeModal: false })} className={"confirm-modal-close"}>
              <MdClose />
            </button>
            <div className="card-header">Change Due date </div>
            <div className="card-body">
              <div className={"form-field"}>
                <span style={{ marginRight: "15px" }}>Due date : </span>
                <DatePicker
                  id="due-field-datepicker"
                  selected={this.state.due_date}
                  dateFormat="dd/MM/yyyy"
                  onChange={(val) => {
                    this.setState({
                      due_date: val,
                    });
                    // this.changeDueDate()
                  }}
                  style={{ width: "50vw !important" }}
                />
              </div>
            </div>
            <div className="card-footer">
              <button
                disabled={this.state.disableBtnDueDate}
                type="submit"
                className={"btn btn-outline-primary mr-3"}
                onClick={() => this.changeDueDate(true)}
              >
                Save
              </button>
              <button type={"button"} className={"btn btn-outline-secondary"} onClick={() => this.setState({ showDueDateChangeModal: false })}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
