import React, { Component } from "react";
import moment from "moment";
export default class ActivityItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div key={this.props.activity.id} className="card mb-2" data-testid={`activity-${this.props.activity.id}`}>
        <div style={{ padding: "3px", margin: "1px" }} className={"card-ticket-title row rounded-top"}>
          <div className="col-8">{this.props.activity.subject}</div>
        </div>
        <div className="card-body">
          <p className="card-text">{this.props.activity.detail}</p>
        </div>
        <div className="card-footer">
          <small>
            <div className="d-flex justify-content-between">
              <span>{moment(this.props.activity.created).format("DD MMMM YYYY HH:mm")}</span>
              {/* <span>{this.props.activity.created_by && `by ${this.props.activity.created_by}`}</span> */}
            </div>
          </small>
        </div>
      </div>
    );
  }
}
