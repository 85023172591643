import React, { Component } from "react";
import Modal from "react-modal";
import "../common.css";
import { MdClose } from "react-icons/md";
import config from "../../config";
// import errorFunctions from "../../store/errorFunctions";
import utilityFunctions from "../../store/utilityFunctions";
import axios from "axios";
// import Select from "react-select";
// import { postNewStaffFunction } from "./api";

Modal.setAppElement("body");

export default class ServiceCenterAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      redirect: false,
      statusTicket: "",
      modalStatusConfirm: false,
      brands: [],
      roles: [],
      currentRole: "",
      id: this.props?.serviceCenter?.id || "",
      email: this.props.serviceCenter?.email || "",
      name: this.props.serviceCenter?.name || "",
      contact_number: this.props.serviceCenter?.contact_number || "",
      address: this.props.serviceCenter?.address || "",
      postError: "",
    };
  }

  // componentDidMount() {
  //   this.searchLocationState();
  // }

  postNewServiceCenter = (payload) => {
    const userInfo = localStorage?.getItem("userInfo") || utilityFunctions?.getCookie("userInfo");
    const token = (userInfo && JSON?.parse(userInfo)?.access_token) || "";
    let options = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    return new Promise((resolve) => {
      axios
        .post(`${config.backendServer}service_center_central/${this.props.serviceCenterCentralId}/location/`, payload, options)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };

  putNewServiceCenter = (payload) => {
    const userInfo = localStorage?.getItem("userInfo") || utilityFunctions?.getCookie("userInfo");
    const token = (userInfo && JSON?.parse(userInfo)?.access_token) || "";
    let options = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    return new Promise((resolve) => {
      axios
        .patch(`${config.backendServer}service_center/${this.props.serviceCenter.id}/`, payload, options)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };

  handleSubmit = async (event) => {
    this.setState({ loading: true });
    event.preventDefault();

    let payload = {
      name: this.state.name,
      address: this.state.address,
      contact_number: this.state.contact_number,
      email: this.state.email,
    };

    const result = this.props.serviceCenter ? await this.putNewServiceCenter(payload) : await this.postNewServiceCenter(payload);
    if (result.status === 200 || result.status === 201) {
      this.setState({ loading: false });
      this.props.handleCloseModal();
      this.props.handleRetriveServiceCenterList();
    } else {
      this.setState({
        loading: false,
        postError: JSON.stringify(result?.response?.data),
      });
    }
  };

  handleRole = (e) => {
    this.setState({ currentRole: e.target.value });
  };
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  render() {
    return (
      <div className={"card"} style={{ height: "100%" }}>
        <button onClick={() => this.props.handleCloseModal()} className={"confirm-modal-close"}>
          <MdClose />
        </button>

        <h4 className={"card-header"}>{this.state.name ? "Edit Service Center" : "Add Service Center"}</h4>
        <form onSubmit={this.handleSubmit}>
          <div className="card-body m-3">
            <div className="mb-3">
              <label className={"single-warranty-details-label "} htmlFor="name">
                Name*
              </label>
              <input type="text" id="name" required={true} className="form-control" onChange={this.handleChange} value={this.state.name} />
            </div>
            <div className="mb-3">
              <label className={"single-warranty-details-label"}>Address*</label>
              <textarea id="address" required={false} className="form-control" onChange={this.handleChange} value={this.state.address} />
            </div>
            <div className="mb-3">
              <label className={"single-warranty-details-label"}>Phone Number*</label>
              <input
                type="tel"
                id="contact_number"
                //  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                className="form-control"
                onChange={this.handleChange}
                required
                value={this.state.contact_number}
              />
            </div>
            <div className="mb-3">
              <label className={"single-warranty-details-label"}>Email*</label>
              <input type="email" id="email" required={true} className="form-control" onChange={this.handleChange} value={this.state.email} />
            </div>
          </div>
          <div className="card-footer ">
            <div className="d-flex justify-content-start gap-2">
              {/* <span className={"mb-1 text-danger"}>{this.state.postError}</span> */}
              <button className={"btn btn-outline-primary"}>Submit</button>
              <button className={"btn btn-outline-secondary"} onClick={this.props.handleCloseModal}>
                Close
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
