const baseUrl = process.env.REACT_APP_BASE_URL || "staging.warrantyservices.com.au";
const country = process.env.REACT_APP_COUNTRY || "AU";

const apiUrl = `https://${baseUrl}/api/`;
const config = {
  backendServer: apiUrl,
  country: country,
};

export default config;
[];
