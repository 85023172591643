import React, { Component } from "react";
import { withRouter } from "react-router";
import { Table as MaterialTable } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import config from "../../config";
import utilityFunctions from "../../store/utilityFunctions";

import "./Table.css";
import Modal from "react-modal";
import "./Table.css";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{
                lineHeight: "1rem",
                boxSizing: "border-box",
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                style={{
                  fontSize: "12px",
                  color: "#777777",
                }}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

class Table extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: "desc",
      orderBy: "id",
      page: 0,
      rowsPerPage: 15,
      showModal: false,
      modalStatus: false,
      currentRow: undefined,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({ page: 0 });
    }
  }
  viewProfile(deviceId) {
    const { history } = this.props;
    history.push("/device-detail/" + deviceId);
  }

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };
  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  handleDropDown = (e, id) => {
    let ticketID = id;
    let employeeID = e.target.value;
    this.setState({ showModal: true, employeeID, ticketID });
  };

  handleCancel = () => {
    // this.props.reloadMini(this.props.selectedbrandID);
    this.setState({
      showModal: false,
    });
  };

  handleButtonDelete = (item) => {
    // this.props.reloadMini(this.props.selectedbrandID);
    this.setState({
      showModal: true,
      currentRow: item,
    });
  };
  handleDeleteDevice = () => {
    const userInfo = localStorage.getItem("userInfo") || utilityFunctions.getCookie("userInfo");

    //state jika di refresh akan kembali null, jadi ambil data dari localstorage atau cookies solusinya
    const token = userInfo?.access_token || JSON.parse(userInfo)?.access_token;
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const deviceToDelete = this.state.currentRow.id; // Storing array id in showDeleteModal
    this.setState({ loading: true, showDeleteModal: false });
    const url = config.backendServer + "device/" + deviceToDelete + "/";

    fetch(url, options)
      .then((resp) => {
        if (resp.status === 403) alert("Cannot delete device. Device is already registered by at least one user(s)");
        else if (resp.status === 204) alert("Delete success");
        else alert("status:" + resp.status);

        this.setState({ showModal: false });
        this.props.reloadDevice();
      })
      .catch((err) => {
        alert(err);
        this.setState({ showModal: false });
      });
  };

  render() {
    const rows = this.props.data;
    const headCells = this.props.head;
    Modal.setAppElement("body");

    return (
      <div>
        {/* {rows.length === 0 && <div>No Data</div>} */}
        <TableContainer>
          <MaterialTable aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
            <EnhancedTableHead
              order={this.state.order}
              orderBy={this.state.orderBy}
              onRequestSort={this.handleRequestSort}
              headCells={this.props.head}
              selectStatus={this.props.statusSelectors}
              filterStatus={this.props.filteredStatus}
            />
            <TableBody>
              {stableSort(rows, getComparator(this.state.order, this.state.orderBy))
                .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  // const isStockLow = parseInt(row["quantity"]) <= 5;
                  return (
                    <TableRow
                      tabIndex={-1}
                      key={labelId}
                      // className={isStockLow ? "over-due-date" : "in-due-date"}
                      style={{ cursor: "pointer" }}
                    >
                      {/* Loop through head to get data order */}
                      {headCells.map((item, i) => {
                        if (item.id === "id") {
                          return (
                            <TableCell
                              key={i}
                              style={{
                                width: "10vw",
                                wordWrap: "break-word",
                                // cursor: "pointer",
                              }}
                              onClick={() => this.viewProfile(row.id)}
                            >
                              {item?.valueDisplay(row[item?.id])}
                            </TableCell>
                          );
                        } else if (item.id === "action") {
                          return (
                            <TableCell key={i} style={{ width: "20px" }}>
                              <button onClick={() => this.handleButtonDelete(row)} className={"btn btn-outline-danger btn-sm"}>
                                Delete
                              </button>
                            </TableCell>
                          );
                        } else
                          return (
                            <TableCell
                              key={i}
                              onClick={() => this.viewProfile(row.id)}
                              style={
                                item?.width
                                  ? {
                                      maxWidth: item?.width,
                                      wordWrap: "break-word",
                                    }
                                  : {
                                      maxWidth: "120px",
                                      wordWrap: "break-word",
                                    }
                              }
                            >
                              {item?.valueDisplay(row[item?.id])}
                            </TableCell>
                          );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </MaterialTable>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />

        {/* Modal for confirm status ticket type */}
        <Modal isOpen={this.state.showModal} contentLabel={"Set as Out of Device"} className={"confirm-modal"}>
          <div className="card">
            <div className="card-header">Delete</div>

            <div className={"card-body"}>
              <div className="card-title">Are you sure want to delete Device {this.state.currentRow?.name}</div>
            </div>
            <div className="card-footer">
              {" "}
              <button type="button" className={"btn btn-outline-danger mr-3"} onClick={() => this.handleDeleteDevice()}>
                Delete
              </button>
              <button type={"button"} className={"btn btn-outline-secondary "} onClick={this.handleCancel}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
export default withRouter(Table);
