import React, { Component } from "react";
import "./PageWithNavbar.css";
import Navbar from "../../components/Navbar/Navbar";
//import PageFooter from "../PageFooter";

export default class PageWithNavbar extends Component {
  render() {
    return (
      <div className="fullPageContainer">
        <Navbar
          userInfo={this.props.userInfo}
          logout={this.props.logout}
          isSuper={this.props.isSuper}
          protectedFetch={this.props.protectedFetch}
          handleSelectedBrandID={this.props.handleSelectedBrandID}
        />
        <div className="fullPageChildrenHolder">{this.props.children}</div>

        {/*<PageFooter/>*/}
      </div>
    );
  }
}
