import React, { Component } from "react";
import Modal from "react-modal";
import { Redirect } from "react-router-dom";
import "../../common.css";

import LoadingPage from "../../../components/Loading/Loading";
import config from "../../../config";
import errorFunctions from "../../../store/errorFunctions";
import "./StaffDetail.css";
Modal.setAppElement("body");

export default class SingleWarranty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      details: null,
      id: null,
      showApproveModal: false,
      showDeclineModal: false,
      addComment: "",
      startPostageNumber: "",
      endPostageNumber: "",
      showDeleteModal: false,
      redirect: false,
      showOutOfWarrantyModal: false,
      showAddNote: false,
      addedNote: "",
      employee: null,
      ticketID: null,
      employeeID: null,
      showAssignModal: false,
      statusTicket: "",
      modalStatusConfirm: false,
      brands: [],
      roles: [],
      currentRole: "",
      name: "",
      email: "",
    };
  }

  componentDidMount() {
    const id = this.props.computedMatch.params.id;
    this.setState({ id });
    this.retriveDetailStaff(id);
    this.retriveAllBrand();
    this.retriveAllRoles();
  }
  retriveDetailStaff = (id) => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + "employee/" + id + "/", "GET", { id }, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({ loading: false, details: res, currentRole: res.user_role });
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
  };

  retriveAllBrand = () => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + "brands/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({ loading: false, brands: res });
      })
      .catch(() => {
        console.log("fail brand");
        this.setState({ loading: false });
      });
  };

  retriveAllRoles = () => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + "roles/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({ loading: false, roles: res });
      })
      .catch(() => {
        console.log("fail brand");
        this.setState({ loading: false });
      });
  };

  handleSubmit = () => {
    this.setState({ loading: true });

    const id = this.props.computedMatch.params.id;
    const selectedBrandIds = Array.from(document.querySelectorAll("input[type='checkbox']:checked")).map((checkbox) => parseInt(checkbox.value));

    let payload = {
      brands: selectedBrandIds,
      name: this.state.name ? this.state.name : this.state.details.name,
      email: this.state.email ? this.state.email : this.state.details.email,
      user_role: this.state.currentRole ? this.state.currentRole : this.state.details.user_role,
    };

    this.props
      .protectedFetch(config.backendServer + `employee/${id}/`, "PUT", payload, errorFunctions.checkResponseThenJson)
      .then(() => {
        this.setState({
          name: "",
          email: "",
          currentRole: "",
          loading: false,
          redirect: true,
        });
        this.retriveDetailStaff(id); //mini reload
      })
      .catch(() => {
        console.log("fail");
      });
  };
  handleRole = (e) => {
    this.setState({ currentRole: e.target.value });
  };
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  render() {
    if (this.state.redirect) {
      // Redirect only occurs when its a super user
      return <Redirect to={"/manage-staff"} />;
    } else if (this.state.loading) {
      return <LoadingPage />;
    } else if (!this.state.details) {
      return (
        <div className={"page"}>
          <h2 className={"pageHeader"}>Error - page not found</h2>
        </div>
      );
    }

    const details = this.state.details || {};

    return (
      <div className={"page"}>
        <div className="card">
          <h4 className={"card-header"}>Staff Detail #{details?.id}</h4>

          <form onSubmit={this.handleSubmit}>
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <div className={"single-warranty-details-item"}>
                    <span className={"single-warranty-details-label"}>Full name:</span>
                    <input
                      type="text"
                      id="name"
                      required={true}
                      defaultValue={details.name || this.state.fullName}
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className={"single-warranty-details-item"}>
                    <span className={"single-warranty-details-label"}>Email:</span>
                    <input
                      type="email"
                      id="email"
                      required={true}
                      defaultValue={details.email || this.state.email}
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className={"single-warranty-details-item"}>
                    <span className={"single-warranty-details-label"}>Brands:</span>
                    <span>
                      {this.state.brands.map((brand) => (
                        <div className="col-6" key={brand.id}>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={`brand-${brand.id}`}
                              defaultChecked={this.state.details.brands.some((activeBrand) => activeBrand.id === brand.id)}
                              value={brand.id}
                            />
                            <label className="form-check-label" htmlFor={`brand-${brand.id}`}>
                              {brand.name}
                            </label>
                          </div>
                        </div>
                      ))}
                    </span>
                  </div>

                  <div className={"single-warranty-details-item"}>
                    <span className={"single-warranty-details-label"}>Role:</span>

                    <span className="container">
                      <select className="form-control custom-select" value={this.state.currentRole} onChange={this.handleRole}>
                        {this.state?.roles?.map((val, i) => {
                          return (
                            <option key={i} id="currentRole" value={val.name}>
                              {val.name}
                            </option>
                          );
                        })}
                      </select>
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <h5>List service center:</h5>
                  {details?.service_center?.length > 0 ? (
                    <ul className="list-group">
                      {details?.service_center?.map((sc, i) => {
                        return <li className="list-group-item" key={i}>{`${i + 1}.  ${sc.name}`}</li>;
                      })}
                    </ul>
                  ) : (
                    <div>No Data</div>
                  )}
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-3">
                  <button className={"btn btn-outline-primary"}>Save Data</button>
                </div>
                <div className="col-3">
                  <a className={"btn btn-outline-secondary"} href={"/manage-staff"}>
                    Close
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
